import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Menu } from 'interfaces/menu';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    tableContainer: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    activeButton: {
        margin: '0 5px',
        color: '#fff',
        border: 'none',
        backgroundColor: '#211f5c',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
});

interface IProps {
    store_menus?: number[];
    menus: Menu[];
    selectedMenus?: number[];
    setSelectedMenus?: any;
}

export default function EditMenusTable(props: IProps) {
    const classes = useStyles();
    const { menus, selectedMenus, setSelectedMenus } = props;

    const handleMenuOnPress = (selectedMenu: Menu) => {
        if (
            selectedMenus
                .includes(selectedMenu.menu_id)
        ) {
            console.log(selectedMenus)
            return setSelectedMenus(
                selectedMenus.filter(
                    menu => menu !== selectedMenu.menu_id,
                ),
            );
        }
        console.log(selectedMenus)
        return setSelectedMenus([selectedMenu.menu_id, ...selectedMenus]);
    };

    return (
        <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: '5px' }}>Selecionado</TableCell>
                        <TableCell style={{ padding: '5px' }}>Id</TableCell>
                        <TableCell style={{ padding: '5px' }}>Nome</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {menus?.map((menu) => (
                        <TableRow key={menu.menu_id}>
                            <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                                {selectedMenus.some(store_menu => store_menu === menu.menu_id) ? (<CheckBoxIcon onClick={() => handleMenuOnPress(menu)} />) : (<CheckBoxOutlineBlankIcon onClick={() => handleMenuOnPress(menu)} />)}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                                {menu.menu_id}
                            </TableCell>
                            <TableCell style={{ padding: '5px' }}>{menu.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

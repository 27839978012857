import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getHistory } from 'redux/ducks/history';
// import {
// MuiPickersUtilsProvider,
// KeyboardDatePicker
// } from '@material-ui/pickers';
import IStore from 'interfaces/store';
import Loading from 'components/Common/Loading';
import HistoryTable from './HistoryTable';
// import DateFnsUtils from '@date-io/date-fns';
// import SearchIcon from '@material-ui/icons/Search';
// import useStyles from './styles';
// import formatDate from 'date-fns/format';
import { IHistoryState } from 'interfaces/history';
import moment from 'moment';

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [isFiltering, setIsFiltering] = useState(false);
  const [message, setMessage] = useState('Mostrando todos pedidos.');
  const orders = useSelector<IStore, IHistoryState>((state) => state.history);

  useEffect(() => {
    const _initialDate = initialDate
      ? moment(initialDate).format('YYYY-MM-DD')
      : null;
    const _finalDate = finalDate
      ? moment(finalDate).format('YYYY-MM-DD')
      : null;
    dispatch(
      getHistory({
        starts: _initialDate,
        ends: _finalDate,
        store: 'BYSTATION'
      })
    );
  }, [dispatch]);

  const filter = () => {
    const _initialDate = initialDate
      ? moment(initialDate).format('YYYY-MM-DD')
      : null;
    const _finalDate = finalDate
      ? moment(finalDate).format('YYYY-MM-DD')
      : null;
    dispatch(
      getHistory({
        starts: _initialDate,
        ends: _finalDate,
        store: 'BYSTATION'
      })
    );
    setIsFiltering(true);
    const _message = `Mostrando pedidos de ${initialDate ? moment(initialDate).format('DD/MM/YYYY') : 'início'
      } até ${moment(finalDate).format('DD/MM/YYYY')
      }`;
    setMessage(_message);
  };
  // const [date, setDate] = useState(new Date());

  // const handleDateRange = () => {
  //   dispatch(fetchProducts());
  // };

  return (
    <>
      <Loading show={orders.fetch} />
      <Grid container spacing={3}>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          {/* <Grid item container xs={4} alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-initial"
                label="Selecione a Data"
                value={date}
                onChange={setDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>
            <Button style={{ alignSelf: 'flex-end' }} onClick={handleDateRange}>
              <SearchIcon className={classes.datePickerButton} />
            </Button>
          </Grid> */}
        </Grid>
        <HistoryTable
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          isFiltering={isFiltering}
          filter={filter}
          message={message}
          orders={orders.results}
        />
      </Grid>
    </>
  );
}

import React, { useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IStore from 'interfaces/store';
import { Product } from 'interfaces/stock';
import { Button, InputLabel, Input, Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { submitProductionOrder, fetchProviders } from 'redux/ducks/stock';

import useStyles from './styles';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product;
}

export default function ModalAddProduct(props: SimpleDialogProps) {
  const classes = useStyles();

  const { onClose, open, product } = props;
  const [quantity, setQuantity] = React.useState(0);
  const { stock } = useSelector<IStore, IStore>((state) => state);
  const [pacote, setPacote] = React.useState(1);
  const [provider, setProvider] = React.useState(1);

  const dispatch = useDispatch();

  const handleClose = () => {
    setPacote(1);
    setQuantity(0);
    onClose();
  };

  useEffect(() => {
    if (product) {
      dispatch(fetchProviders);
    }
  }, [product]);

  const handlePacote = (event: any) => {
    setPacote(event.target.value)
  }

  const handleQuantity = (event: any) => {
    setQuantity(event.target.value)
  }

  const handleProvider = (event: any) => {
    setProvider(event.target.value)
  }

  const submitCreation = async () => {
    dispatch(submitProductionOrder({
      package: pacote,
      quantity: quantity,
      product: product.product_id,
      provider: provider
    }))
      .then((result: boolean) => {
        if (result) handleClose()
      })
  }

  if (!product) return null;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Criar ordem de produção para {product.name}
      </DialogTitle>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel htmlFor="my-input">Quantidade</InputLabel>
        <Input id="my-input" aria-describedby="my-helper-text" type="number" value={quantity} onChange={handleQuantity} />
      </FormControl>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel id="label">Pacote</InputLabel>
        <Select labelId="label" id="select" value={pacote} onChange={handlePacote}>
          <MenuItem value="1">Pacote 1</MenuItem>
          <MenuItem value="2">Pacote 2</MenuItem>
          <MenuItem value="3">Pacote 3</MenuItem>
          <MenuItem value="4">Pacote 4</MenuItem>
          <MenuItem value="5">Pacote 5</MenuItem>
          <MenuItem value="6">Pacote 6</MenuItem>
          <MenuItem value="7">Pacote 7</MenuItem>
          <MenuItem value="8">Pacote 8</MenuItem>
          <MenuItem value="9">Pacote 9</MenuItem>
          <MenuItem value="10">Pacote 10</MenuItem>
          <MenuItem value="11">Pacote 11</MenuItem>
          <MenuItem value="12">Pacote 12</MenuItem>
          <MenuItem value="13">Pacote 13</MenuItem>
          <MenuItem value="14">Pacote 14</MenuItem>
          <MenuItem value="15">Pacote 15</MenuItem>
          <MenuItem value="16">Pacote 16</MenuItem>
          <MenuItem value="17">Pacote 17</MenuItem>
          <MenuItem value="18">Pacote 18</MenuItem>
          <MenuItem value="19">Pacote 19</MenuItem>
          <MenuItem value="20">Pacote 20</MenuItem>
          <MenuItem value="21">Pacote 21</MenuItem>
          <MenuItem value="22">Pacote 22</MenuItem>
          <MenuItem value="23">Pacote 23</MenuItem>
          <MenuItem value="24">Pacote 24</MenuItem>
          <MenuItem value="25">Pacote 25</MenuItem>
          <MenuItem value="26">Pacote 26</MenuItem>
          <MenuItem value="27">Pacote 27</MenuItem>
          <MenuItem value="28">Pacote 28</MenuItem>
          <MenuItem value="29">Pacote 29</MenuItem>
          <MenuItem value="30">Pacote 30</MenuItem>
          <MenuItem value="31">Pacote 31</MenuItem>
          <MenuItem value="32">Pacote 32</MenuItem>
          <MenuItem value="33">Pacote 33</MenuItem>
          <MenuItem value="34">Pacote 34</MenuItem>
          <MenuItem value="35">Pacote 35</MenuItem>
          <MenuItem value="36">Pacote 36</MenuItem>
          <MenuItem value="37">Pacote 37</MenuItem>
          <MenuItem value="38">Pacote 38</MenuItem>
          <MenuItem value="39">Pacote 39</MenuItem>
          <MenuItem value="40">Pacote 40</MenuItem>
          <MenuItem value="41">Pacote 41</MenuItem>
          <MenuItem value="42">Pacote 42</MenuItem>
          <MenuItem value="43">Pacote 43</MenuItem>
          <MenuItem value="44">Pacote 44</MenuItem>
          <MenuItem value="45">Pacote 45</MenuItem>
          <MenuItem value="46">Pacote 46</MenuItem>
          <MenuItem value="47">Pacote 47</MenuItem>
          <MenuItem value="48">Pacote 48</MenuItem>
          <MenuItem value="49">Pacote 49</MenuItem>
          <MenuItem value="50">Pacote 50</MenuItem>
          <MenuItem value="51">Pacote 51</MenuItem>
          <MenuItem value="52">Pacote 52</MenuItem>
          <MenuItem value="53">Pacote 53</MenuItem>
          <MenuItem value="54">Pacote 54</MenuItem>
          <MenuItem value="55">Pacote 55</MenuItem>
          <MenuItem value="56">Pacote 56</MenuItem>
          <MenuItem value="57">Pacote 57</MenuItem>
          <MenuItem value="58">Pacote 58</MenuItem>
          <MenuItem value="59">Pacote 59</MenuItem>
          <MenuItem value="60">Pacote 60</MenuItem>
          <MenuItem value="61">Pacote 61</MenuItem>
          <MenuItem value="62">Pacote 62</MenuItem>
          <MenuItem value="63">Pacote 63</MenuItem>
          <MenuItem value="64">Pacote 64</MenuItem>
          <MenuItem value="65">Pacote 65</MenuItem>
          <MenuItem value="66">Pacote 66</MenuItem>
          <MenuItem value="67">Pacote 67</MenuItem>
          <MenuItem value="68">Pacote 68</MenuItem>
          <MenuItem value="69">Pacote 69</MenuItem>
          <MenuItem value="70">Pacote 70</MenuItem>
          <MenuItem value="71">Pacote 71</MenuItem>
          <MenuItem value="72">Pacote 72</MenuItem>
          <MenuItem value="73">Pacote 73</MenuItem>
          <MenuItem value="74">Pacote 74</MenuItem>
          <MenuItem value="75">Pacote 75</MenuItem>
          <MenuItem value="76">Pacote 76</MenuItem>
          <MenuItem value="77">Pacote 77</MenuItem>
          <MenuItem value="78">Pacote 78</MenuItem>
          <MenuItem value="79">Pacote 79</MenuItem>
          <MenuItem value="80">Pacote 80</MenuItem>
          <MenuItem value="81">Pacote 81</MenuItem>
          <MenuItem value="82">Pacote 82</MenuItem>
          <MenuItem value="83">Pacote 83</MenuItem>
          <MenuItem value="84">Pacote 84</MenuItem>
          <MenuItem value="85">Pacote 85</MenuItem>
          <MenuItem value="86">Pacote 86</MenuItem>
          <MenuItem value="87">Pacote 87</MenuItem>
          <MenuItem value="88">Pacote 88</MenuItem>
          <MenuItem value="89">Pacote 89</MenuItem>
          <MenuItem value="90">Pacote 90</MenuItem>
          <MenuItem value="91">Pacote 91</MenuItem>
          <MenuItem value="92">Pacote 92</MenuItem>
          <MenuItem value="93">Pacote 93</MenuItem>
          <MenuItem value="94">Pacote 94</MenuItem>
          <MenuItem value="95">Pacote 95</MenuItem>
          <MenuItem value="96">Pacote 96</MenuItem>
          <MenuItem value="97">Pacote 97</MenuItem>
          <MenuItem value="98">Pacote 98</MenuItem>
          <MenuItem value="99">Pacote 99</MenuItem>
          <MenuItem value="100">Pacote 100</MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel id="label">Fornecedor</InputLabel>
        <Select labelId="label" id="select" value={provider} onChange={handleProvider}>
          {stock.providers?.map((provider) => (
            <MenuItem value={provider?.id}>{provider.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
        <Button
          onClick={submitCreation}
          color="primary"
          disabled={quantity <= 0}
          variant="outlined"
          className={classes.activeButton}
        >
          Criar
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          className={classes.button}
        >
          Cancelar
        </Button>
      </div>
    </Dialog>
  );
}

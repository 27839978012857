import React from 'react';
import { Product } from 'interfaces/stock';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import Print from 'components/Common/Print';
import ProductsTable from './ProductsTable';

interface IProps {
  products: Product[];
  disabled: boolean;
}

export default function ToPrint(props: IProps): JSX.Element {
  const { products, disabled } = props;

  return (
    <Print
      trigger={
        <Button size="medium" disabled={disabled}>
          <PrintIcon />
        </Button>
      }
    >
      <ProductsTable products={products} noMargin />
    </Print>
  );
}

ToPrint.defaultProps = {
  disabled: false
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { checkAuthentication } from 'redux/ducks/authentication';

import IStore from 'interfaces/store';
import { IAuthenticationState, ICredentials } from 'interfaces/authentication';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { useStyles } from './styles';

import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import FieldText from '@react-form-fields/material-ui/components/Text';

import Logo from 'assets/img/mposgo_logo.png';

export default function Authentication(): JSX.Element {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const dispatch = useDispatch();
  const authenticationState = useSelector<IStore, IAuthenticationState>(
    (state) => state.authentication
  );
  const { fetch, authorized } = authenticationState;

  const classes = useStyles();

  async function authenticate(isValid: boolean): Promise<void> {
    if (!isValid) return;

    const credentials: ICredentials = { email, password };
    dispatch(checkAuthentication(credentials));
  }

  if (authorized) { 
    if (authenticationState.is_superuser === true) {
      return <Redirect to="/dashboard/order" />; 
    }
    else if (authenticationState.is_matriz === true) {
      return <Redirect to="/dashboard/order-matriz" />;
    } 
    else if (authenticationState.is_bystation === true) {
      return <Redirect to="/dashboard/order-by-station" />;
    } 
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={Logo} alt="Logo" width="300" height="90" />
        <FormValidation onSubmit={(isValid) => authenticate(isValid)}>
          <FieldText
            inputProps={{ 'data-testid': 'email' }}
            variant="outlined"
            margin="normal"
            validation="required|email"
            type="email"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            value={email}
            onChange={(email) => setEmail(email)}
            autoFocus
            className={classes.field}
          />

          <FieldText
            inputProps={{ 'data-testid': 'password' }}
            variant="outlined"
            margin="normal"
            validation="required"
            fullWidth
            name="password"
            value={password}
            label="Password"
            type="password"
            id="password"
            onChange={(password) => setPassword(password)}
            autoComplete="current-password"
            className={classes.field}
          />

          <Button
            data-testid="submit"
            type="submit"
            fullWidth
            disabled={fetch}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {!fetch ? 'Sign in' : 'Loading...'}
          </Button>
        </FormValidation>
      </div>
    </Container>
  );
}

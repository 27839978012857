import { ETypesChart, IFetchAction, ThunkAction, Dispatch, IChartState, ISuccessAction, IHomeAction} from 'interfaces/charts'
import { EMethod } from 'enums/method';
import { fetch } from 'utils/request';
import { sendAlert } from './alert';
import { EAlertVariant } from 'interfaces/alert';


const initialState: IChartState = {
    fetch: false,
    orders: {
        opened: 0,
        closed: 0,
        canceled: 0,
        afazer: 0,
        fazendo: 0,
        feito: 0,
        depot: 0,
        all: 0
    },
    ingredients: {
        opened: 0,
        closed: 0,
        afazer: 0,
        fazendo: 0,
        feito: 0,
        all: 0
    },
    production: {
        opened: 0,
        closed: 0,
        afazer: 0,
        fazendo: 0,
        feito: 0,
        all: 0
    },
  };

  export default (
    state: IChartState = initialState,
    action: IHomeAction
  ): IChartState => {
    switch (action.type) {
      case ETypesChart.FETCH:
        return {
          ...state,
          fetch: true
        };
      case ETypesChart.SUCCESS:
        return {
          ...state,
          fetch: false,
          orders: action.payload.orders,
          ingredients: action.payload.ingredients,
          production: action.payload.production
        };
      default:
        return state;
    }
  };

export const fetchHome = (): IFetchAction => ({
    type: ETypesChart.FETCH
});


export const successHome = (payload: IChartState): ISuccessAction => ({
    type: ETypesChart.SUCCESS,
    payload
});

export const getCount = (): ThunkAction => async (
    dispatch: Dispatch
): Promise<void> => {
    try {
        const response = await fetch({
            method: EMethod.GET,
            url: `/orders/api/orders/count/?all=True`
        });
        dispatch(successHome(response));

    } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
};
import React from 'react';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import Print from 'components/Common/Print';
import { IPackage } from 'interfaces/package';
import { Grid } from '@material-ui/core';
import Panel from './Panel';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  packages: IPackage[];
  disabled: boolean;
}

export default function ToPrint(props: IProps): JSX.Element {
  const { packages, disabled } = props;

  return (
    <Print
      trigger={
        <Button size="medium" disabled={disabled}>
          <PrintIcon />
        </Button>
      }
    >
      <Grid container spacing={2} style={{ padding: '10px' }}>
        {packages.map((batch) => (
          <Panel key={uuidv4()} batch={batch} toPrint />
        ))}
      </Grid>
    </Print>
  );
}

ToPrint.defaultProps = {
  disabled: false
};

import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Grid,
    Container,
    FormControl,
    Button,
    InputLabel,
    Input,
    Tabs,
    Tab,
    AppBar,
    Typography,
    Box,
    MenuItem,
    Select,
    IconButton
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editProduct } from 'redux/ducks/stock';
import { Product, Ingredients } from 'interfaces/stock';
import './styles.css';
import Modal from '@material-ui/core/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { fetch } from './../../utils/request';

import { makeStyles } from '@material-ui/core/styles';

//import CloseIcon from '@material-ui/icons/Close';
// import Typography from '@material-ui/core/Typography';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

//Overrides theme of Dropzone component
const theme = (createMuiTheme as any)({
    overrides: {
        MuiDropzoneArea: {
            root: {
                minHeight: 'auto'
            },
            text: {
                fontSize: '1rem'
            },
            icon: {
                width: '33px'
            }
        },
        MuiDropzonePreviewList: {
            root: {
                justifyContent: 'center',
                position: 'absolute',
                top: 0
            },
            imageContainer: {
                maxWidth: '100%',
                flexBasis: '100%'
            }
        }
    }
});

const useStyles = makeStyles({
    divButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        color: '#211f5c',
        border: 'none',
        margin: '0 5px',
        boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        backgroundColor: '#fdfdfd',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    activeButton: {
        margin: '0 5px',
        color: '#fff',
        border: 'none',
        backgroundColor: '#211f5c',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
});

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    product: Product;
    store: string;
}

export default function ModalEditProduct(props: SimpleDialogProps) {
    const classes = useStyles();

    const { onClose, open, product } = props;
    const [image, setImage] = React.useState<File>();
    const [imageSource, setImageSource] = React.useState<File>();
    const [name, setName] = React.useState('');
    const [price, setPrice] = React.useState(0);
    const [cost, setCost] = React.useState(0);
    const [stock, setStock] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const [fetchedIngredients, setFetchedIngredients] = useState<Ingredients[]>([]);
    const [allergyNotes, setAllergyNotes] = React.useState('');
    const [ncm, setNcm] = React.useState('');
    const [categories, setCategories] = React.useState([]);
    const [category, setCategory] = React.useState('Food');
    const [ingredients, setIngredients] = React.useState([]);
    const [extras, setExtras] = React.useState([]);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setName('');
        setPrice(0);
        setStock(0);
        onClose();
        setIngredients([]);
        setExtras([]);
        setValue(0);
    };
    const getIngredients = async () => {
        try {
            let url = `products/api/ingredients/`;
            let response;
            let results = [];
            do {
                response = await fetch({
                    method: 'GET',
                    url: url
                });
                url = response.next;
                results.push(...response.results);
            } while (response.next);
            setFetchedIngredients(results);
        } catch (error) {
            console.log('error fetching ingredients');
        }
    };
    const getCategories = async () => {
        try {
            let url = `products/api/products/list_categories/`;
            let response;
            let results = [];
            do {
                response = await fetch({
                    method: 'GET',
                    url: url
                });
                url = response.next;
                results.push(...response.category);
            } while (response.next);
            setCategories(results);
        } catch (error) {
            console.log('error fetching ingredients');
        }
    };

    useEffect(() => {
        if (product) {
            console.log('product.ingredients =>', product);
            setName(product.name);
            setPrice(product.price);
            setCost(product.cost);
            setStock(product.stock);
            setDescription(product.description);
            setAllergyNotes(product.allergy_notes);
            setCategory(product.category);
            setNcm(product.ncm);
            handleMaps();
            getIngredients();
            getCategories();
        }
    }, [product]);

    const handleMaps = () => {
        const newIngredients = [];
        const newExtras = [];
        console.log('product.ingredients => ', product.ingredients);
        for (let index = 0; index < product.ingredients.length; index++) {
            const element = product.ingredients[index];
            newIngredients.push({
                id: index,
                ingredient_id: element.ingredient_id,
                measure: element.measure,
                name: element.name,
                quantity: element.quantity,
                isOld: true,
                stock_id: element.id
            });
        }
        console.log('product.extras => ', product.extras);
        for (let index = 0; index < product.extras.length; index++) {
            const element = product.extras[index];
            newExtras.push({
                extra_id: index,
                id: element.id,
                is_sauce: element.is_sauce === false ? 0 : 1,
                limit: element.limit,
                price: element.price,
                ingredient: element.ingredient_id,
                quantity: element.quantity,
                isOld: true
            });
            console.log('element => ', newExtras);
        }
        setIngredients(newIngredients);
        console.log('newExtras => ', newExtras);
        setExtras(newExtras);
    };
    const handleImage = (event: any) => {
        if (event.target) {
            setImage(event.target.value);
            console.log(image);
            var file = event.target.files[0];
            setImageSource(file);
        }
        setImage(null);
        setImageSource(null);
    };
    const handleName = (event: any) => {
        setName(event.target.value);
    };

    const handlePrice = (event: any) => {
        setPrice(event.target.value);
    };

    const handleCost = (event: any) => {
        setCost(event.target.value);
    };

    const handleStock = (event: any) => {
        setStock(event.target.value);
    };

    const handleDescription = (event: any) => {
        setDescription(event.target.value);
    };
    const handleAllergyNotes = (event: any) => {
        setAllergyNotes(event.target.value);
    };
    const handleCategory = (event: any) => {
        setCategory(event.target.value);
    };
    const handleNcm = (event: any) => {
        setNcm(event.target.value);
    };

    const submitEdit = async () => {
        var oldExtras = [];
        var oldIngredients = [];
        var extrasNew = [];
        var ingredientsNew = [];
        for (let index = 0; index < ingredients.length; index++) {
            const element = ingredients[index];
            if (element.isOld === true) {
                oldIngredients.push({
                    quantity: element.quantity,
                    measure: element.measure,
                    ingredient_id: element.ingredient_id,
                    stock: element.stock_id
                });
            } else {
                ingredientsNew.push({
                    ingredient_id: element.ingredient_id,
                    ingredient_stock: {
                        measure: showMeasure(element.ingredient_id),
                        quantity: element.quantity * 1
                    }
                });
            }
        }

        for (let index = 0; index < extras.length; index++) {
            const element = extras[index];
            if (element.isOld === true) {
                oldExtras.push({
                    ingredient_id_new: element.ingredient,
                    quantity: element.quantity,
                    limit: element.limit,
                    price: element.price,
                    id: element.id,
                    is_sauce: element.is_sauce === 0 ? false : true
                });
            } else {
                extrasNew.push({
                    ingredient_id: element.ingredient,
                    extra: {
                        limit: element.limit,
                        price: element.price,
                        is_sauce: element.is_sauce === 0 ? false : true,
                        measure: showMeasure(element.ingredient),
                        quantity: element.quantity
                    }
                });
            }
        }
        dispatch(
            editProduct(product.product_id, {
                image: imageSource,
                name: name,
                price: price,
                stock: stock,
                cost: cost,
                description: description,
                allergy_notes: allergyNotes,
                category: category,
                ncm: ncm,
                oldExtras: oldExtras,
                oldIngredients: oldIngredients,
                newIngredients: ingredientsNew,
                newExtras: extrasNew,
                store: props.store
            })
        ).then((result: boolean) => {
            if (result) handleClose();
        });
    };

    const handleChangeQuantity = (index: any, value: any) => {
        setIngredients(
            ingredients.map((item) =>
                item.id === index
                    ? {
                        ...item,
                        quantity: value
                    }
                    : item
            )
        );
    };

    const handleChangeingredientId = (index: any, value: any) => {
        setIngredients(
            ingredients.map((item) =>
                item.id === index
                    ? {
                        ...item,
                        ingredient_id: value
                    }
                    : item
            )
        );
    };
    const handleDelete = (id: number) => {
        const values = [...ingredients];
        values.splice(id, 1);
        for (let index = 0; index < values.length; index++) {
            const element = values[index];
            element.id = index;
        }
        setIngredients(values);
    };

    const listIngredientsItems = fetchedIngredients.map((value, index) => (
        <MenuItem key={value.name + index} value={value.ingredient_id}>
            {value.name}
        </MenuItem>
    ));

    const showCost = (id: number) => {
        var cost = 0;
        for (let index = 0; index < fetchedIngredients.length; index++) {
            const element = fetchedIngredients[index];
            if (element.ingredient_id === id) {
                cost = element.cost;
            }
        }
        return cost;
    };

    const showMeasure = (id: number) => {
        var measure = '';
        for (let index = 0; index < fetchedIngredients.length; index++) {
            const element = fetchedIngredients[index];
            if (element.ingredient_id === id) {
                measure = element.measure;
            }
        }
        return measure;
    };

    const listIngredients = ingredients.map((item, index) => (
        <tr>
            <td>
                <Grid item xs={12} sm={8}>
                    <FormControl
                        style={{
                            marginTop: '0px'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                    >
                        <Select
                            id="ingredient-input"
                            value={item.ingredient_id}
                            onChange={(e) => {
                                handleChangeingredientId(index, e.target.value);
                            }}
                        >
                            {listIngredientsItems}
                        </Select>
                    </FormControl>
                </Grid>
            </td>
            <td>
                <Grid item xs={12} sm={5}>
                    <FormControl fullWidth={true} required={true}>
                        <Input
                            id="price-input"
                            type="number"
                            value={item.quantity}
                            onChange={(e) => {
                                handleChangeQuantity(index, e.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            </td>
            <td>
                <Grid
                    style={{ display: 'flex', flexDirection: 'row' }}
                    item
                    xs={12}
                    sm={2}
                >
                    {showMeasure(item.ingredient_id) === '' ? null : (
                        <p className="cost-per-measure-container">
                            R$ {showCost(item.ingredient_id)}/
                            {showMeasure(item.ingredient_id)}
                        </p>
                    )}
                </Grid>
            </td>

            <td>
                <Grid
                    style={{ display: 'flex', flexDirection: 'column' }}
                    item
                    xs={12}
                    sm={2}
                >
                    <div className="div-total-cost-container">
                        <p>R$ {showCost(item.ingredient_id) * item.quantity}</p>
                    </div>
                </Grid>
            </td>
            <td>
                <>
                    <IconButton
                        style={{ height: '50px' }}
                        onClick={() => handleDelete(index)}
                        color="secondary"
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            </td>
        </tr>
    ));

    const handleChangeingredientIdExtras = (index: any, value: any) => {
        setExtras(
            extras.map((item) =>
                item.extra_id === index
                    ? {
                        ...item,
                        ingredient: value
                    }
                    : item
            )
        );
        console.log(extras);
    };

    const handleChangeIsSauceExtras = (index: any, value: any) => {
        setExtras(
            extras.map((item) =>
                item.extra_id === index
                    ? {
                        ...item,
                        is_sauce: value
                    }
                    : item
            )
        );
    };

    const handleChangeLimitExtras = (index: any, value: any) => {
        setExtras(
            extras.map((item) =>
                item.extra_id === index
                    ? {
                        ...item,
                        limit: value
                    }
                    : item
            )
        );
    };

    const handleChangePriceExtras = (index: any, value: any) => {
        setExtras(
            extras.map((item) =>
                item.extra_id === index
                    ? {
                        ...item,
                        price: value
                    }
                    : item
            )
        );
    };

    const handleChangeQuantityExtras = (index: any, value: any) => {
        setExtras(
            extras.map((item) =>
                item.extra_id === index
                    ? {
                        ...item,
                        quantity: value
                    }
                    : item
            )
        );
    };

    const handleDeleteExtras = (id: number) => {
        const values = [...extras];
        values.splice(id, 1);
        for (let index = 0; index < values.length; index++) {
            const element = values[index];
            element.extra_id = index;
        }
        setExtras(values);
    };

    const listExtras = extras.map((item, index) => (
        <tr>
            <td style={{ marginTop: 10, marginLeft: 15 }}>
                <Grid item xs={12} sm={9}>
                    <FormControl
                        style={{
                            marginTop: '0px'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                    >
                        <Select
                            id="ingredient-input"
                            value={item.ingredient}
                            onChange={(e) => {
                                handleChangeingredientIdExtras(item.extra_id, e.target.value);
                            }}
                        >
                            {listIngredientsItems}
                        </Select>
                    </FormControl>
                </Grid>
            </td>
            <td style={{ marginTop: 10, marginLeft: 15 }}>
                <Grid item xs={12} sm={5}>
                    <FormControl fullWidth={true} required={true}>
                        <Input
                            id="price-input"
                            type="number"
                            value={item.quantity}
                            onChange={(e) => {
                                handleChangeQuantityExtras(item.extra_id, e.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            </td>
            <td style={{ marginTop: 10, marginLeft: 15 }}>
                <Grid item xs={12} sm={5}>
                    <FormControl fullWidth={true} required={true}>
                        <Input
                            id="price-input"
                            type="number"
                            value={item.limit}
                            onChange={(e) => {
                                handleChangeLimitExtras(item.extra_id, e.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            </td>
            <td>
                <Grid item xs={12} sm={8}>
                    <FormControl fullWidth={true} required={true}>
                        <Input
                            id="price-input"
                            type="number"
                            value={item.price}
                            onChange={(e) => {
                                handleChangePriceExtras(item.extra_id, e.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            </td>

            <td>
                <Grid item xs={12} sm={10}>
                    <FormControl fullWidth={true} required={true}>
                        <Select
                            id="ingredient-input"
                            value={item.is_sauce}
                            onChange={(e) => {
                                handleChangeIsSauceExtras(item.extra_id, e.target.value);
                            }}
                        >
                            <MenuItem value={0}>Sim</MenuItem>
                            <MenuItem value={1}>Não</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </td>
            <td>
                <>
                    <IconButton
                        style={{ height: '50px' }}
                        onClick={() => handleDeleteExtras(index)}
                        color="secondary"
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            </td>
        </tr>
    ));

    const showTotalCost = () => {
        var cost = 0;
        for (let index = 0; index < ingredients.length; index++) {
            const element = ingredients[index];
            cost = cost + showCost(element.ingredient_id) * element.quantity;
        }
        return <p>R$ {cost}</p>;
    };

    return (
        <Modal
            className="create-menu-dialog"
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-create-menu-content">
                <IconButton
                    style={{ width: '50px' }}
                    color="secondary"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle id="simple-dialog-title">
                    {value === 0
                        ? 'Editar Produto'
                        : value === 1
                            ? 'Editar Ingredientes'
                            : 'Editar Extras'}
                </DialogTitle>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Add Product"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Produto" {...a11yProps(0)} />
                        <Tab label="Ingredientes" {...a11yProps(1)} />
                        <Tab label="Extras" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Container maxWidth="md">
                        <Grid container spacing={7}>
                            {/* Start of left column */}
                            <Grid item xs={12} sm={9}>
                                <FormControl fullWidth={true} required={true}>
                                    <InputLabel htmlFor="name-input">Nome</InputLabel>
                                    <Input
                                        id="name-input"
                                        type="string"
                                        value={name}
                                        onChange={handleName}
                                    />
                                </FormControl>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth={true} margin={'normal'}>
                                            <InputLabel htmlFor="price-input">Preço</InputLabel>
                                            <Input
                                                id="price-input"
                                                type="number"
                                                value={price}
                                                onChange={handlePrice}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth={true} margin={'normal'}>
                                            <InputLabel htmlFor="stock-input">Estoque</InputLabel>
                                            <Input
                                                id="stock-input"
                                                type="number"
                                                value={stock}
                                                onChange={handleStock}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth={true} margin={'normal'}>
                                            <InputLabel htmlFor="stock-input">Custo</InputLabel>
                                            <Input
                                                id="stock-input"
                                                type="number"
                                                value={cost}
                                                onChange={handleCost}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <FormControl fullWidth={true} margin={'normal'}>
                                    <InputLabel htmlFor="description-input">
                                        Descrição
                                    </InputLabel>
                                    <Input
                                        id="description-input"
                                        type="string"
                                        value={description}
                                        onChange={handleDescription}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true} margin={'normal'}>
                                    <InputLabel htmlFor="alergy-notes-input">
                                        Notas de Alergia
                                    </InputLabel>
                                    <Input
                                        id="alergy-notes-input"
                                        type="string"
                                        value={allergyNotes}
                                        onChange={handleAllergyNotes}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true} margin={'normal'} required={true}>
                                    <InputLabel htmlFor="category-input">
                                        Categoria
                                    </InputLabel>
                                    <Select
                                        id="category-input"
                                        value={category}
                                        onChange={handleCategory}
                                    >
                                        {categories.map((value, index) => (
                                            <MenuItem key={value + index} value={value}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth={true} margin={'normal'} required={true}>
                                    <InputLabel htmlFor="alergy-notes-input">
                                        NCM
                                    </InputLabel>
                                    <Input
                                        id="alergy-notes-input"
                                        type="string"
                                        value={ncm}
                                        onChange={handleNcm}
                                    />
                                </FormControl>
                            </Grid>
                            {/* End of left column */}
                            <Grid item xs={12} sm={3}>
                                <ThemeProvider theme={theme}>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        dropzoneText={'Drag and drop an image here or click'}
                                        onChange={(files) => handleImage(files)}
                                        filesLimit={1}
                                    />
                                </ThemeProvider>
                                {/* <FormControl>
                <InputLabel htmlFor="file-input">Image</InputLabel>
                <Input
                  id="file-input"
                  type="file"
                  value={image}
                  onChange={(e) => handleImage(e)}
                />
              </FormControl> */}
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            paddingRight: '10px'
                                        }}
                                    >
                                        <Button
                                            onClick={() => submitEdit()}
                                            variant="outlined"
                                            color="primary"
                                            className={classes.activeButton}
                                        >
                                            Salvar{' '}
                                        </Button>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Button
                                            onClick={() => setValue(1)}
                                            variant="outlined"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Próximo{' '}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <table className="ingredients-table-container">
                        <thead>
                            <th className="ingredient-th">Ingrediente</th>
                            <th>Quantidade</th>
                            <th>Custo/Ingrediente</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Custo da Receita</th>
                            <th></th>
                        </thead>
                        <tbody>
                            {fetchedIngredients ? listIngredients : null}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    {' '}
                                    <div className="bottom-total-cost-container">
                                        {showTotalCost()}
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            var data = {
                                id: 0,
                                ingredient_id: 0,
                                measure: '',
                                name: '',
                                quantity: 0,
                                isOld: false
                            };
                            if (ingredients.length >= 1) {
                                data.id = ingredients[ingredients.length - 1].id + 1;
                            }
                            setIngredients(ingredients.concat(data));
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                onClick={() => setValue(2)}
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                            >
                                Próximo{' '}
                            </Button>
                        </div>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Container maxWidth="md">
                        <table className="ingredients-table-container">
                            <thead>
                                <th className="extra-th">Extra</th>
                                <th>Quantidade</th>
                                <th>Limite</th>
                                <th>Preço</th>
                                <th className="sauce-th">É molho?</th>
                                <th></th>
                            </thead>
                            <tbody>{listExtras}</tbody>
                        </table>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                var data = {
                                    extra_id: 0,
                                    ingredient: '',
                                    limit: 0,
                                    price: '',
                                    is_sauce: 0,
                                    quantity: 0,
                                    isOld: false
                                };
                                if (extras.length >= 1) {
                                    data.extra_id = extras[extras.length - 1].extra_id + 1;
                                }
                                setExtras(extras.concat(data));
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Container>
                </TabPanel>
            </div>
        </Modal>
    );
}
import React from 'react';
import {
    Button,
    InputLabel,
    Input,
    Container,
    Grid,
    FormControl,
    IconButton,
    Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createCustomer } from 'redux/ducks/stock';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import ToggleOnOutlinedIcon from '@material-ui/icons/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';

import { EMethod } from 'enums/method';

import { fetch } from 'utils/request';

import useStyles from './styles';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function ModalCreateCustomer(props: SimpleDialogProps) {
    const classes = useStyles();

    const { onClose, open } = props;
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [cpf, setCpf] = React.useState('');
    const [cnpj, setCnpj] = React.useState('');
    const [isCnpj, setIsCnpj] = React.useState(false);

    const [addressName, setAddressName] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [streetNumber, setStreetNumber] = React.useState('');
    const [neighborhood, setNeighborhood] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [zipcode, setZipcode] = React.useState('');
    const [complement, setComplement] = React.useState('');


    const dispatch = useDispatch();

    const handleClose = () => {
        setName('');
        onClose();
    };

    const handleName = (event: any) => {
        setName(event.target.value);
    };

    const handleEmail = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePhoneNumber = (event: any) => {
        setPhoneNumber(event.target.value);
    };

    const handleCpf = (event: any) => {
        setCpf(event.target.value);
    };

    const handleCnpj = (event: any) => {
        setCnpj(event.target.value);
    };

    const handleAddressName = (event: any) => {
        setAddressName(event.target.value);
    };

    const handleStreet = (event: any) => {
        setStreet(event.target.value);
    };

    const handleStreetNumber = (event: any) => {
        setStreetNumber(event.target.value);
    };

    const handleNeighborhood = (event: any) => {
        setNeighborhood(event.target.value);
    };

    const handleCity = (event: any) => {
        setCity(event.target.value);
    };

    const handleState = (event: any) => {
        setState(event.target.value);
    };


    const handleZipcode = async (event: any) => {
        setZipcode(event.target.value);
        const zip = event.target.value

        console.log(zip)
        const request_url = `https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zip}/json/`;

        await fetch({
            method: EMethod.GET,
            url: request_url
        }).then((response) => {
            setCity(response.localidade)
            setNeighborhood(response.bairro)
            setStreet(response.logradouro)
            setComplement(response.complemento)

            
        }).catch((err) => {
            console.log(err)
        });


    }

    const handleComplement = (event: any) => {
        setComplement(event.target.value);
    };

    const submitCreation = async () => {
        dispatch(
            createCustomer({
                name: name,
                email: email,
                phone: phoneNumber,
                cpf: cpf,
                address: {
                    name: addressName,
                    street: street,
                    street_number: streetNumber,
                    neighborhood: neighborhood,
                    city: city,
                    state: state,
                    zipcode: zipcode,
                    complement: complement
                },
                is_cnpj: isCnpj,
                cnpj: cnpj
            })
        ).then((result: boolean) => {
            if (result) handleClose();
        });
    };

    return (
        <Modal
            className="create-menu-dialog"
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-create-menu-content">
                <IconButton
                    style={{ width: '50px' }}
                    color="secondary"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>

                <h2>Adicionar Cliente</h2>
                <Container maxWidth="md">
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="name-input">Nome</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={name}
                                onChange={handleName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="email-input">Email</InputLabel>
                            <Input
                                id="email-input"
                                type="string"
                                value={email}
                                onChange={handleEmail}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="phoneNumber-input">Telefone</InputLabel>
                            <Input
                                id="phoneNumber-input"
                                type="string"
                                value={phoneNumber}
                                onChange={handlePhoneNumber}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="cpf-input">CPF</InputLabel>
                            <Input
                                id="cpf-input"
                                type="string"
                                value={cpf}
                                onChange={handleCpf}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <Typography >É CNPJ?</Typography>
                            {isCnpj ? (<ToggleOnOutlinedIcon onClick={() => setIsCnpj(false)} />) : (<ToggleOffOutlinedIcon onClick={() => setIsCnpj(true)} />)}
                        </FormControl>
                    </Grid>
                    {isCnpj ?
                        (<Grid item xs={12} spacing={5} sm={12}>
                            <FormControl fullWidth style={{ marginBottom: '15px' }}>
                                <InputLabel htmlFor="cnpj-input">CNPJ</InputLabel>
                                <Input
                                    id="cpf-input"
                                    type="string"
                                    value={cnpj}
                                    onChange={handleCnpj}
                                />
                            </FormControl>
                        </Grid>)
                        :
                        null}
                    <h3>Endereço</h3>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="address-input">Nome do Endereço</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={addressName}
                                onChange={handleAddressName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="street-input">Rua</InputLabel>
                            <Input
                                id="email-input"
                                type="string"
                                value={street}
                                onChange={handleStreet}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="streetNumber-input">Número</InputLabel>
                            <Input
                                id="phoneNumber-input"
                                type="string"
                                value={streetNumber}
                                onChange={handleStreetNumber}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="neighborhood-input">Bairro</InputLabel>
                            <Input
                                id="cpf-input"
                                type="string"
                                value={neighborhood}
                                onChange={handleNeighborhood}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="city-input">Cidade</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={city}
                                onChange={handleCity}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="state-input">Estado</InputLabel>
                            <Input
                                id="email-input"
                                type="string"
                                value={state}
                                onChange={handleState}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="cep-input">CEP</InputLabel>
                            <Input
                                id="phoneNumber-input"
                                type="string"
                                value={zipcode}
                                onChange={handleZipcode}
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <ViaCep cep={this.state.cep} lazy>
                                {({ data, loading, error, fetch }) => {
                                    if (loading) {
                                        return <p>loading...</p>
                                    }
                                    if (error) {
                                        return <p>error</p>
                                    }
                                    if (data) {
                                        return <div>
                                            <p>
                                                CEP: {data.cep} <br />
                                        CIDADE: {data.localidade} <br />
                                        UF: {data.uf} <br />
                                            </p>
                                        </div>
                                    }
                                    return <div>
                                        <input onChange={() => { }} value={this.state.cep} placeholder="CEP" type="text" />
                                        <button onClick={fetch}>Pesquisar</button>
                                    </div>
                                }}
                            </ViaCep>
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="complement-input">Complemento</InputLabel>
                            <Input
                                id="cpf-input"
                                type="string"
                                value={complement}
                                onChange={handleComplement}
                            />
                        </FormControl>
                    </Grid>
                    {/* End of left column */}
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ marginBottom: '30px' }}
                                variant="outlined"
                                className={classes.activeButton}
                                onClick={submitCreation}
                                color="primary"
                            >
                                Criar{' '}
                            </Button>
                        </div>
                    </Grid>
                </Container>
            </div>
        </Modal>
    );
}
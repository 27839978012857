import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchNotificationsGeneral, fetchNotificationsStatus } from 'redux/ducks/notification';
import { EFilters } from '../../enums/notification';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import GeneralNotificationsTable from './GeneralNotificationsTable';
import StatusNotificationsTable from './StatusNotificationsTable';
import StatusFilter from './StatusFilter'

const filters = [
  {
    label: 'Notificações Gerais',
    value: EFilters.GENERAL
  },
  {
    label: 'Notificações de Status',
    value: EFilters.STATUS
  }
];


export default function ControlPanel(): JSX.Element {
  const dispatch = useDispatch();
  const { notification } = useSelector<IStore, IStore>((state) => state);
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.GENERAL);

  useEffect(() => {
    if (activeFilter === EFilters.GENERAL) {
      dispatch(fetchNotificationsGeneral());
    } else {
      dispatch(fetchNotificationsStatus());
    }
  }, [dispatch, activeFilter]);



  return (
    <>
      <Loading show={notification.fetch} />
      <Grid container spacing={3}>
        <StatusFilter
          activeFilter={activeFilter}
          setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
          filters={filters}
        />
        {activeFilter === EFilters.GENERAL ? (
          <GeneralNotificationsTable notifications={notification.generalnotifications} />
        ) : (
            <StatusNotificationsTable notifications={notification.statusnotifications} />
          )}
      </Grid>
    </>
  );
}

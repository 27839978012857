import React from 'react';
import { Typography } from '@material-ui/core';
import { CardWithValue, CardBody, CardLabel } from './styles';

interface Props {
  label: string;
  time?: string;
}

const DateCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { label, time } = props;
  return (
    <CardWithValue>
      <CardBody>
        <CardLabel paragraph align="center">
          {label.toUpperCase()}
        </CardLabel>
        {time ? (
          <Typography
            variant="h4"
            style={{ color: '#333399' }}
          >
            { time}
          </Typography>
        ) : <Typography
          variant="h4"
          style={{ color: '#333399' }}
        >
            {'00:00:00'}
          </Typography>
        }
      </CardBody>
    </CardWithValue>
  );
};

export default DateCard;

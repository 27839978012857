import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export enum ETypesNotification {
  FETCH = 'NOTIFICATION/FETCH',
  SUCCESS = 'NOTIFICATION/SUCCESS',
  FAILURE = 'NOTIFICATION/FAILURE',
  RESET = 'NOTIFICATION/RESET'
}

export interface INotificationState {
  fetch?: boolean;
  generalnotifications: GeneralNotification[];
  statusnotifications: StatusNotification[];
}

export interface GeneralNotification {
  id: number;
  title: string;
  message: string;
  created_date: Date;
}

export interface StatusNotification {
  id: number;
  message: string;
  status: string;
}

export interface IFetchAction extends IBaseAction<ETypesNotification, null> {
  type: ETypesNotification.FETCH;
}

export interface ISuccessAction extends IBaseAction<ETypesNotification, INotificationState> {
  type: ETypesNotification.SUCCESS;
  payload: INotificationState;
}

export interface IFailureAction extends IBaseAction<ETypesNotification, string> {
  type: ETypesNotification.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesNotification, null> {
  type: ETypesNotification.RESET;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;

import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';

interface IProps {
  trigger: React.ReactElement;
  children: React.ReactElement;
  onBeforeGetContent?: () => void | Promise<any>;
}

export default function Print(props: IProps): JSX.Element {
  const { trigger, children, onBeforeGetContent } = props;

  const componentRef = useRef();
  const contentFunction = () =>
    (componentRef.current as unknown) as React.ReactInstance;
  const triggerFunction = () => trigger;

  return (
    <>
      <ReactToPrint
        trigger={triggerFunction}
        content={contentFunction}
        onBeforeGetContent={onBeforeGetContent}
        suppressErrors={true}
      />
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>{children}</div>
      </div>
    </>
  );
}

import React, { PureComponent } from 'react';
import { CardWithGraphic, GraphicCardLabel } from './styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

interface GraphicProps {
  data:
  | Array<{
    name: string;
    média: string;
  }>
  | Array<{
    name: string;
    quantidade: number;
  }>;
  floatNumbers?: boolean;
}

class CustomLineChart extends PureComponent<GraphicProps> {
  render() {
    const { data, floatNumbers } = this.props;
    return (
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey={floatNumbers ? 'média' : 'quantidade'}
              stroke="#211F5C"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

interface Props {
  data: Array<{
    name: string;
    amount: number;
  }>;
  size?: 'small' | 'medium' | 'large';
  title: string;
  floatNumbers?: boolean;
  total_average?: number;
}

const IntensityChartCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { size, title, data, floatNumbers, total_average } = props;

  let dataFormated;

  if (floatNumbers) {
    dataFormated = data.map((record) => ({
      name: record.name,
      média: record.amount.toFixed(2)
    }));
  } else {
    dataFormated = data.map((record) => ({
      name: record.name,
      quantidade: record.amount
    }));
  }

  const renderSize = () => {
    switch (size) {
      case 'medium':
        return {
          minWidth: '48%'
        };
      case 'large':
        return {
          width: '100%'
        };
      default:
        return {};
    }
  };
  return (
    <CardWithGraphic style={renderSize()}>
      {total_average ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <GraphicCardLabel paragraph>{title.toUpperCase()}</GraphicCardLabel>
          <div style={{ whiteSpace: 'nowrap' }}>
            <GraphicCardLabel style={{ color: "#32CD32" }} paragraph>
              R$ {total_average ? total_average.toFixed(2) : null}
            </GraphicCardLabel>
          </div>
        </div>
      ) : <GraphicCardLabel paragraph>{title.toUpperCase()}</GraphicCardLabel>}
      <CustomLineChart
        data={dataFormated}
        floatNumbers={floatNumbers ? true : false}
      />
    </CardWithGraphic>
  );
};

export default IntensityChartCard;

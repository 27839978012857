import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IHistory } from 'interfaces/history';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  orders: IHistory[];
  initialDate: Date;
  finalDate: Date;
  isFiltering: Boolean;
  filter: any;
  setInitialDate: any;
  setFinalDate: any;
  message: string;
}

export default function HistoryTable(props: IProps) {
  const classes = useStyles();
  const { orders,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    isFiltering,
    filter,
    message } = props;

  console.log(orders)

  const handleInitalDateChange = (date: Date) => {
    setInitialDate(date);
  };

  const handleFinalDateChange = (date: Date) => {
    setFinalDate(date);
  };

  const filterMessage = () => {
    const style = {
      color: '#233472',
      padding: '15px 0 0 0',
      fontWeight: 600
    };

    if (isFiltering) {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    }
  };

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 15,
            padding: 15,
            backgroundColor: '#f5f5f5',
            borderRadius: 5
          }}
        >
          <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
            <Grid
              style={{
                alignItems: 'center',
                display: 'inline-flex'
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Data Inicial</span>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-initial"
                    value={initialDate}
                    placeholder="                ---------------"
                    onChange={handleInitalDateChange}
                    autoOk={true}
                    style={{ width: 189, marginRight: 5 }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Data Final</span>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-final"
                    margin="none"
                    value={finalDate}
                    onChange={handleFinalDateChange}
                    autoOk={true}
                    style={{ width: 189 }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Grid>

            <div style={{ display: 'inline-flex', marginLeft: 5 }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: 5, fontSize: '0.8em', padding: '5px 16px' }}
                onClick={filter}
              >
                Filtrar
            </Button>
            </div>
          </div>
          <PrintIcon onClick={() => {
            const _initialDate = initialDate
              ? moment(initialDate).format('YYYY-MM-DD')
              : null;
            const _finalDate = finalDate
              ? moment(finalDate).format('YYYY-MM-DD')
              : null;
            window.open(`https://farina.stopplay.io/waiter/generate_csv_of_orders_for_between_dates/?starts=${_initialDate}&ends=${_finalDate}&store=MATRIZ`)
          }} />
          {filterMessage()}
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '5px', fontSize: 10 }}>Número do Pedido</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Pagamento</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Pedido para</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Valor</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Id PagarMe</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Pago?</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Pedido</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Fazendo</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Feito</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Depósito</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>A Caminho</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Entregue</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Completo</TableCell>
              <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>Número NFC-e</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((order) => (
              <TableRow style={{ padding: '5px', fontSize: 10 }} key={order.order_id}>
                <TableCell component="th" scope="row" style={{ padding: '5px', fontSize: 10 }}>
                  {order.order_code}
                </TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>
                  {order.payment}
                </TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.date_delivery}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.total_order}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.pagarme_id}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.is_paid ? 'Sim' : 'Não'}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.date_ordered}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.doing_at}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.done_at}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.depot_at}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.transit_at}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.delivered_at}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.finished_at}</TableCell>
                <TableCell align="right" style={{ padding: '5px', fontSize: 10 }}>{order.NFCe_number}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Ingredients } from 'interfaces/stock';

import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid'; //

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  icons: {
    fill: '#3f51b5'
  },
});

interface Props {
  ingredients: Ingredients[];
}

export default function IngredientsTable(props: Props) {
  const classes = useStyles();
  const { ingredients } = props;

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nome', type: 'string', width: 400, headerAlign: 'left', align: 'left' },
    { field: 'stock', headerName: 'Estoque', type: 'string', width: 180, headerAlign: 'right', align: 'right' },
    { field: 'measure', headerName: 'Medida', type: 'string', width: 180, headerAlign: 'right', align: 'right' },
    { field: 'cost', headerName: 'Custo (R$)', type: 'string', width: 240, headerAlign: 'right', align: 'right' },


  ];

  let rows: Array<any>
  if (ingredients) {

    rows = ingredients?.map((ingredient) => (
      (
        {
          id: ingredient.ingredient_id,
          name: ingredient.name,
          stock: ingredient.quantity,
          measure: ingredient.measure,
          cost: ingredient.cost
        }
      )
    ))
  } else {
    rows = [({
      id: '',
      name: '',
      stock: '',
      measure: '',
      cost: '',
    })]
  }

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}

        />
      </div>

    </TableContainer>
  );
}

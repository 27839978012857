import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { EFilters } from '../../enums/managerDashboard';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import formatDate from 'date-fns/format';
import SearchIcon from '@material-ui/icons/Search';

interface IProps {
  filters: Array<{
    label: string;
    value: EFilters;
  }>;
  activeFilter: EFilters;
  setActiveFilter: (filter: EFilters) => void;
  dateRange: {
    initialDate: Date;
    endDate: Date;
    setInitialDate: (date: Date) => void;
    setEndDate: (date: Date) => void;
  };
  handleDateRange: () => void;
}

const DateFilter: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    filters,
    activeFilter,
    setActiveFilter,
    dateRange,
    handleDateRange
  } = props;
  const classes = useStyles();
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dateFilterName, setDateFilterName] = useState(null);

  const handleFilterClick = (filterValue: EFilters) => {
    setActiveFilter(filterValue);
    setOpenDateRange(false);
    setDateFilterName(null);
  };

  const handleDateFilterName = async () => {
    await handleDateRange();
    const dateFilterName = `${formatDate(
      dateRange.initialDate,
      'dd/MM/yyyy'
    )} até ${formatDate(dateRange.endDate, 'dd/MM/yyyy')}`;
    setDateFilterName(dateFilterName);
    setOpenDateRange(false);
  };

  return (
    <Grid item container direction="row">
      {filters.map((filter) => (
        <Button
          key={filter.value}
          variant="outlined"
          size="small"
          className={
            activeFilter === filter.value
              ? classes.activeButton
              : classes.button
          }
          onClick={() => handleFilterClick(filter.value)}
        >
          {filter.label}
        </Button>
      ))}
      {dateFilterName && (
        <Grid>
          <Typography
            variant="overline"
            className={classes.dateMessage}
          >{`Mostrando pedidos de: ${dateFilterName}`}</Typography>
          <CancelIcon
            color="action"
            onClick={() => setDateFilterName(null)}
            className={classes.dateMessageCancelButton}
          />
        </Grid>
      )}

      {!dateFilterName && (
        <>
          {!openDateRange ? (
            <Button onClick={() => setOpenDateRange((prevState) => !prevState)}>
              <DateRangeIcon className={classes.datePickerButton} />
            </Button>
          ) : (
            <>
              <Button
                style={{ alignSelf: 'flex-end' }}
                onClick={() => setOpenDateRange((prevState) => !prevState)}
              >
                <ClearIcon className={classes.datePickerButton} />
              </Button>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-initial"
                  label="Inicio"
                  value={dateRange.initialDate}
                  onChange={dateRange.setInitialDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  className={classes.datePicker}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-final"
                  label="Fim"
                  margin="none"
                  value={dateRange.endDate}
                  onChange={dateRange.setEndDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
              <Button
                style={{ alignSelf: 'flex-end' }}
                onClick={handleDateFilterName}
              >
                <SearchIcon className={classes.datePickerButton} />
              </Button>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default DateFilter;

/* eslint-disable no-var */
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';

import Paper from '@material-ui/core/Paper';
import { Product } from 'interfaces/stock';

import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';

import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import ModalAddProduct from './ModalAddProduct';
import ModalAddAvaria from './ModalAddAvaria';
import ModalHistory from './ModalHistory';
import ModalCreateProduct from './ModalCreateProduct';
import ModalEditProduct from './ModalEditProduct';

import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar
} from '@material-ui/data-grid';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    maxHeight: '650px'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  icons: {
    fill: '#3f51b5'
  }
});
const GlobalCss = withStyles({
  '@global': {
    '.MuiDataGrid-columnHeaderTitle': {
      wordSpacing: '12px'
    }
  }
})(() => null);

interface IProps {
  products: Product[];
  noMargin?: boolean;
  selectedStore?: string;
}

export default function ProductsTable(props: IProps) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openAvaria, setOpenAvaria] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(undefined);
  const [searchProduct, setSearchProduct] = React.useState('');

  const { products, noMargin, selectedStore } = props;

  const handleClickOpen = (product: Product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClickOpenAvaria = (product: Product) => {
    setSelectedProduct(product);
    setOpenAvaria(true);
  };

  const handleClickOpenHistory = (product: Product) => {
    setSelectedProduct(product);
    setOpenHistory(true);
  };

  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleClickOpenEdit = (product: Product) => {
    setOpenEdit(true);
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setSelectedProduct(undefined);
    setOpen(false);
    setOpenCreate(false);
    setOpenEdit(false);
    setOpenAvaria(false);
    setOpenHistory(false);
  };

  const lowerSearchProduct = searchProduct.toLowerCase();
  let productsFiltered;

  if (lowerSearchProduct && lowerSearchProduct.length >= 3) {
    productsFiltered = products?.filter((product) =>
      product.name.toLowerCase().includes(lowerSearchProduct)
    );
  } else {
    productsFiltered = products;
  }

  var date = new Date();
  var month = date.getMonth() + 1;
  var day = Number(date.getDate());
  var day2 = `${String(day + 1).padStart(2, '0')}/${month} `;
  var day3 = ` ${String(day + 2).padStart(2, '0')}/${month} `;
  var day4 = ` ${String(day + 3).padStart(2, '0')}/${month} `;
  var day5 = ` ${String(day + 4).padStart(2, '0')}/${month} `;
  var day6 = ` ${String(day + 5).padStart(2, '0')}/${month} `;
  var day7 = ` ${String(day + 6).padStart(2, '0')}/${month}`;

  var nextDays =
    day2 + ' ' + day3 + ' ' + day4 + ' ' + day5 + ' ' + day6 + ' ' + day7;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'action',
      headerName: 'Editar',
      type: 'number',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '2px', fill: 'blue' }}>
            {params.value}
          </span>
          <EditIcon
            className={classes.icons}
            onClick={() => {}} /*handleClickOpenHistory(params.product) */
          />
        </div>
      )
    },
    { field: 'name', headerName: 'Nome', width: 420, headerAlign: 'center' },
    {
      field: 'production',
      headerName: 'Produção',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <div
          style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}
        >
          <ControlPointIcon className={classes.icons} onClick={() => {}} />
        </div>
      )
    },
    {
      field: 'avaria',
      headerName: 'Avaria',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <div
          style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}
        >
          <PlayForWorkIcon className={classes.icons} onClick={() => {}} />
        </div>
      )
    },
    {
      field: 'sold',
      headerName: 'Vendido',
      type: 'number',
      width: 130,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'reserved',
      headerName: 'Reservado',
      type: 'number',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'nextDays',
      headerName: nextDays,
      type: 'number',
      width: 335,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{ marginRight: '2px', fill: 'blue', letterSpacing: '15px' }}
          >
            {params.value}
          </span>
        </div>
      )
    },
    {
      field: 'stock',
      headerName: 'Estoque',
      type: 'number',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '3px', fill: 'blue' }}>
            {params.value}
          </span>
          <HistoryIcon
            className={classes.icons}
            onClick={() => {}} /* handleClickOpenHistory(params.product) */
          />
        </div>
      )
    },
    {
      field: 'avarias',
      headerName: 'Avarias',
      type: 'number',
      width: 130,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'price',
      headerName: 'Preço',
      type: 'number',
      width: 130,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'cost',
      headerName: 'Custo',
      type: 'number',
      width: 130,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'profit',
      headerName: 'Lucro %',
      type: 'number',
      width: 140,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'cmv',
      headerName: 'CMV %',
      type: 'number',
      width: 135,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'value',
      headerName: 'Valor ',
      type: 'number',
      width: 130,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  var rows: Array<any>;
  if (products) {
    rows = productsFiltered?.map((product) => ({
      id: product.product_id,
      name: product.name,
      sold: product.sold,
      reserved: product.reserved,
      nextDays: product.next_days.map((value) => value.value + ' ' + ' ' + ' '),
      stock: product.stock,
      avarias: product.avarias,
      price: product.price,
      cost: product.cost,
      profit: product.price
        ? (((product.price - product.cost) / product.price) * 100).toFixed(2)
        : 0.0,
      cmv: product.price
        ? ((product.cost / product.price) * 100).toFixed(2)
        : 0.0,
      value: (product.stock * product.price).toFixed(2)
    }));
  } else {
    rows = [
      {
        id: '',
        name: '',
        sold: '',
        reserved: '',
        nextDays: '',
        stock: '',
        avarias: '',
        price: '',
        cost: '',
        profit: '',
        cmv: '',
        value: ''
      }
    ];
  }

  return (
    <TableContainer
      className={classes.tableContainer}
      style={noMargin && { margin: 0 }}
      component={Paper}
    >
      <GlobalCss />

      <Button
        style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
        color="primary"
        onClick={handleClickOpenCreate}
        className={classes.activeButton}
      >
        Adicionar Produto
      </Button>
      <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
        <div style={{ display: 'inline-flex', marginLeft: 5 }}>
          <FormControl>
            <Input
              id="my-input"
              value={searchProduct}
              type="tel"
              onChange={(event) => setSearchProduct(event.target.value)}
              aria-describedby="my-helper-text"
              placeholder="Escolha nome do Produto"
              style={{ width: 300 }}
            />
          </FormControl>
        </div>
      </div>

      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onCellClick={(params: GridCellParams) =>
            params.field == 'stock'
              ? products?.map((product) => {
                  if (params.row.id == product.product_id) {
                    handleClickOpenHistory(product);
                  }
                })
              : params.field == 'avaria'
              ? products?.map((product) => {
                  if (params.row.id == product.product_id) {
                    handleClickOpenAvaria(product);
                  }
                })
              : params.field == 'production'
              ? products?.map((product) => {
                  if (params.row.id == product.product_id) {
                    handleClickOpen(product);
                  }
                })
              : params.field == 'action'
              ? products?.map((product) => {
                  if (params.row.id == product.product_id) {
                    handleClickOpenEdit(product);
                  }
                })
              : null
          }
          components={{
            Toolbar: GridToolbar
          }}
        />
      </div>
      <ModalAddProduct
        open={open}
        onClose={handleClose}
        product={selectedProduct}
      />
      <ModalAddAvaria
        open={openAvaria}
        onClose={handleClose}
        product={selectedProduct}
      />
      <ModalHistory
        open={openHistory}
        onClose={handleClose}
        product={selectedProduct}
      />
      <ModalCreateProduct open={openCreate} onClose={handleClose} />
      <ModalEditProduct
        open={openEdit}
        product={selectedProduct}
        store={selectedStore}
        onClose={handleClose}
      />
    </TableContainer>
  );
}

import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EFilters } from '../../enums/stock';

const useStyles = makeStyles(() => ({
  button: {
    margin: '0 5px',
    color: '#211f5c',
    border: 'none',
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  datePickerButton: {
    color: '#211f5c'
  },
  datePicker: {
    width: '20%',
    marginRight: '10px'
  },
  dateMessage: {
    color: '#211f5c',
    fontWeight: 'bold',
    marginLeft: '10px'
  },
  dateMessageCancelButton: {
    marginLeft: 10,
    width: 15
  }
}));

interface Props {
  filters: Array<{
    label: string;
    value: EFilters;
  }>;
  activeFilter: EFilters;
  setActiveFilter: (filter: EFilters) => void;
}

const ProductFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const { filters, activeFilter, setActiveFilter } = props;
  const classes = useStyles();

  const handleFilterClick = (filterValue: EFilters) => {
    setActiveFilter(filterValue);
  };

  return (
    <Grid item container direction="row">
      {filters.map((filter) => (
        <Button
          key={filter.value}
          variant="outlined"
          size="small"
          color="primary"
          className={
            activeFilter === filter.value
              ? classes.activeButton
              : classes.button
          }
          onClick={() => handleFilterClick(filter.value)}
        >
          {filter.label}
        </Button>
      ))}
    </Grid>
  );
};

export default ProductFilter;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchProducts, fetchIngredients, fetchProviders } from 'redux/ducks/stock';
// import {
// MuiPickersUtilsProvider,
// KeyboardDatePicker
// } from '@material-ui/pickers';
import { EFilters } from '../../enums/stock';
import IStore from 'interfaces/store';
import Loading from 'components/Common/Loading';
import ProductFilter from './ProductFilter';
import IngredientsTable from './IngredientsTable';
import ProvidersTable from './ProvidersTable';
import ProductsTable from './ProductsTable';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import useStyles from './styles';
// import DateFnsUtils from '@date-io/date-fns';
// import SearchIcon from '@material-ui/icons/Search';
// import useStyles from './styles';
// import formatDate from 'date-fns/format';
import ToPrint from './ToPrint';

const filters = [
  {
    label: 'Produtos',
    value: EFilters.PRODUCTS
  },
  {
    label: 'Ingredientes',
    value: EFilters.INGREDIENTS
  },
  {
    label: 'Fornecedores',
    value: EFilters.PROVIDERS
  }
];

enum EStoreFilter {
  MATRIZ = 'MATRIZ',
  BYSTATION = 'BYSTATION',
}

const storeFilter = [EStoreFilter.MATRIZ, EStoreFilter.BYSTATION];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.PRODUCTS);
  const [activeStoreFilter, setActiveStoreFilter] = useState('MATRIZ')
  const { stock } = useSelector<IStore, IStore>((state) => state);

  const handleActiveStoreFilter = (value: string) => {
    setActiveStoreFilter(value);
  };
  const classes = useStyles();
  // const [date, setDate] = useState(new Date());

  // const handleDateRange = () => {
  //   dispatch(fetchProducts());
  // };

  useEffect(() => {
    if (activeFilter === EFilters.PRODUCTS) {
      dispatch(fetchProducts({ store: activeStoreFilter }));
    } else if (activeFilter === EFilters.INGREDIENTS) {
      dispatch(fetchIngredients({ store: activeStoreFilter }));
    } else{
      dispatch(fetchProviders({ store: activeStoreFilter }));
    }
  }, [dispatch, activeFilter, activeStoreFilter]);

  return (
    <>
      <Loading show={stock.fetch} />
      <Grid container spacing={3}>
        <Grid item container direction="row">
          <Typography
            color="primary"
            variant="overline"
            style={{ fontWeight: 'bold', fontSize: 14 }}
          ></Typography>
          {storeFilter.map((elmnt) => (
            <Button
            key={uuidv4()}
            variant="outlined"
            onClick={() => {
              handleActiveStoreFilter(elmnt);
            }}
            color="primary"
            size="small"
            className={activeStoreFilter === elmnt ? classes.activeButton : classes.button}
            >
            {elmnt}
          </Button>
          ))}
        </Grid>
        <ProductFilter
          activeFilter={activeFilter}
          setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
          filters={filters}
        />
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          {/* <Grid item container xs={4} alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-initial"
                label="Selecione a Data"
                value={date}
                onChange={setDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>
            <Button style={{ alignSelf: 'flex-end' }} onClick={handleDateRange}>
              <SearchIcon className={classes.datePickerButton} />
            </Button>
          </Grid> */}
          <ToPrint products={stock.products} disabled={stock.fetch} />
        </Grid>
        {activeFilter === EFilters.PRODUCTS ? (
          <ProductsTable products={stock.products} selectedStore={activeStoreFilter} />
        ) : activeFilter === EFilters.INGREDIENTS ? (
            <IngredientsTable ingredients={stock.ingredients} />
          ): (
            <ProvidersTable providers={stock.providers} />
          )}
      </Grid>
    </>
  );
}

import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  INotificationState,
  ETypesNotification,
  IManagerAction,
  GetState
} from 'interfaces/notification';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: INotificationState = {
  fetch: false,
  generalnotifications: [],
  statusnotifications: []
};

/* Authentication Reducer. */
export default (
  state: INotificationState = initialState,
  action: IManagerAction
): INotificationState => {
  switch (action.type) {
    case ETypesNotification.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesNotification.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesNotification.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesNotification.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchNotification = (): IFetchAction => ({
  type: ETypesNotification.FETCH
});

export const successFetchNotification = (payload: INotificationState): ISuccessAction => ({
  type: ETypesNotification.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesNotification.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesNotification.RESET
});

/* Authentication Side Effects Functions. */

export const fetchNotificationsGeneral = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { notification } = getState();
    dispatch(fetchNotification());
    let url = `orders/api/generalnotification/`;
    let response;
    let results = [];
    do {
      response = await fetch({
        method: EMethod.GET,
        url: url
      });
      url = response.next;
      results.push(...response.results);
    } while (response.next);
    response.results = results;
    dispatch(successFetchNotification({ ...notification, generalnotifications: response.results }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const createNotificationGeneral = (data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: 'orders/api/generalnotification/',
      data: data
    });
    dispatch(sendAlert('General Notification created successfully', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const fetchNotificationsStatus = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { notification } = getState();
    dispatch(fetchNotification());
    let url = `orders/api/statusnotifications/`;
    let response;
    let results = [];
    do {
      response = await fetch({
        method: EMethod.GET,
        url: url
      });
      url = response.next;
      results.push(...response.results);
    } while (response.next);
    response.results = results;
    dispatch(successFetchNotification({ ...notification, statusnotifications: response.results }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const createNotificationStatus = (data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: 'orders/api/statusnotifications/',
      data: data
    });
    dispatch(sendAlert('Status Notification created successfully', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const editNotificationStatus = (id: number=undefined, data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.PATCH,
      url: `orders/api/statusnotifications/${id}/`,
      data: data
    });
    dispatch(sendAlert('Status Notification edited successfully', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};
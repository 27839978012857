import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  field: {
    ".MuiInputBase-input": {
      outline: "none !important",
      border: "1px solid red",
      boxShadow: "0 0 10px #CCCCCC",
      backgroundColor: "#CCCCCC"
    },
    "&.Mui-focused fieldset": {
      border: "1px solid grey"
    },
    ".MuiFormLabel-root.Mui-focused": {
      color: "#3f51b5",
    },
    ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CCCCCC"
    }
  } ,
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import ModalEditStore from './ModalEditStore';
import {IStoreBase} from 'interfaces/store_test';
import EditIcon from '@material-ui/icons/Edit';

import { DataGrid, GridColDef, GridCellParams, GridToolbar } from '@material-ui/data-grid'; //

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  icons: {
    fill: '#3f51b5'
  },
});

interface IProps {
  stores: IStoreBase[];
  fetchedMenus: any[];
}

export default function ProductsTable(props: IProps) {
  const classes = useStyles();
  const { stores } = props;
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedStore, setSelectedStore] = React.useState(undefined);

  const handleClose = () => {
    setOpenEdit(false);
    setSelectedStore(undefined);
  };

  const handleClickOpenEdit = (store: any) => {
    setOpenEdit(true);
    setSelectedStore(store);
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nome', type: 'string', width: 1050, headerAlign: 'left', align: 'left' },
    {
      field: 'actions',
      headerName: 'Ações',
      type: 'string',
      width: 140,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '3px', fill: 'blue' }}>{(params.value)}</span>
          <EditIcon className={classes.icons} onClick={() => { }} /* handleClickOpenHistory(params.product) */ />
        </div>
      ),
    },
  ];

  let rows: Array<any> 
  
  if(stores){
    rows = stores?.map((store) => (
      (
        {
          id: store.id,
          name: store.name,
        }
      )
    ))
  }else {
    rows = [({
      id: '',
      name: '',
    })]
  }
  

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          onCellClick={(params: GridCellParams) => (

            params.field === "actions" ? stores?.map((store) => {
                if (params.row.id === store.id) {
                    console.log(params.row.id)
                    handleClickOpenEdit(store)
                }
              })
                :
                null
              ) 
            }
        />
      </div>
      <ModalEditStore
        menus={selectedStore ? selectedStore.menus : []}
        store={selectedStore}
        open={openEdit}
        fetchedMenus={props.fetchedMenus}
        onClose={handleClose}
      />
    </TableContainer>
  );
}

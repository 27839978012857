import React from 'react';
import { useLocation } from 'react-router-dom';

import clsx from 'clsx';
import { getUrlName } from 'helpers/common';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useStyles } from './styles';

import Menu from '../Menu';

import Logo from 'assets/img/mposgo_logo.svg';

interface IProps {
  children: React.ReactNode;
}

export default function Content(props: IProps) {
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [condition, setCondition] = React.useState(navigator.onLine);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const { children } = props;

  const classes = useStyles();

  window.addEventListener("online", () => {
    setCondition(true)
    console.log(condition)
  });
  window.addEventListener("offline", () => {
    setCondition(false)
    console.log(condition)
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        { condition? 
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon style={{ color: '#00004c' }} />
          </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                style={{ color: '#00004c' }}
                className={classes.title}
              >
                {getUrlName(location.pathname)}
              </Typography>
        </Toolbar>
         :
        <Toolbar className={classes.toolbar} style={{ backgroundColor: "brown" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon style={{ color: '#00004c' }} />
          </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                style={{ color: 'white', backgroundColor: "brown", textAlign: 'center', fontFamily: 'Roboto' }}
                className={classes.title}
              >
                Sem conexão, por favor verifique seu roteador!
            </Typography>
        </Toolbar>
        }
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={Logo} alt="Logo" width="136" height="28" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon style={{ color: '#a84551' }} />
          </IconButton>
        </div>
        <Divider />
        <Menu />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Container>
      </main>
    </div>
  );
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Content from 'components/Dashboard/Content';
import Logout from 'components/Authentication/Logout';

import Home from 'components/Home';
import Kitchen from 'components/Kitchen';
import Kitchen_matriz from 'components/Kitchen-matriz';
import Kitchen_by_station from 'components/Kitchen-by-station';
import Balcony from 'components/Balcony';
import Balcony_matriz from 'components/Balcony_matriz';
import Balcony_by_station from 'components/Balcony_by_station';
import Production from 'components/Production';
import Package from 'components/Package';
import IngredientProduction from 'components/IngredientProduction';
import Delivery from 'components/Delivery';
import Delivery_matriz from 'components/Delivery-matriz';
import Delivery_by_station from 'components/Delivery-by-station';
import ManagerDashboard from 'components/ManagerDashboard';
import ManagerDashboard_matriz from 'components/ManagerDashboard_matriz';
import ManagerDashboard_by_station from 'components/ManagerDashboard_by_station';
import IngredientProduction_by_station from 'components/IngredientProduction_by_station';
import Stock from 'components/Stock';
import Stock_matriz from 'components/Stock_matriz';
import Stock_by_station from 'components/Stock_by_station';
import Customers from 'components/Customers';
import History from 'components/History';
import History_by_station from 'components/History-by-station';
import History_matriz from 'components/History-matriz';
import ControlPanel from 'components/ControlPanel';
import Menus from 'components/Menus';
import Table from 'components/Table';
import Stores from 'components/Stores';

export default function Routes() {
  return (
    <Content>
      <Switch>
        <Route path="/dashboard/home" component={Home} />
        <Route path="/dashboard/manager" component={ManagerDashboard} />
        <Route path="/dashboard/manager-matriz" component={ManagerDashboard_matriz} />
        <Route path="/dashboard/manager-by-station" component={ManagerDashboard_by_station} />
        <Route path="/dashboard/stock" component={Stock} />
        <Route path="/dashboard/stock-matriz" component={Stock_matriz} />
        <Route path="/dashboard/stock-by-station" component={Stock_by_station} />
        <Route path="/dashboard/customers" component={Customers} />
        <Route path="/dashboard/order" component={Kitchen} />
        <Route path="/dashboard/order-matriz" component={Kitchen_matriz} />
        <Route path="/dashboard/order-by-station" component={Kitchen_by_station} />
        <Route path="/dashboard/production/product" component={Production} />
        <Route
          path="/dashboard/production/ingredient"
          component={IngredientProduction}
        />
        <Route
          path="/dashboard/production/ingredient_by_station"
          component={IngredientProduction_by_station}
        />
        <Route path="/dashboard/package" component={Package} />
        <Route path="/dashboard/completed" component={Balcony} />
        <Route path="/dashboard/completed-matriz" component={Balcony_matriz} />
        <Route path="/dashboard/completed-by-station" component={Balcony_by_station} />
        <Route path="/dashboard/logout" component={Logout} />
        <Route path="/dashboard/delivery" component={Delivery} />
        <Route path="/dashboard/delivery-matriz" component={Delivery_matriz} />
        <Route path="/dashboard/delivery-by-station" component={Delivery_by_station} />
        <Route path="/dashboard/table" component={Table} />
        <Route path="/dashboard/history" component={History} />
        <Route path="/dashboard/history-by-station" component={History_by_station} />
        <Route path="/dashboard/history-matriz" component={History_matriz} />
        <Route path="/dashboard/notifications" component={ControlPanel} />
        <Route path="/dashboard/menus" component={Menus} />
        <Route path="/dashboard/stores" component={Stores} />
      </Switch>
    </Content>
  );
}

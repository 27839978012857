import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../redux/ducks/alert';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { InputCard, useStyles } from './styles';
import { MANAGER_PASSWORD } from '../../settings';
import { EAlertVariant } from '../../interfaces/alert';

interface Props {
  handleSuccess: () => void;
}

export default function CheckPassword({ handleSuccess }: Props): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmitPassword = () => {
    if (password === '') {
      return dispatch(sendAlert('Por favor, digite a senha!'));
    }
    if (password !== MANAGER_PASSWORD) {
      return dispatch(sendAlert('Senha incorreta!', EAlertVariant.ERROR));
    }
    handleSuccess();
  };

  return (
    <InputCard>
      <Grid item>
        <Typography variant="h6" align="center">
          Página Restrita!
        </Typography>
        <Typography paragraph align="center" style={{ marginBottom: 0 }}>
          Digite a senha para continuar.
        </Typography>
      </Grid>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitPassword();
        }}
      >
        <FormControl>
          <InputLabel htmlFor="standard-adornment-password">
            Password
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
      <Button
        variant="outlined"
        size="medium"
        type="submit"
        className={classes.activeButton}
        onClick={handleSubmitPassword}
      >
        Enviar
      </Button>
    </InputCard>
  );
}

import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Product } from 'interfaces/stock';
import { Button, InputLabel, Input } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { submitAvariaOrder } from 'redux/ducks/stock';

import useStyles from './styles';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product;
}

export default function ModalAddAvaria(props: SimpleDialogProps) {
  const classes = useStyles();

  const { onClose, open, product } = props;
  const [quantity, setQuantity] = React.useState(0);

  const dispatch = useDispatch();

  const handleClose = () => {
    setQuantity(0);
    onClose();
  };

  const handleQuantity = (event: any) => {
    setQuantity(event.target.value)
  }

  const submitCreation = async () => {
    dispatch(submitAvariaOrder({
      quantity: quantity,
      product: product.product_id
    }))
      .then((result: boolean) => {
        if (result) handleClose()
      })
  }

  if (!product) return null;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Criar ordem de avaria para {product.name}
      </DialogTitle>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel htmlFor="my-input">Quantidade</InputLabel>
        <Input id="my-input" aria-describedby="my-helper-text" type="number" value={quantity} onChange={handleQuantity} />
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
        <Button
          onClick={submitCreation}
          color="primary"
          disabled={quantity <= 0}
          variant="outlined"
          className={classes.activeButton}
        >
          Criar
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          className={classes.button}
        >
          Cancelar
        </Button>      </div>
    </Dialog>
  );
}

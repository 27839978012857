import React from 'react';
import { useSelector } from 'react-redux';
import IStore from 'interfaces/store';
import { IAuthenticationState } from 'interfaces/authentication';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import KitchenIcon from '@material-ui/icons/KitchenOutlined';
import ProductionIcon from '@material-ui/icons/FastfoodOutlined';
import SignOutIcon from '@material-ui/icons/ExitToAppOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SportsMotorsportsOutlinedIcon from '@material-ui/icons/SportsMotorsportsOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import EventSeatOutlinedIcon from '@material-ui/icons/EventSeatOutlined';
import HistoryIcon from '@material-ui/icons/History';
import StoreIcon from '@material-ui/icons/Store';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import BallotIcon from '@material-ui/icons/Ballot';

import { ItemMenu } from './styles';
import { Grid } from '@material-ui/core';
export default function Menu() {
  const state = useSelector<IStore, IAuthenticationState>(
    (state) => state.authentication
  );
  return (
    <>
      <Grid container direction="column">

        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/notifications" >
            <ListItem button>
              <ListItemIcon>
                <NotificationsOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Notificações" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/manager">
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Manager" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_matriz === true ? (
          <ItemMenu to="/dashboard/manager-matriz">
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Manager" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_bystation === true ? (
          <ItemMenu to="/dashboard/manager-by-station">
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Manager" />
            </ListItem>
          </ItemMenu>
        ) : null}

        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/order">
            <ListItem button>
              <ListItemIcon>
                <KitchenIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Encomendas" />
            </ListItem>
          </ItemMenu>
        ) : null}

        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/delivery">
            <ListItem button>
              <ListItemIcon>
                <SportsMotorsportsOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Entregas" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_matriz === true ? (
          <ItemMenu to="/dashboard/delivery-matriz">
            <ListItem button>
              <ListItemIcon>
                <SportsMotorsportsOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Entregas" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_bystation === true ? (
          <ItemMenu to="/dashboard/delivery-by-station">
            <ListItem button>
              <ListItemIcon>
                <SportsMotorsportsOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Entregas" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && (state.is_superuser === true || state.is_bystation === true) ? (
          <ItemMenu to="/dashboard/table">
            <ListItem button>
              <ListItemIcon>
                <EventSeatOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Mesas" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/completed">
            <ListItem button>
              <ListItemIcon>
                <ReceiptIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Completo" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_matriz === true ? (
          <ItemMenu to="/dashboard/completed-matriz">
            <ListItem button>
              <ListItemIcon>
                <ReceiptIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Completo" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_bystation === true ? (
          <ItemMenu to="/dashboard/completed-by-station">
            <ListItem button>
              <ListItemIcon>
                <ReceiptIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Completo" />
            </ListItem>
          </ItemMenu>
        ) : null}

        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/history">
            <ListItem button>
              <ListItemIcon>
                <HistoryIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Transações" />
            </ListItem>
          </ItemMenu>
        ) : null}

        {state && state.is_bystation === true ? (
          <ItemMenu to="/dashboard/history-by-station">
            <ListItem button>
              <ListItemIcon>
                <HistoryIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Transações" />
            </ListItem>
          </ItemMenu>
        ) : null}

        {state && state.is_matriz === true ? (
          <ItemMenu to="/dashboard/history-matriz">
            <ListItem button>
              <ListItemIcon>
                <HistoryIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Transações" />
            </ListItem>
          </ItemMenu>
        ) : null}

        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/stock">
            <ListItem button>
              <ListItemIcon>
                <TableChartOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_matriz === true ? (
          <ItemMenu to="/dashboard/stock-matriz">
            <ListItem button>
              <ListItemIcon>
                <TableChartOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_bystation === true ? (
          <ItemMenu to="/dashboard/stock-by-station">
            <ListItem button>
              <ListItemIcon>
                <TableChartOutlinedIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </ListItem>
          </ItemMenu>
        ) : null}

        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/menus">
            <ListItem button>
              <ListItemIcon>
                <RestaurantMenuIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Menus" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/stores">
            <ListItem button>
              <ListItemIcon>
                <StoreIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Lojas" />
            </ListItem>
          </ItemMenu>
        ) : null}
        <ItemMenu to="/dashboard/customers">
          <ListItem button>
            <ListItemIcon>
              <PeopleOutlineOutlinedIcon style={{ color: '#a84551' }} />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        </ItemMenu>

        {state && state.is_matriz === true ? (
          <ItemMenu to="/dashboard/order-matriz">
            <ListItem button>
              <ListItemIcon>
                <KitchenIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Encomendas" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_bystation === true ? (
          <ItemMenu to="/dashboard/order-by-station">
            <ListItem button>
              <ListItemIcon>
                <KitchenIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Encomendas" />
            </ListItem>
          </ItemMenu>
        ) : null}


        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/production/product">
            <ListItem button>
              <ListItemIcon>
                <ProductionIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Produção" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/production/ingredient">
            <ListItem button>
              <ListItemIcon>
                <ShoppingCartIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Compras" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_bystation === true ? (
          <ItemMenu to="/dashboard/production/ingredient_by_station">
            <ListItem button>
              <ListItemIcon>
                <ShoppingCartIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Compras" />
            </ListItem>
          </ItemMenu>
        ) : null}
        {state && state.is_superuser === true ? (
          <ItemMenu to="/dashboard/package">
            <ListItem button>
              <ListItemIcon>
                <BallotIcon style={{ color: '#a84551' }} />
              </ListItemIcon>
              <ListItemText primary="Pacotes" />
            </ListItem>
          </ItemMenu>
        ) : null}

        <ItemMenu
          to="/dashboard/logout"
          style={{ marginTop: 50, bottom: "bottom" }}
        >
          <ListItem button>
            <ListItemIcon>
              <SignOutIcon style={{ color: '#a84551' }} />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </ItemMenu>
      </Grid>
    </>
  )
};

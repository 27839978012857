import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 300,
        maxWidth: '48%',
        maxHeight: 300,
        overflowY: 'scroll'
    },
});

interface Props {
    label1: string
    label2: string
    data: Array<{
        name: string;
        amount: number;
        total_sold?: number;
    }>;
    total_count?: number
    total_value?: number
}

export default function SimpleTable(props: Props) {
    const classes = useStyles();
    const { label1, label2, data, total_count, total_value } = props;

    return (
        <TableContainer component={Paper} className={classes.table}>
            <Table stickyHeader aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{label1}</TableCell>
                        <TableCell align="left">{label2}</TableCell>
                        {total_count ? (<TableCell align="left">% Q</TableCell>) : null}
                        <TableCell align="left">Valor</TableCell>
                        {total_value ? (<TableCell align="left">% V</TableCell>) : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((d) => (
                        <TableRow key={d.name}>
                            <TableCell align="left">{d.name}</TableCell>
                            <TableCell align="left">{d.amount}</TableCell>
                            {total_count ? (<TableCell align="left">{((d.amount / total_count) * 100).toFixed(2)}%</TableCell>) : null}
                            <TableCell align="left">{d.total_sold ? d.total_sold.toFixed(2) : 0.00}</TableCell>
                            {total_value ? (<TableCell align="left">{((d.total_sold / total_value) * 100).toFixed(2)}%</TableCell>) : null}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

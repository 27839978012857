import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_NOTIFICATION } from 'settings';
import { connect } from 'utils/socket';

import { getKitchen, updateKitchen } from 'redux/ducks/kitchen';

import IStore from 'interfaces/store';
import { IKitchenState } from 'interfaces/kitchen';

import { ESocketStatus } from 'enums/socket';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

import Panel from './Panel';
import Loading from 'components/Common/Loading';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

// import useStyles from './styles';

enum EDateFilter {
  HOJE = 'HOJE',
  SEMANA = 'SEMANA',
  ALL = 'ALL'
}

const dateFilter = [EDateFilter.HOJE, EDateFilter.SEMANA, EDateFilter.ALL];

enum EStoreFilter {
  MATRIZ = 'MATRIZ',
  BYSTATION = 'BYSTATION',
  ALL = 'ALL'
}

const storeFilter = [
  EStoreFilter.MATRIZ,
  EStoreFilter.BYSTATION,
  EStoreFilter.ALL
];

export default function Kitchen() {
  const dispatch = useDispatch();
  const { fetch, todo, doing, done, depot } = useSelector<
    IStore,
    IKitchenState
  >((state) => state.kitchen);
  const classes = useStyles();

  const [online, setOnline] = useState(false);
  const [filteredTodo, setFilteredTodo] = useState(todo);
  const [filteredDoing, setFilteredDoing] = useState(doing);
  const [filteredDone, setFilteredDone] = useState(done);
  const [filteredDepot, setFilteredDepot] = useState(depot);
  const [activeDateFilter, setActiveDateFilter] = useState('HOJE');
  const [activeStoreFilter, setActiveStoreFilter] = useState('ALL');

  const handleActiveDateFilter = (value: string) => {
    setActiveDateFilter(value);
  };

  const handleActiveStoreFilter = (value: string) => {
    setActiveStoreFilter(value);
  };

  const getWeekDates = () => {
    let now = new Date();
    let dayOfWeek = now.getDay();
    let numDay = now.getDate();

    let start = new Date(now);
    start.setDate(numDay - dayOfWeek + 1);
    start.setHours(0, 0, 0, 0);

    let end = new Date(now);
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  const getTodayDates = () => {
    let now = new Date();

    let start = new Date(now);
    start.setHours(0, 0, 0, 0);

    let end = new Date(now);
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  useEffect(() => {
    dispatch(
      getKitchen({
        date_delivery: activeDateFilter,
        store: activeStoreFilter,
        a_fazer: true,
        fazendo: true,
        feito: true,
        depot: true
      })
    );
  }, [dispatch, activeDateFilter, activeStoreFilter]);

  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => setOnline(true);

    kitchenSocket.onmessage = (event) => {
      let new_data = JSON.parse(event.data);
      console.log(new_data);
      console.log(new_data.message.store_id);
      if (activeStoreFilter === 'MATRIZ') {
        if (new_data && new_data.message.store_id === null) {
          dispatch(updateKitchen(JSON.parse(event.data)));
        }
      } else if (activeStoreFilter === 'BYSTATION') {
        if (new_data && new_data.message.store_id === 1) {
          dispatch(updateKitchen(JSON.parse(event.data)));
        }
      } else {
        dispatch(updateKitchen(JSON.parse(event.data)));
      }
    };

    kitchenSocket.onerror = () => kitchenSocket.close();

    kitchenSocket.onclose = () => kitchenSocket.close();

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (kitchenSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online, activeStoreFilter]);

  useEffect(() => {
    const [start, end] =
      activeDateFilter === EDateFilter.HOJE
        ? getTodayDates()
        : activeDateFilter === EDateFilter.SEMANA
        ? getWeekDates()
        : [null, null];

    const toDofilter = todo.filter(
      (order) =>
        (new Date(order.date_delivery) >= start &&
          new Date(order.date_delivery) <= end) ||
        (!order.date_delivery &&
          new Date(order.date_ordered) >= start &&
          new Date(order.date_ordered) <= end) ||
        (!start && !end)
    );
    setFilteredTodo(toDofilter);

    const doingfilter = doing.filter(
      (order) =>
        (new Date(order.date_delivery) >= start &&
          new Date(order.date_delivery) <= end) ||
        (!order.date_delivery &&
          new Date(order.date_ordered) >= start &&
          new Date(order.date_ordered) <= end) ||
        (!start && !end)
    );
    setFilteredDoing(doingfilter);

    const donefilter = done.filter(
      (order) =>
        (new Date(order.date_delivery) >= start &&
          new Date(order.date_delivery) <= end) ||
        (!order.date_delivery &&
          new Date(order.date_ordered) >= start &&
          new Date(order.date_ordered) <= end) ||
        (!start && !end)
    );
    setFilteredDone(donefilter);

    const depotfilter = depot.filter(
      (order) =>
        (new Date(order.date_delivery) >= start &&
          new Date(order.date_delivery) <= end) ||
        (!order.date_delivery &&
          new Date(order.date_ordered) >= start &&
          new Date(order.date_ordered) <= end) ||
        (!start && !end)
    );
    setFilteredDepot(depotfilter);
  }, [activeDateFilter, activeStoreFilter, todo, doing, done, depot]);

  const panels = [
    {
      key: 1,
      headerStyle: classes.cardHeaderTodo,
      headerTitle: 'SOLICITADO',
      bodyStyle: classes.cardBodyTodo,
      orders: filteredTodo,
      showBackBtn: false,
      showExtraInfo: true,
      showPrintBtn: true,
      showIsPaid: true
    },
    {
      key: 2,
      headerStyle: classes.cardHeaderDoing,
      headerTitle: 'PREPARANDO',
      bodyStyle: classes.cardBodyDoing,
      orders: filteredDoing,
      showExtraInfo: true,
      showPrintBtn: true,
      showIsPaid: true
    },
    {
      key: 3,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'FEITO',
      bodyStyle: classes.cardBodyDone,
      orders: filteredDone,
      showOKBtn: true,
      showBackBtn: true,
      showExtraInfo: true,
      showPrintBtn: true,
      showIsPaid: true
    },
    {
      key: 4,
      headerStyle: classes.cardHeaderDepot,
      headerTitle: 'DEPÓSITO',
      bodyStyle: classes.cardBodyDepot,
      orders: filteredDepot,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showExtraInfo: true,
      showDriverBtn: true,
      showPrintBtn: true
    }
  ];

  return (
    <>
      <Loading show={fetch} />
      <Grid style={{ marginBottom: 10 }}>
        <Typography
          color="primary"
          variant="overline"
          style={{ fontWeight: 'bold', fontSize: 14 }}
        >{`pedidos da loja: `}</Typography>
        {storeFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            variant="outlined"
            onClick={() => {
              handleActiveStoreFilter(elmnt);
            }}
            color="primary"
            style={{
              marginRight: 3,
              fontSize: '0.6rem'
            }}
            className={
              activeStoreFilter === elmnt
                ? `${classes.activeButton}`
                : `${classes.button}`
            }
          >
            {elmnt}
          </Button>
        ))}
      </Grid>
      <Grid style={{ marginBottom: 10 }}>
        <Typography
          color="primary"
          variant="overline"
          style={{ fontWeight: 'bold', fontSize: 14 }}
        >{`pedidos que serão entregues: `}</Typography>
        {dateFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            variant="outlined"
            onClick={() => {
              handleActiveDateFilter(elmnt);
            }}
            color="primary"
            style={{
              marginRight: 3,
              fontSize: '0.6rem'
            }}
            className={
              activeDateFilter === elmnt
                ? `${classes.activeButton}`
                : `${classes.button}`
            }
          >
            {elmnt}
          </Button>
        ))}
      </Grid>
      <Grid container spacing={3} direction="row" justify="space-between">
        {panels.map((panel) => (
          <Panel
            key={panel.key}
            headerStyle={panel.headerStyle}
            headerTitle={panel.headerTitle}
            bodyStyle={panel.bodyStyle}
            orders={panel.orders}
            showOKBtn={panel.showOKBtn}
            showBackBtn={panel.showBackBtn}
            showIsPaid={panel.showIsPaid}
            showPrintBtn={panel.showPrintBtn}
            showDriverBtn={panel.showDriverBtn}
            showExtraInfo={panel.showExtraInfo}
          />
        ))}
      </Grid>
      {!fetch &&
        todo.length === 0 &&
        doing.length === 0 &&
        done.length === 0 &&
        depot.length === 0 && (
          <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
            No orders yet
          </Typography>
        )}
    </>
  );
}

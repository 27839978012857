import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import {
  fetchDashboard,
  fetchDashboardWithDateRange
} from 'redux/ducks/manager';
import formatDate from 'date-fns/format';

import { EFilters } from '../../enums/managerDashboard';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import DateFilter from './DateFilter';
import ExpensesCard from './ExpensesCard';
import DateCard from './DateCard';
import PieChart from './PieChart';
// import BarChart from './BarChart';
import LineChart from './LineChart';
import NumberLessCard from './numberLessCard';
//import NumberCard from './NumberCard';
import TableChart from './TableChart';

const filters = [
  {
    label: 'Hoje',
    value: EFilters.TODAY
  },
  {
    label: 'Ontem',
    value: EFilters.YESTERDAY
  },
  {
    label: 'Ultimos 7 dias',
    value: EFilters.LAST_WEEK
  },
  {
    label: 'Ultimos 30 dias',
    value: EFilters.LAST_MONTH
  }
];

export default function ManagerDashboard(): JSX.Element {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.TODAY);
  const [initialDate, setInitialDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { manager } = useSelector<IStore, IStore>((state) => state);
  const revenue = Object.values(manager.balance.revenue).reduce(
    (value, sum) => value + sum,
    0
  );
  const cost =
    Object.values(manager.balance.cost).reduce((value, sum) => value + sum, 0) -
    manager.balance.cost.discount;

  useEffect(() => {
    dispatch(fetchDashboard(activeFilter, 'BYSTATION'));
  }, [dispatch, activeFilter]);

  const handleDateRange = () => {
    dispatch(
      fetchDashboardWithDateRange(
        formatDate(initialDate, 'yyyy-MM-dd'),
        formatDate(endDate, 'yyyy-MM-dd'),
        'BYSTATION'
      )
    );
  };
  console.log('manager  => ', manager);
  return (
    <>
      <Loading show={manager.fetch} />

      <Grid container spacing={3}>
        <DateFilter
          activeFilter={activeFilter}
          setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
          filters={filters}
          dateRange={{
            initialDate,
            endDate,
            setInitialDate,
            setEndDate
          }}
          handleDateRange={handleDateRange}
        />
        <Grid item container direction="row" justify="space-between">
          <ExpensesCard
            label="Entrada"
            header={
              manager.balance.came_from_count.waiter +
              manager.balance.came_from_count.app
            }
            percentage={((manager.balance.came_from.waiter +
              manager.balance.came_from.app) / (manager.balance.came_from.waiter +
                manager.balance.came_from.app)) * 100}
            value={revenue}
          />
          {/* <ExpensesCard
            label="Pedidos do App"
            header={manager.balance.came_from_count.app}
            percentage={(manager.balance.came_from.app / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.came_from.app}
          /> */}
          <ExpensesCard
            label="Pedidos do serviço"
            header={manager.balance.came_from_count.waiter}
            percentage={(manager.balance.came_from.waiter / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.came_from.waiter}
          />
          <ExpensesCard
            label="Mesa"
            header={manager.balance.where_go_count.table}
            percentage={(manager.balance.where_go.table / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.where_go.table}
          />
          <ExpensesCard
            label="Coleção"
            header={manager.balance.where_go_count.collection}
            percentage={(manager.balance.where_go.collection / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.where_go.collection}
          />
          <ExpensesCard
            label="Entrega"
            header={manager.balance.where_go_count.delivery}
            percentage={(manager.balance.where_go.delivery / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.where_go.delivery}
          />
        </Grid>
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Entradas
          </Typography>
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <ExpensesCard
            header={manager.balance.revenue_count.cash}
            label="Dinheiro"
            percentage={(manager.balance.revenue.cash / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.revenue.cash}
          />
          <ExpensesCard
            header={manager.balance.revenue_count.card}
            label="Cartão"
            percentage={(manager.balance.revenue.card / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.revenue.card}
          />
          <ExpensesCard
            label="Maquineta"
            header={manager.balance.revenue_count.machine}
            percentage={(manager.balance.revenue.machine / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.revenue.machine}
          />
          <ExpensesCard
            label="Transferência/Pix"
            header={manager.balance.revenue_count.transfer}
            percentage={(manager.balance.revenue.transfer / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.revenue.transfer}
          />
          <ExpensesCard label="Mesas Abertas"
            header={manager.balance.revenue_count.others}
            percentage={(manager.balance.revenue.others / (manager.balance.came_from.waiter +
              manager.balance.came_from.app)) * 100}
            value={manager.balance.revenue.others}
          />
        </Grid>
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Custos
          </Typography>
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <NumberLessCard label="Custo total" value={cost} />
          <NumberLessCard
            label="Custos Gerais"
            value={manager.balance.cost.general}
          />

          <NumberLessCard
            label="Motoristas"
            value={manager.balance.cost.drivers}
          />
          <NumberLessCard label="Desperdício" value={0} />
          <NumberLessCard
            label="Descontos"
            value={manager.balance.cost.discount}
          />
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <LineChart title="novos pedidos" data={manager.orders.news} />
          <PieChart open={manager.orders.open} closed={manager.orders.closed} />
          <LineChart
            title="Valor Médio por pedido"
            data={manager.orders.average}
            floatNumbers
            total_average={manager.orders.total_average}
          />
        </Grid>
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Médias de Tempo
          </Typography>
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <DateCard
            label="Fazendo - Feito"
            time={manager.balance.timings.doing_done}
          />
          <DateCard
            label="Fazendo - Depósito"
            time={manager.balance.timings.doing_depot}
          />
          <DateCard
            label="Depósito - A Caminho"
            time={manager.balance.timings.depot_transit}
          />
          <DateCard
            label="A Caminho - Entregue"
            time={manager.balance.timings.transit_delivered}
          />
          <DateCard
            label="Fazendo - Completo"
            time={manager.balance.timings.doing_completed}
          />
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <TableChart
            label1="Produto"
            label2="Quant."
            data={manager.ranks.products}
            total_count={manager.ranks.total_products}
            total_value={manager.ranks.total_products_value}
          />
          {/* <BarChart
            title="Produtos mais vendidos"
            size="medium"
            data={manager.ranks.products}
          /> */}
          <TableChart
            label1="Bairro"
            label2="Quant."
            data={manager.ranks.neighborhood}
            total_count={manager.ranks.total_neighborhood}
            total_value={manager.ranks.total_neighborhood_value}
          />
          {/* <BarChart
            title="ranking dos bairros"
            size="medium"
            data={manager.ranks.neighborhood}
          /> */}
        </Grid>
      </Grid>
    </>
  );
}

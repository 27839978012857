import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setKitchenStatus, setKitchenRevertStatus } from 'redux/ducks/kitchen';

import { IKitchen, IKitchenState } from 'interfaces/kitchen';
import IStore from 'interfaces/store';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import Animate from 'components/Common/Animate';
import Order from './Order';

interface IProps {
  headerTitle: string;
  headerStyle: string;
  bodyStyle: string;
  orders: IKitchen[];
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showExtraInfo?: boolean;
  showDriverBtn?: boolean;
}

export default function Panel(props: IProps): JSX.Element {
  const {
    headerTitle,
    headerStyle,
    bodyStyle,
    orders,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showPrintBtn,
    showExtraInfo,
    showDriverBtn
  } = props;
  const dispatch = useDispatch();
  const { currentOrderID } = useSelector<IStore, IKitchenState>(
    (state) => state.kitchen
  );

  return (
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3} style={{ padding: '5px' }}>
      <Animate container={false}>
        <Card className={headerStyle}>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ fontWeight: 300, fontSize: 16 }}
          >
            {headerTitle}
          </Typography>
        </Card>
      </Animate>
      {orders.length > 0 &&
        orders.map((order) => (
          <Order
            key={order.order_id}
            bodyStyle={bodyStyle}
            order={order}
            showBackBtn={showBackBtn}
            showDriverBtn={showDriverBtn}
            showOKBtn={showOKBtn}
            showIsPaid={showIsPaid}
            showPrintBtn={showPrintBtn}
            showExtraInfo={showExtraInfo}
            currentOrderID={currentOrderID}
            setKitchenStatus={() => dispatch(setKitchenStatus(order))}
            setRevertKitchenStatus={() =>
              dispatch(setKitchenRevertStatus(order))
            }
          />
        ))}
    </Grid>
  );
}

Panel.defaultProps = {
  showOKBtn: true,
  showBackBtn: true,
  showIsPaid: false,
  showPrintBtn: false,
  showExtraInfo: false
};

import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import {IKitchen} from 'interfaces/kitchen';

export interface ITable {
  id: number;
  number: number;
  name: string;
  orders: IKitchen[];
  table_total?: number;
  status?: string;
  order_code?: string;
  created_at?: string;
}

export interface ITableState {
  fetch: boolean;
  results: ITable[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesTable {
  FETCH = 'Table/FETCH',
  SUCCESS = 'Table/SUCCESS',
  FAILURE = 'Table/FAILURE',
}

export interface IFetchAction extends IBaseAction<ETypesTable, null> {
  type: ETypesTable.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesTable, ITableState> {
  type: ETypesTable.SUCCESS;
  payload: ITableState;
}

export interface IFailureAction extends IBaseAction<ETypesTable, string> {
  type: ETypesTable.FAILURE;
  payload: string;
}

export type ITableAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<ITableAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | ITableAction
    | ThunkAction
    | PromiseAction
    | Array<ITableAction>
    | IAlertAction
) => any;

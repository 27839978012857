import React, { useState, useEffect } from 'react';
import { IKitchen } from 'interfaces/kitchen';
import formatDate from 'date-fns/format';
import { pt } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';

import { getDateByStatus, hideButtonByDate } from 'helpers/kitchen';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import {
  getPaymentLogo,
  getWhatsappWithPicPayMessage,
  getPaymentType
} from 'helpers/common';
import { getDeliveryType, getWhastappUrl } from 'helpers/kitchen';
import { useStyles } from './styles';

import Motorcycle from '../../assets/img/motorcycle.png';
import LeftIcon from '@material-ui/icons/ArrowBackIosOutlined';
import RightIcon from '@material-ui/icons/ArrowForwardIosOutlined';

import Animate from 'components/Common/Animate';
import Receipt from './Receipt';
import Nfce from './nfce';

interface IProps {
  bodyStyle: string;
  order: IKitchen;
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showExtraInfo?: boolean;
  showDriverBtn?: boolean;
  currentOrderID: number;
  setKitchenStatus: () => void;
  setRevertKitchenStatus: () => void;
}

const Order: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    bodyStyle,
    order,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showPrintBtn,
    showExtraInfo,
    showDriverBtn,
    // currentOrderID,
    setKitchenStatus,
    setRevertKitchenStatus
  } = props;
  const classes = useStyles();

  const [disableButtons, setDisableButtons] = useState(hideButtonByDate(order));

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisableButtons(hideButtonByDate(order));
    }, 35000);
    return () => clearTimeout(timer);
  }, [order]);

  const driverPhone = order?.driver?.phone_number.replace(/\D/g, '');
  const addressEncoded = !order?.delivery_address
    ? order.delivery_address
    : order.delivery_address.split(' : ')[1].split(' ').join('%2B');

  const regex = /(?<=-\s).+(?=\s\()|(?<=-\s).+/gm;

  const found = order.delivery_address
    ? order.delivery_address.match(regex)
    : null;
  const bairro = found != null ? found[0] : null;
  const _renderName = () => {
    if (order?.client?.name) return order?.client?.name;
    if (order?.owner?.name) return order?.owner?.name;
    return '';
  };

  const background_type = (type: string) => {
    const values = {
      'O-D': '#ccf6c8',
      'O-C': '#f6d6ad',
      'O-M': '#a6f6f1'
    };

    return values[type];
  };

  console.log('state ==> ', JSON.parse(localStorage.getItem('state')));
  return (
    <Animate key={order.order_id}>
      <Card className={bodyStyle}>
        <CardContent style={{ padding: '5px' }}>
          <Grid
            container
            justify="space-between"
            style={{ marginBottom: '20px', alignItems: 'center' }}
          >
            <Typography // Order Code
              variant="overline"
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: 1,
                backgroundColor: background_type(order.type_of_order),
                padding: '4px 2px',
                marginBottom: 3
              }}
            >
              {order?.order_code}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography // Date
                className={classes.pos}
                variant="overline"
                style={{
                  margin: 0,
                  lineHeight: 1,
                  color: '#909090',
                  marginBottom: '5px'
                }}
              >
                {formatDate(
                  new Date(getDateByStatus(order.status, order)),
                  'EEE - dd/MM/yy - HH:mm',
                  { locale: pt }
                )}
              </Typography>
              {order.date_delivery && (
                <Typography // Date Delivery
                  className={classes.pos}
                  variant="overline"
                  style={{
                    margin: 0,
                    fontWeight: 'bold',
                    lineHeight: 1,
                    marginBottom: '5px'
                  }}
                >
                  {formatDate(
                    new Date(order.date_delivery),
                    'EEE - dd/MM/yy - HH:mm',
                    { locale: pt }
                  )}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid>
              <Typography // Order Client
                style={{
                  lineHeight: 1,
                  fontWeight: 'bold'
                }}
              >
                {_renderName()}
              </Typography>
              <Typography>
                {order?.payment && getPaymentType(order?.payment)}
              </Typography>
              {showIsPaid && (
                <Typography
                  style={{
                    color: 'green'
                  }}
                >
                  {order?.is_paid && 'PAGO'}
                </Typography>
              )}
            </Grid>
            {showIsPaid && order.payment && (
              <Grid>
                {order?.payment === 'picpay' ? (
                  <a href={getWhatsappWithPicPayMessage(order)} target="blank">
                    <img
                      src={getPaymentLogo(order?.payment)}
                      alt="Logo"
                      width="50"
                      height="50"
                    />
                  </a>
                ) : (
                  <img
                    src={getPaymentLogo(order?.payment)}
                    alt="Logo"
                    width="50"
                    height="50"
                  />
                )}
              </Grid>
            )}
          </Grid>

          <Grid container justify="space-between" alignItems="center">
            {order?.items?.map((item) => (
              <Grid
                key={uuidv4()}
                style={{
                  backgroundColor: '#f5f5f5',
                  marginBottom: 10,
                  paddingLeft: 8,
                  paddingRight: 8,
                  borderRadius: 10,
                  width: '100%'
                }}
              >
                <Typography // Product Name
                  variant="h6"
                  className={classes.productInfo}
                  style={{ marginTop: 10 }}
                >
                  {`${item?.quantity + 'x' ?? ''} ${
                    item?.product?.name ?? 'Indisponível'
                  }`}
                </Typography>
                {item?.extra_orders?.length > 0 && (
                  <Typography
                    paragraph
                    variant="overline"
                    style={{
                      fontWeight: 'bold',
                      marginBottom: -3,
                      marginTop: -10
                    }}
                  >
                    Extras
                  </Typography>
                )}
                {item?.extra_orders?.length > 0 &&
                  item?.extra_orders?.map((extra) => (
                    <Typography
                      key={extra.id}
                      variant="overline"
                      paragraph
                      style={{ marginBottom: -5 }}
                    >
                      {`${extra?.quantity} x ${extra?.measure_quantity}${extra?.measure} ${extra?.ingredient}`}
                    </Typography>
                  ))}
                {item.comment && (
                  <Grid className={classes.comment}>
                    <Typography
                      variant="overline"
                      style={{ fontWeight: 'bold' }}
                    >
                      Observação:
                    </Typography>
                    <Typography paragraph variant="overline">
                      {item.comment}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
          {order.delivery_address && bairro && (
            <Grid>
              <Typography variant="overline" style={{ fontWeight: 'bold' }}>
                BAIRRO:
              </Typography>
              <Typography variant="overline">{bairro}</Typography>
            </Grid>
          )}
          {order.final_comment && (
            <>
              <Grid>
                <Typography variant="overline" style={{ fontWeight: 'bold' }}>
                  COMENTÁRIO
                </Typography>
              </Grid>
              <Typography variant="overline">
                {`${order.final_comment}`}
              </Typography>
            </>
          )}
          {showExtraInfo && (
            <Grid container justify="space-between" direction="column">
              <Typography variant="overline" style={{ fontWeight: 'bold' }}>
                DETALHES:
              </Typography>

              <Typography variant="overline">
                Tipo: {`${getDeliveryType(order?.type_of_order)}`}
              </Typography>
              {`${getDeliveryType(order?.type_of_order)}` === 'Entrega' && (
                <>
                  <Typography variant="overline">
                    Motorista:{' '}
                    {`${
                      order?.gomotoboy_driver_name
                        ? order?.gomotoboy_driver_name
                        : 'NÃO DEFINIDO'
                    }`}
                  </Typography>

                  {order?.payment === 'cash' && (
                    <Typography variant="overline">
                      Troco:{' '}
                      {`${order?.cash_change ? order.cash_change : 'Não'}`}
                    </Typography>
                  )}
                </>
              )}
              {`${getDeliveryType(order?.type_of_order)}` === 'Mesa' && (
                <>
                  <Typography variant="overline">
                    Número da Mesa:{' '}
                    {`${
                      order?.table
                        ? order?.table?.number
                        : 'NÃO DEFINIDO'
                    }`}
                  </Typography>

                  <Typography variant="overline">
                    Nome da Mesa:{' '}
                    {`${
                      order?.table
                        ? order?.table?.name
                        : 'NÃO DEFINIDO'
                    }`}
                  </Typography>
                </>
              )}
            </Grid>
          )}
        </CardContent>
        {showDriverBtn && order.delivery_address && order.driver?.phone_number && (
          <>
            <CardActions>
              <Grid container justify="center">
                <Button size="small">
                  <a
                    target="blank"
                    href={getWhastappUrl(order, driverPhone, addressEncoded)}
                  >
                    <img src={Motorcycle} width="30" height="30" alt="Driver" />
                  </a>
                </Button>
              </Grid>
            </CardActions>
          </>
        )}

        <CardActions>
          <div className="card-buttons-wrapper">
            {showBackBtn ? (
              disableButtons ? (
                <Button
                  size="small"
                  // disabled={currentOrderID === order.order_id}
                  onClick={setRevertKitchenStatus}
                  style={{ minWidth: '0px' }}
                >
                  <LeftIcon />
                </Button>
              ) : (
                <Button disabled>{''}</Button>
              )
            ) : (
              <Button disabled>{''}</Button>
            )}
            {showPrintBtn && (
              <>
                <Receipt
                  order={order}
                  // disabled={currentOrderID === order.order_id}
                />
                <Nfce order={order} />
              </>
            )}
            {showOKBtn ? (
              <Button
                size="small"
                // disabled={currentOrderID === order.order_id}
                onClick={setKitchenStatus}
                style={{ minWidth: '0px' }}
              >
                <RightIcon />
              </Button>
            ) : (
              <Button disabled>{''}</Button>
            )}
          </div>
        </CardActions>
      </Card>
    </Animate>
  );
};

export default Order;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  animate: {
    animation: 'fadein 2s',
    WebkitAnimation: 'fadein 2s',
    MozAnimation: 'fadein 2s'
  },
  '@keyframes animate': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

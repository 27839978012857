import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Paper,
  Button
} from '@material-ui/core';
import { Customer } from 'interfaces/customersAndSellers';
import { formatCellPhone } from '../../helpers/common';
import ModalCreateCustomer from './ModalCreateCustomer';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import HistoryIcon from '@material-ui/icons/History';

import { DataGrid, GridColDef, GridCellParams, GridToolbar } from '@material-ui/data-grid'; //

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  icons: {
    fill: '#3f51b5'
  },
});

interface IProps {
  customers: Customer[];
  handleLastOrder: (orderId: number) => void;
}

export default function ClientsTable(props: IProps) {
  const classes = useStyles();
  const { customers, handleLastOrder } = props;
  const [open, setOpen] = React.useState(false);
  const [customersToShow, setCustomersToShow] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const lowerSearchCustomer = customersToShow.toLowerCase()
  let customersFiltered;

  if (lowerSearchCustomer && lowerSearchCustomer.length >= 3) {
    customersFiltered = customers?.filter((customer) => customer.name.toLowerCase().includes(lowerSearchCustomer))
  } else {
    customersFiltered = customers
  }
    const columns: GridColDef[] = [
      // { field: 'id', headerName: 'id', type: 'number', width: 90, headerAlign: 'center', align: 'center' },
      { field: 'name', headerName: 'Nome', type: 'string', width: 200, headerAlign: 'center', align: 'center' },
      {
        field: 'phone_number',
        headerName: 'Telefone',
        type: 'string',
        width: 180,
        headerAlign: 'center',
        align: 'center',
      },
      { field: 'orders', headerName: 'Pedidos', width: 280, headerAlign: 'center', align: 'center' },
      {
        field: 'total',
        headerName: 'Total (R$)',
        width: 140,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'to_pay',
        headerName: 'A Pagar (R$)',
        width: 160,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'profit', headerName: 'Lucro até hoje (R$)', type: 'number', width: 280, headerAlign: 'center', align: 'center'
      },
      {
        field: 'average_price', headerName: 'Média por Pedido (R$)', type: 'number', width: 280, headerAlign: 'center', align: 'center'
      },
      {
        field: 'last_order',
        headerName: 'Último Pedido (R$)',
        type: 'number',
        width: 280,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridCellParams) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '3px', fill: 'blue' }}>{(params.value)}</span>
            <HistoryIcon className={classes.icons} onClick={() => { }} /* handleClickOpenHistory(params.product) */ />
          </div>
        ),
      },
    ];
    // console.log(customersFiltered)
    let rows: Array<any>
    
    if (customers) {
      rows = customersFiltered?.map((customer) =>
      (
        {
          id: customer.email + customer.phone_number,
          name: customer.name,
          phone_number: formatCellPhone(customer.phone_number),
          orders: customer.orders_placed,
          total: customer.total_billed.toFixed(2),
          to_pay: customer.outstanding_to_pay.toFixed(2),
          profit: (customer.total_billed - customer.outstanding_to_pay).toFixed(2),
          average_price: customer.value_of_order_average.toFixed(2),
          last_order: customer.last_order.total_order.toFixed(2)
        }
      )
      )
    } else {
      rows = [({
          id: '',
          name: '',
          phone_number: '',
          orders: '',
          total: '',
          to_pay: '',
          profit: '',
          average_price: '',
          last_order: '',
        })]
    }

    return (
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Button style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }} color="primary" onClick={handleClickOpen} className={classes.activeButton}>
          Adicionar Cliente
        </Button>
        <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
          <div style={{ display: 'inline-flex', marginLeft: 5 }}>
            <FormControl>
              <Input
                id="my-input"
                value={customersToShow}
                type="tel"
                onChange={(event) => setCustomersToShow(event.target.value)}
                aria-describedby="my-helper-text"
                placeholder="Digite o nome do Cliente"
                style={{ width: 300 }}
              />
            </FormControl>
          </div>
        </div>

        <div style={{ height: 550, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(params: GridCellParams) => (

              params.field == "last_order" ? customers?.map((customer) => {
                if (params.row.id == customer.email + customer.phone_number) {
                  handleLastOrder(customer.last_order.id)
                }
              })
                :
                null
            )
            }
          />
        </div>
        <ModalCreateCustomer
          open={open}
          onClose={handleClose}
        />
      </TableContainer>
    );
  }

import { IProduction } from 'interfaces/production';
import { EProductionStatus } from 'enums/order';

export function checkOrderStatus(status: string): any {
  const types = {
    [EProductionStatus.TODO]: 'todo',
    [EProductionStatus.DOING]: 'doing',
    [EProductionStatus.DONE]: 'done'
  };
  return types[status];
}

export function setNewOrder(order: IProduction, orders: IProduction[]) {
  const newOrder: IProduction[] = [order, ...orders]
    .sort((a, b) => a.id - b.id)
    .reverse();
  return newOrder;
}

export function unsetOldOrder(
  order: IProduction,
  orders: IProduction[]
): IProduction[] {
  const newOrder: IProduction[] = orders;
  return newOrder
    .filter((item) => item.id !== order.id)
    .sort((a, b) => a.id - b.id)
    .reverse();
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  content: {
    padding: 10,
    marginTop: 10,
    width: 200
  },
  card: {
    padding: 10
  },
  info: {
    marginBottom: 30
  },
  status: {
    color: '#000',
    fontWeight: 'bold'
  },
  date: {
    marginTop: 10,
    color: '#000',
    fontWeight: 'bold'
  },
  panel: {
    marginBottom: 20
  },
  todoPanel: {
    borderRadius: 50,
    border: '1px solid rgb(221, 23, 23)'
  },
  doingPanel: {
    borderRadius: 50,
    border: '1px solid rgb(255, 145, 36)'
  },
  donePanel: {
    borderRadius: 50,
    border: '1px solid rgb(15, 197, 19)'
  },
  depotPanel: {
    borderRadius: 50,
    border: '1px solid rgb(100,72,77)'
  },
  completedPanel: {
    borderRadius: 50,
    border: '1px solid lightblue'
  },
  receiptHeader: {
    width: 350,
    margin: '0 auto'
  },
  btn: {
    padding: '0px',
    '& span': {
      padding: '6px 16px'
    }
  },
  active: {
    backgroundColor: '#233472 !important'
  }
}));

import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { IKitchen } from 'interfaces/kitchen';

export type IBalcony = IKitchen;

export interface IBalconyState {
  fetch: boolean;
  count: number;
  next?: string;
  previous?: string;
  results: IBalcony[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesBalcony {
  FETCH = 'BALCONY/FETCH',
  SUCCESS = 'BALCONY/SUCCESS',
  FIRSTLOAD = 'BALCONY/FIRSTLOAD',
  FAILURE = 'BALCONY/FAILURE',
  SEARCH = 'BALCONY/SEARCH'
}

export interface IFetchAction extends IBaseAction<ETypesBalcony, null> {
  type: ETypesBalcony.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesBalcony, IBalconyState> {
  type: ETypesBalcony.SUCCESS;
  payload: IBalconyState;
}

export interface IFirstLoadAction
  extends IBaseAction<ETypesBalcony, IBalconyState> {
  type: ETypesBalcony.FIRSTLOAD;
  payload: IBalconyState;
}

export interface IFailureAction extends IBaseAction<ETypesBalcony, string> {
  type: ETypesBalcony.FAILURE;
  payload: string;
}

export interface ISearchAction extends IBaseAction<ETypesBalcony, IBalcony> {
  type: ETypesBalcony.SEARCH;
  payload: IBalcony;
}

export type IBalconyAction =
  | IFetchAction
  | ISuccessAction
  | IFirstLoadAction
  | IFailureAction
  | ISearchAction;

export type GetState = () => any;

export type PromiseAction = Promise<IBalconyAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IBalconyAction
    | ThunkAction
    | PromiseAction
    | Array<IBalconyAction>
    | IAlertAction
) => any;

import React, { PureComponent } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { CardWithGraphic, GraphicCardLabel, ColorLabel } from './styles';
import { Typography, Grid } from '@material-ui/core';

const COLORS = ['#211F5C', '#A84550'];

interface GraphicProps {
  data: Array<{
    name: string;
    value: number;
  }>;
}

class CustomPieChart extends PureComponent<GraphicProps> {
  render() {
    const { data } = this.props;
    return (
      <div style={{ width: '100%', height: 250 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

interface Props {
  open: number;
  closed: number;
}

const CustomCardPieChart: React.FC<Props> = (props: Props): JSX.Element => {
  const translation = {
    open: 'Abertos',
    closed: 'Fechados'
  };
  const data = Object.keys(props).map((key) => ({
    name: translation[key],
    value: props[key]
  }));

  return (
    <CardWithGraphic>
      <GraphicCardLabel paragraph align="left">
        PEDIDOS
      </GraphicCardLabel>
      <CustomPieChart data={data} />
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ padding: '10px 20px' }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          style={{ width: '50%' }}
        >
          <ColorLabel color="#211F5C" />
          <Typography>ABERTOS</Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
          style={{ width: '50%' }}
        >
          <ColorLabel color="#A84550" />
          <Typography>FECHADOS</Typography>
        </Grid>
      </Grid>
    </CardWithGraphic>
  );
};

export default CustomCardPieChart;

import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  ICustomersAndSellersState,
  ETypesCustomersAndSellers,
  IManagerAction,
  GetState
} from 'interfaces/customersAndSellers';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: ICustomersAndSellersState = {
  fetch: false,
  customers: [],
  last_order: {
    id: 0,
    order_code: '',
    payment: '',
    total_order: 0,
    delivery_fee: 0,
    cash_change: 0,
    cash_paid: 0,
    discount: 0,
    date_delivery: '',
    date_ordered: '',
    finished_at: '',
    final_comment: '',
    items: []
  }
};

/* Authentication Reducer. */
export default (
  state: ICustomersAndSellersState = initialState,
  action: IManagerAction
): ICustomersAndSellersState => {
  switch (action.type) {
    case ETypesCustomersAndSellers.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesCustomersAndSellers.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesCustomersAndSellers.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesCustomersAndSellers.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStock = (): IFetchAction => ({
  type: ETypesCustomersAndSellers.FETCH
});

export const successFetch = (
  payload: ICustomersAndSellersState
): ISuccessAction => ({
  type: ETypesCustomersAndSellers.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesCustomersAndSellers.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesCustomersAndSellers.RESET
});

/* Authentication Side Effects Functions. */
export const fetchBestBuyers = (filter: string): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { customersAndSellers } = getState();
    dispatch(fetchStock());
    const response = await fetch({
      method: EMethod.GET,
      url: `users/api/clients/best_buyers/?period=${filter}`
    });
    dispatch(successFetch({ ...customersAndSellers, customers: response }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const fetchBestBuyersWithDateRange = (
  initial: string,
  end: string
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { customersAndSellers } = getState();
    dispatch(fetchStock());
    const response = await fetch({
      method: EMethod.GET,
      url: `users/api/clients/best_buyers/?starts=${initial}&ends=${end}`
    });
    dispatch(successFetch({ ...customersAndSellers, customers: response }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const fetchLastOrder = (orderId: number): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { customersAndSellers } = getState();
    dispatch(fetchStock());
    const response = await fetch({
      method: EMethod.GET,
      url: `/orders/api/orders/${orderId}/get_order/`
    });
    dispatch(successFetch({ ...customersAndSellers, last_order: response }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

import React, { useEffect } from 'react';

import { IKitchen } from 'interfaces/kitchen';

import { useDispatch } from 'react-redux';

import formatDate from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';

import { getLogo, getPaymentType } from 'helpers/common';
import { getDeliveryType } from 'helpers/kitchen';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PrintIcon from './../../assets/img/print-icon.svg';

import Print from 'components/Common/Print';

import { useStyles } from './styles';
import { fetch } from './../../utils/request';

interface IProps {
  order: IKitchen;
  disabled?: boolean;
}

export default function Receipt(props: IProps): JSX.Element {
  const { order, disabled } = props;
  const { order_code, date_ordered, items, date_delivery, store_id } = order;
  const [imageUrl, setImageUrl] = React.useState(undefined)
  const [store, setStore] = React.useState(undefined);
  const classes = useStyles();
  const dispatch = useDispatch();

  const getStore = async (store_id: number = undefined) => {
    try {
      let url = `/products/api/stores/${store_id}/`;
      let response;
      let results;
      response = await fetch({
        method: 'GET',
        url: url
      });
      results = response
      console.log('Results =>', results)
      setStore(results);
    } catch (error) {
      console.log('error fetching ingredients');
    }
  };

  useEffect(() => {
    if (store_id && store === undefined) {
      getStore(store_id)
      console.log(store)
      if (store) {
        setImageUrl(store.image)
      }
      console.log('Image Url', imageUrl)
    }
    else {
      if (store) {
        setImageUrl(store.image)
      }
    }
  }, [dispatch, store]);

  return (
    <Print
      trigger={
        <Button size="medium" disabled={disabled}>
          <img src={PrintIcon} height="35px" width="50px" />
        </Button>
      }
    >
      <Card>
        <Grid container justify="center" className={classes.receiptHeader}>
          <Grid>
            {getDeliveryType(order?.type_of_order) !== 'Mesa' && imageUrl ?
              <img src={imageUrl} alt="Logo" width="125" height="125" style={{ paddingBottom: 10 }} />
              : getDeliveryType(order?.type_of_order) !== 'Mesa' &&
              <img src={getLogo()} alt="Logo" width="150" height="150" />}
            <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
              {order_code?.toUpperCase()}
            </Typography>
            <Typography style={{ fontSize: 20 }}>
              {formatDate(new Date(date_ordered), 'dd/MM/yyyy - hh:mm')}
            </Typography>
            {date_delivery && (
              <Typography style={{ fontSize: 20 }}>
                {formatDate(new Date(date_delivery), 'dd/MM/yyyy - hh:mm')}
              </Typography>
            )}
            {getDeliveryType(order?.type_of_order) === 'Mesa' && order?.table ? (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                  DADOS DA MESA
                </Typography>
                <Typography style={{ fontSize: 20 }}>Nome do Cliente: {order?.owner?.name ? order?.client?.name : 'Não identificado'}</Typography>
                <Typography style={{ fontSize: 20 }}>
                  Nome da Mesa: {order?.table?.name}
                </Typography>
                <Typography style={{ fontSize: 20 }}>
                  Número da Mesa: {order?.table?.number}
                </Typography>
                <Typography style={{ fontSize: 20 }}>
                  Status da Mesa: {order?.table?.status === 'Available' ? 'Disponível' : order?.table?.status === 'Active' ? 'Ativo' : 'Precisa de Pagamento'}
                </Typography>
              </>
            ) : null}
            {order?.owner || order?.client ? (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                  DADOS DO CLIENTE
                </Typography>
                <Typography style={{ fontSize: 20 }}>{order?.owner?.name ?? order?.client?.name}</Typography>
                <Typography style={{ fontSize: 20 }}>
                  {order?.owner?.phone_number ?? order?.client?.phone}
                </Typography>
              </>) : null}

            {order?.delivery_address && (
              <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                ENDEREÇO DE ENTREGA
              </Typography>
            )}
            {order?.delivery_address && (
              <Typography style={{ maxWidth: 200, fontSize: 20 }}>
                {`${order?.delivery_address}`}
              </Typography>
            )}

            {getDeliveryType(order?.type_of_order) !== 'Mesa' || (order?.table && order?.table?.status === 'Need Payment') ? (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                  DETALHES DO PEDIDO
                </Typography>
                {getDeliveryType(order?.type_of_order) !== 'Mesa' ? (
                  <>
                    <Typography style={{ fontSize: 20 }}>Pago: {order?.is_paid ? 'Sim' : 'Não'}</Typography>
                    <Typography style={{ fontSize: 20 }}>Pagamento: {getPaymentType(order?.payment)}</Typography>
                  </>
                ) : null}
                <Typography style={{ fontSize: 20 }}>
                  Tipo: {getDeliveryType(order?.type_of_order)}
                </Typography>
                {order?.delivery_address && (
                  <>
                    <Typography style={{ fontSize: 20 }}>
                      Sub-total: R$:{' '}
                      {(
                        Number(order?.total_order?.toFixed(2)) -
                        Number(order?.delivery_fee)
                      ).toFixed(2)}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Taxa de entrega: R$ {order?.delivery_fee?.toFixed(2)}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Entregador:{' '}
                      {`${order?.gomotoboy_driver_name
                        ? order?.gomotoboy_driver_name
                        : 'Não definido'
                        }`}
                    </Typography>
                  </>
                )}
                <Typography style={{ fontSize: 20 }}>Desconto: R$: {order?.discount ? order?.discount?.toFixed(2) : '0.00'}</Typography>
                <Typography style={{ fontSize: 20 }}>Total: R$: {order?.total_order?.toFixed(2)}</Typography>
                {order?.cash_change && (
                  <Typography style={{ fontSize: 20 }}>
                    Troco: R$ {Number(order?.cash_change).toFixed(2)}
                  </Typography>
                )}
              </>
            ) : null}

            <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
              RESUMO DO PEDIDO
            </Typography>
            {items.map((item) => (
              <span key={uuidv4()}>
                <Typography variant="h6" key={item?.product?.product_id} style={{ fontSize: 20 }}>
                  {`${item?.quantity}x ${item?.product?.name} - R$ ${(item?.quantity * item?.item_price)}`}
                </Typography>
                {item?.extra_orders.map((extra) => (
                  <Typography key={extra.id} style={{ marginLeft: 15, fontSize: 20 }}>
                    {`${extra?.quantity} x Extra ${extra?.ingredient}`}
                  </Typography>
                ))}
              </span>
            ))}
            {order.set_driver_qr && getDeliveryType(order?.type_of_order) === 'Entrega' && (
              <img
                alt="QR Code"
                src={order.set_driver_qr}
                width="150"
                height="150"
              />
            )}

            {order.final_comment && (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                  COMENTÁRIO
                </Typography>
                <Typography style={{ fontSize: 20 }}>{`${order.final_comment}`}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </Print>
  );
}

Receipt.defaultProps = {
  disabled: false
};

import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Product } from 'interfaces/stock';
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import moment from 'moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product;
}

export default function ModalHistory(props: SimpleDialogProps) {
  const { onClose, open, product } = props;

  const handleClose = () => {
    onClose();
  };

  if (!product) return null;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <span>Histórico do Estoque {product.name} - {moment(moment.now()).format('DD/MM/YYYY')} </span>
          <span>Estoque atual: {product.stock}</span>
        </div>
        
      </DialogTitle>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Antes</TableCell>
              <TableCell align="left">Depois</TableCell>
              <TableCell align="left">Operação</TableCell>
              <TableCell align="left">Descrição</TableCell>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Horário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {product.day_history.map((history) => (
              <TableRow key={history.created_at}>
                <TableCell align="left">{history.before}</TableCell>
                <TableCell align="left">{history.after}</TableCell>
                <TableCell align="left">
                  { history.type === 'INCREASE' ? (
                    <div style={{color: 'green', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span style={{display: 'flex', flexDirection: 'column'}}>
                      <ArrowDropUpIcon style={{position: 'relative', top: 7.5}}/>
                      <ArrowDropUpIcon style={{position: 'relative', top: -7.5}}/>
                    </span>
                      {history.quantity}
                    </div>

                  ) : (
                    <div style={{color: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span style={{display: 'flex', flexDirection: 'column'}}>
                      <ArrowDropDownIcon style={{position: 'relative', top: 7.5}}/>
                      <ArrowDropDownIcon style={{position: 'relative', top: -7.5}}/>
                    </span>
                      {history.quantity}
                    </div>
                  )
                  }
                  
                </TableCell>
                <TableCell align="left">{history.description}</TableCell>
                <TableCell align="left">
                  {history.item_id ? history.item_id : ''}
                </TableCell>
                <TableCell align="left">
                  {moment(history.created_at).format('HH:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}

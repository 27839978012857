import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, InputLabel, Input, Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createNotificationStatus, fetchNotificationsStatus } from 'redux/ducks/notification'

import useStyles from './styles';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function ModalCreateNotificationStatus(props: SimpleDialogProps) {
  const classes = useStyles();

  const { onClose, open } = props;
  const [message, setMessage] = React.useState('');
  const [status, setStatus] = React.useState('A fazer');

  const dispatch = useDispatch();

  const handleClose = () => {
    setMessage('');
    onClose();
  };

  const handleMessage = (event: any) => {
    setMessage(event.target.value)
  }

  const handleStatus = (event: any) => {
    setStatus(event.target.value)
  }

  const submitCreation = async () => {
    dispatch(createNotificationStatus({
      message: message,
      status: status
    }))
      .then((result: boolean) => {
        dispatch(fetchNotificationsStatus());
        if (result) handleClose()
      })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="simple-dialog-title">
        Adicionar Notificação de Status
      </DialogTitle>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel htmlFor="message-input">Mensagem</InputLabel>
        <Input id="message-input" type="string" value={message} onChange={handleMessage} />
      </FormControl>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel id="label">Status</InputLabel>
        <Select labelId="label" id="select" value={status} onChange={handleStatus}>
          <MenuItem value="A fazer">A fazer</MenuItem>
          <MenuItem value="Fazendo">Fazendo</MenuItem>
          <MenuItem value="Feito">Feito</MenuItem>
          <MenuItem value="Depot">Depósito</MenuItem>
          <MenuItem value="Transit">Em Transito</MenuItem>
          <MenuItem value="Delivered">Entregue</MenuItem>
          <MenuItem value="Completed">Completo</MenuItem>
          <MenuItem value="Canceled">Cancelado</MenuItem>
          <MenuItem value="Canceled and Refunded">Cancelado e Estornado</MenuItem>
        </Select>
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
        <Button onClick={submitCreation} color="primary" variant="outlined"
          className={classes.activeButton}> Criar </Button>
        <Button onClick={handleClose} variant="outlined"
          className={classes.button}> Cancelar </Button>
      </div>
    </Dialog>
  );
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_NOTIFICATION } from 'settings';
import { connect } from 'utils/socket';

import { getKitchen, updateKitchen } from 'redux/ducks/kitchen';

import IStore from 'interfaces/store';
import { IKitchenState } from 'interfaces/kitchen';

import { ESocketStatus } from 'enums/socket';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

import Panel from './Panel';
import Loading from 'components/Common/Loading';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

enum EDateFilter {
  HOJE = 'HOJE',
  SEMANA = 'SEMANA',
  ALL = 'ALL'
}

const dateFilter = [EDateFilter.HOJE, EDateFilter.SEMANA, EDateFilter.ALL];



export default function Kitchen() {
  const dispatch = useDispatch();
  const { fetch, depot, transit, delivered } = useSelector<
    IStore,
    IKitchenState
  >((state) => state.kitchen);
  const classes = useStyles();

  const [online, setOnline] = useState(false);
  const [filteredDepot, setFilteredDepot] = useState(depot);
  const [filteredTransit, setFilteredTransit] = useState(transit);
  const [filteredCompleted, setFilteredCompleted] = useState(delivered);
  const [activeDateFilter, setActiveDateFilter] = useState('HOJE');

  const handleActiveDateFilter = (value: string) => {
    setActiveDateFilter(value);
  };

  const getWeekDates = () => {
    let now = new Date();
    let dayOfWeek = now.getDay();
    let numDay = now.getDate();

    let start = new Date(now);
    start.setDate(numDay - dayOfWeek + 1);
    start.setHours(0, 0, 0, 0);

    let end = new Date(now);
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  const getTodayDates = () => {
    let now = new Date();

    let start = new Date(now);
    start.setHours(0, 0, 0, 0);

    let end = new Date(now);
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  useEffect(() => {
    dispatch(
      getKitchen({
        date_delivery: activeDateFilter,
        store: "BYSTATION",
        depot: true,
        transit: true,
        delivered: true
      })
    );
  }, [dispatch, activeDateFilter]);

  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => setOnline(true);

    kitchenSocket.onmessage = (event) => {
      let new_data = JSON.parse(event.data)
      console.log(new_data)
      console.log(new_data.message.store_id)
      if (new_data && new_data.message.store_id === 1) {
        dispatch(updateKitchen(JSON.parse(event.data)));
      }
    }

    kitchenSocket.onerror = () => kitchenSocket.close();

    kitchenSocket.onclose = () => kitchenSocket.close();

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (kitchenSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online]);

  useEffect(() => {
    const [start, end] =
      activeDateFilter === EDateFilter.HOJE ? getTodayDates() : activeDateFilter === EDateFilter.SEMANA ? getWeekDates() : [null, null];

    const depotfilter = depot.filter(
      (order) =>
        (new Date(order.date_delivery) >= start && new Date(order.date_delivery) <= end) ||
        (!order.date_delivery && new Date(order.date_ordered) >= start && new Date(order.date_ordered) <= end) ||
        (!start && !end)
    );
    setFilteredDepot(depotfilter);

    const transitFilter = transit.filter(
      (order) =>
        (new Date(order.date_delivery) >= start && new Date(order.date_delivery) <= end) ||
        (!order.date_delivery && new Date(order.date_ordered) >= start && new Date(order.date_ordered) <= end) ||
        (!start && !end)
    );
    setFilteredTransit(transitFilter);

    const completedFilter = delivered.filter(
      (order) =>
        (new Date(order.date_delivery) >= start && new Date(order.date_delivery) <= end) ||
        (!order.date_delivery && new Date(order.date_ordered) >= start && new Date(order.date_ordered) <= end) ||
        (!start && !end)
    );
    setFilteredCompleted(completedFilter);
  }, [activeDateFilter, depot, transit, delivered]);

  const panels = [
    {
      key: 1,
      headerStyle: classes.cardHeaderDepot,
      headerTitle: 'DEPÓSITO',
      bodyStyle: classes.cardBodyDepot,
      orders: filteredDepot,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showExtraInfo: true,
      showDriverBtn: true,
      showPrintBtn: true
    },
    {
      key: 2,
      headerStyle: classes.cardHeaderDoing,
      headerTitle: 'A CAMINHO',
      bodyStyle: classes.cardBodyDoing,
      orders: filteredTransit,
      showPrintBtn: true
    },
    {
      key: 3,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'ENTREGUES',
      bodyStyle: classes.cardBodyDone,
      orders: filteredCompleted,
      showPrintBtn: true
    }
  ];

  return (
    <>
      <Loading show={fetch} />
      <Grid style={{ marginBottom: 10 }}>
        <Typography
          color="primary"
          variant="overline"
          style={{ fontWeight: 'bold', fontSize: 14 }}
        >{`pedidos que serão entregues: `}</Typography>
        {dateFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            variant="outlined"
            disabled={fetch}
            onClick={() => { handleActiveDateFilter(elmnt) }}
            color="primary"
            style={{
              marginRight: 3,
              fontSize: '0.6rem'
            }}
            className={
              activeDateFilter === elmnt
                ? `${classes.activeButton} ${classes.btn}`
                : `${classes.button}`
            }
          >
            {elmnt}
          </Button>
        ))}
      </Grid>
      <Grid container spacing={3}>
        {panels.map((panel) => (
          <Panel
            key={panel.key}
            headerStyle={panel.headerStyle}
            headerTitle={panel.headerTitle}
            bodyStyle={panel.bodyStyle}
            orders={panel.orders}
            showOKBtn={panel.showOKBtn}
            showBackBtn={panel.showBackBtn}
            showIsPaid={panel.showIsPaid}
            showPrintBtn={panel.showPrintBtn}
            showDriverBtn={panel.showDriverBtn}
            showExtraInfo={panel.showExtraInfo}
          />
        ))}
      </Grid>
      {!fetch &&
        depot.length === 0 &&
        transit.length === 0 &&
        delivered.length === 0 && (
          <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
            No orders yet
          </Typography>
        )}
    </>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Product } from 'interfaces/stock';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import HistoryIcon from '@material-ui/icons/History';
import ModalAddProduct from './ModalAddProduct';
import ModalAddAvaria from './ModalAddAvaria';
import ModalHistory from './ModalHistory';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    maxHeight: '600px',
  }
});

interface IProps {
  products: Product[];
  noMargin?: boolean;
}

export default function ProductsTable(props: IProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openAvaria, setOpenAvaria] = React.useState(false)
  const [openHistory, setOpenHistory] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(undefined);
  const [ searchProduct, setSearchProduct ] = React.useState('');
  const { products, noMargin } = props;

  const handleClickOpen = (product: Product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClickOpenAvaria = (product: Product) => {
    setSelectedProduct(product);
    setOpenAvaria(true);
  };

  const handleClickOpenHistory = (product: Product) => {
    setSelectedProduct(product);
    setOpenHistory(true);
  };

  const handleClose = () => {
    setSelectedProduct(undefined);
    setOpen(false);
    setOpenAvaria(false);
    setOpenHistory(false);
  };

  const lowerSearchProduct = searchProduct.toLowerCase()
  let productsFiltered;

  if(lowerSearchProduct.length >= 3 ){
    productsFiltered = products?.filter((product) => product.name.toLowerCase().includes(lowerSearchProduct));
  }else {
    productsFiltered = products;
  }

  return (
    <TableContainer
      className={classes.tableContainer}
      style={noMargin && { margin: 0 }}
      component={Paper}
    >
      <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
        <div style={{ display: 'inline-flex', marginLeft: 5 }}>
          <FormControl>
            <Input
              id="my-input"
              value={searchProduct} //tempFilter.length <= 3 ? tempFilter : searchProduct
              type="tel"
              onChange={(event) => setSearchProduct(event.target.value)}
              aria-describedby="my-helper-text"
              placeholder="Escolha nome do Produto"
              style={{ width: 300 }}
            />
          </FormControl>
        </div>
      </div>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="left">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Produção |</span>
                <span>Avaria</span>
              </div>
            </TableCell>
            <TableCell align="right">Vendido</TableCell>
            <TableCell align="right">Reservado</TableCell>
            {products && products[0] &&
              products[0].next_days.map((value) => (
                <TableCell align="right">{value.date}</TableCell>
              ))}
            <TableCell align="right">Estoque</TableCell>
            <TableCell align="right">Avarias</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productsFiltered?.map((product) => (
            <TableRow key={product.product_id}>
              <TableCell component="th" scope="row">
                {product.name}
              </TableCell>
              <TableCell align="left">
                <div style={{ color: '#211f5c', display: 'flex' }}>
                  <ControlPointIcon onClick={() => handleClickOpen(product)} />
                  <PlayForWorkIcon
                    onClick={() => handleClickOpenAvaria(product)}
                  />
                </div>
              </TableCell>
              <TableCell align="right">{product.sold}</TableCell>
              <TableCell align="right">{product.reserved}</TableCell>
              {product.next_days.map((value) => (
                <TableCell align="right">{value.value}</TableCell>
              ))}
              <TableCell align="right">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                  <span>{product.stock} </span>
                  <HistoryIcon className='ml-3' onClick={() => handleClickOpenHistory(product)}/>
                </div>
              </TableCell>
              <TableCell align="right">{product.avarias}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalAddProduct
        open={open}
        onClose={handleClose}
        product={selectedProduct}
      />
      <ModalAddAvaria
        open={openAvaria}
        onClose={handleClose}
        product={selectedProduct}
      />
      <ModalHistory
        open={openHistory}
        onClose={handleClose}
        product={selectedProduct}
      />
    </TableContainer>
  );
}

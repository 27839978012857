import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { IPackageState, ETypesPackage, IPackageAction, IFetchAction, ISuccessAction, IFailureAction, ThunkAction, Dispatch } from 'interfaces/package';

/* Production State. */
const initialState: IPackageState = {
  fetch: false,
  results: [],
  error: ''
};

/* Production Reducer. */
export default (
  state: IPackageState = initialState,
  action: IPackageAction
): IPackageState => {
  switch (action.type) {
    case ETypesPackage.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesPackage.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        error: ''
      };
    case ETypesPackage.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Production Action Creators Functions. */
export const fetchPackage = (): IFetchAction => ({
  type: ETypesPackage.FETCH
});

export const successPackage = (
  payload: IPackageState
): ISuccessAction => ({
  type: ETypesPackage.SUCCESS,
  payload
});

export const failurePackage = (payload: string): IFailureAction => ({
  type: ETypesPackage.FAILURE,
  payload
});

/* Production Side Effects Functions. */
export const getPackage = (): ThunkAction => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch(fetchPackage());
    let response;
    let url = `/orders/api/production_orders/package_recipe/`
    response = await fetch({
      method: EMethod.GET,
      url: url
    });
    response.results = response;
    dispatch(successPackage(response));
  } catch (error) {
    dispatch(failurePackage(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

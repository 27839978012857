import { APP_NAME } from 'settings';

import PzzzaLogo from 'assets/img/pzzza_logo.jpg';
import FarinaLogo from 'assets/img/farina_logo.jpg';
import CashLogo from 'assets/img/cash.png';
import PicpayLogo from 'assets/img/picpay.png';
import NubankLogo from 'assets/img/nubank.png';
import CardLogo from 'assets/img/card.png';
import TransferLogo from 'assets/img/transfer.jpg';
import MachineLogo from 'assets/img/machine.png';
import { IKitchen } from 'interfaces/kitchen';

export function getLogo() {
  const logo = {
    'Pzzza - MPOSGO Realtime': PzzzaLogo,
    'Farina - MPOS Realtime': FarinaLogo
  };

  return logo[APP_NAME];
}

export function getPaymentLogo(value: string) {
  const logo = {
    cash: CashLogo,
    nubank: NubankLogo,
    picpay: PicpayLogo,
    card: CardLogo,
    transfer: TransferLogo,
    club: FarinaLogo,
    machine: MachineLogo
  };

  return logo[value];
}

export function getPaymentType(value: string) {
  const logo = {
    cash: 'Dinheiro',
    nubank: 'Nubank',
    picpay: 'Picpay',
    card: 'Cartão',
    transfer: 'Transferência',
    machine: 'Maquineta'
  };

  if (!(value in logo)) {
    return 'Não Informado';
  }

  return logo[value];
}

export function getUrlName(pathname: string): string {
  const urlName = {
    home: 'Home',
    order: 'Encomendas',
    delivery: 'Entregas',
    history: 'Transações',
    completed: 'Completo',
    production: 'Produção',
    product: 'Produção',
    ingredient: 'Compras',
    package: 'Pacotes',
    stores: 'Lojas',
    table: 'Mesas',
    manager: 'Manager',
    stock: 'Estoque',
    customers: 'Clientes',
    notifications: 'Notificações',
  };

  if (pathname.split('/')[2].split('-')[0] === 'production') {
    return urlName[pathname.split('/')[3].split('-')[0]] || '';
  }

  return urlName[pathname.split('/')[2].split('-')[0]] || '';
}

export function getWhatsappWithPicPayMessage(order: IKitchen) {
  const phone = order.client
    ? order.client.phone.replace(/\D/g, '')
    : order.owner.phone_number.replace(/\D/g, '');
  const br = '%0a';
  let message = `PEDIDO Nº: *${order.order_code}*${br}Seu pedido já está sendo preparado.${br}`;

  message += `${br}*Tipo de pagamento*: ${getPaymentType(order.payment)}.`;
  message += `${br}*Total*: R$: ${order?.total_order?.toFixed(2)}`;
  message += `${br}Para efetuar o pagamento, acesse o link: https://app.picpay.com/payment?type=store%26sellerId=141411%26value=${order?.total_order.toFixed(
    2
  )}`;
  message += `${br}${br}Obrigado pela preferência.`;

  return `https://web.whatsapp.com/send?phone=55${phone}&text=${message}&source&data`;
}

export const formatCellPhone = (phoneNumber: string) => {
  const phoneWithoutCharacter = phoneNumber.replace(/\D/g, '');
  if (phoneWithoutCharacter[0] === '5' && phoneWithoutCharacter[1] === '5') {
    const stateCode = phoneWithoutCharacter.slice(2, 4);
    const phoneNumberPartOne = phoneWithoutCharacter.slice(4, 9);
    const phoneNumberPartTwo = phoneWithoutCharacter.slice(9, 13);

    return `(${stateCode}) ${phoneNumberPartOne}-${phoneNumberPartTwo}`;
  }
  const stateCode = phoneWithoutCharacter.slice(0, 2);
  const phoneNumberPartOne = phoneWithoutCharacter.slice(2, 7);
  const phoneNumberPartTwo = phoneWithoutCharacter.slice(7, 11);

  return `(${stateCode}) ${phoneNumberPartOne}-${phoneNumberPartTwo}`;
};

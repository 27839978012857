import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Menu } from 'interfaces/menu';
import { Product } from 'interfaces/stock';
import ModalCreateMenu from './ModalCreateMenu'
import ModalEditMenu from './ModalEditMenu'
import ModalDeleteMenu from './ModalDeleteMenu'
import ModalEditProductsMenu from './ModalEditProductsMenu'
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@material-ui/icons/ToggleOnOutlined';
import { handleActiveMenu } from 'redux/ducks/menu'
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  menus: Menu[];
  products?: Product[];
}


export default function MenusTable(props: IProps) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const { menus, products } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditProducts, setOpenEditProducts] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenEditProducts(false);
    setOpenDelete(false);
    setSelectedMenu(undefined);
  };

  const handleClickOpenEdit = (menu: Menu) => {
    setOpenEdit(true);
    setSelectedMenu(menu);
  };

  const handleClickOpenEditProducts = (menu: Menu) => {
    setOpenEditProducts(true);
    setSelectedMenu(menu);
  };

  const handleClickOpenDelete = (menu: Menu) => {
    setOpenDelete(true);
    setSelectedMenu(menu);
  };


  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }} color="primary" onClick={handleClickOpen} className={classes.activeButton}>Add Menu</Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {menus?.map((menu) => (
            <TableRow key={menu.menu_id}>
              <TableCell component="th" scope="row">
                {menu.menu_id}
              </TableCell>
              <TableCell>{menu.name}</TableCell>
              <TableCell><EditIcon onClick={() => handleClickOpenEdit(menu)} /> <ListIcon onClick={() => handleClickOpenEditProducts(menu)} /> {menu.active ? (<ToggleOnOutlinedIcon onClick={() => dispatch(handleActiveMenu(menu.menu_id))} />) : (<ToggleOffOutlinedIcon onClick={() => dispatch(handleActiveMenu(menu.menu_id))} />)} <DeleteIcon onClick={() => handleClickOpenDelete(menu)} /> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateMenu
        open={open}
        onClose={handleClose}
      />
      <ModalEditMenu
        open={openEdit}
        onClose={handleClose}
        menu={selectedMenu}
      />
      <ModalEditProductsMenu
        open={openEditProducts}
        onClose={handleClose}
        menu={selectedMenu}
        products={products}
      />
      <ModalDeleteMenu
        open={openDelete}
        onClose={handleClose}
        menu={selectedMenu}
      />
    </TableContainer>
  );
}

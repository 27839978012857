import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchProducts, fetchIngredients } from 'redux/ducks/stock';
// import {
  // MuiPickersUtilsProvider,
  // KeyboardDatePicker
// } from '@material-ui/pickers';
import { EFilters } from '../../enums/stock';
import IStore from 'interfaces/store';
import Loading from 'components/Common/Loading';
import ProductFilter from './ProductFilter';
import IngredientsTable from './IngredientsTable';
import ProductsTable from './ProductsTable';
// import DateFnsUtils from '@date-io/date-fns';
// import SearchIcon from '@material-ui/icons/Search';
// import useStyles from './styles';
// import formatDate from 'date-fns/format';
import ToPrint from './ToPrint';

const filters = [
  {
    label: 'Produtos',
    value: EFilters.PRODUCTS
  },
  {
    label: 'Ingredientes',
    value: EFilters.INGREDIENTS
  }
];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.PRODUCTS);
  const { stock } = useSelector<IStore, IStore>((state) => state);
  // const [date, setDate] = useState(new Date());

  // const handleDateRange = () => {
  //   dispatch(fetchProducts());
  // };

  useEffect(() => {
    if (activeFilter === EFilters.PRODUCTS) {
      dispatch(fetchProducts({ store: 'MATRIZ' }));
    } else {
      dispatch(fetchIngredients({ store: 'MATRIZ' }));
    }
  }, [dispatch, activeFilter]);

  return (
    <>
      <Loading show={stock.fetch} />
      <Grid container spacing={3}>
        <ProductFilter
          activeFilter={activeFilter}
          setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
          filters={filters}
        />
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          {/* <Grid item container xs={4} alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-initial"
                label="Selecione a Data"
                value={date}
                onChange={setDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>
            <Button style={{ alignSelf: 'flex-end' }} onClick={handleDateRange}>
              <SearchIcon className={classes.datePickerButton} />
            </Button>
          </Grid> */}
          <ToPrint products={stock.products} disabled={stock.fetch} />
        </Grid>
        {activeFilter === EFilters.PRODUCTS ? (
          <ProductsTable products={stock.products} />
        ) : (
          <IngredientsTable ingredients={stock.ingredients} />
        )}
      </Grid>
    </>
  );
}

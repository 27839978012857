import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_PRODUCTION } from 'settings';
import { connect } from 'utils/socket';

import { getProduction, updateProduction, resetProduction } from 'redux/ducks/production';
import { IProductionState } from 'interfaces/production';

import IStore from 'interfaces/store';

import { ESocketStatus } from 'enums/socket';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

import Panel from './Panel';
import Loading from 'components/Common/Loading';

export default function Production() {
  const dispatch = useDispatch();
  const { fetch, todo, doing, done, next } = useSelector<
    IStore,
    IProductionState
  >((state) => state.production);
  const classes = useStyles();

  const [online, setOnline] = useState(false);

  useEffect(() => {
    return () =>  {
      dispatch(resetProduction());
    }
  }, [dispatch])

  useEffect(() => {
    const params = {
      order_type: 'Product',
      date_ordered: 'SEMANA',
      a_fazer: true,
      fazendo: true,
      feito: true
    };

    if (next === '') dispatch(getProduction(params));
    else if (next !== null) {
      dispatch(getProduction(params, next));
    }
  }, [dispatch, next]);

  useEffect(() => {
    let productionSocket = connect(SOCKET_PRODUCTION);

    productionSocket.onopen = () => setOnline(true);

    productionSocket.onmessage = (event) =>
      dispatch(updateProduction(JSON.parse(event.data)));

    productionSocket.onerror = () => productionSocket.close();

    productionSocket.onclose = () => productionSocket.close();

    const heartbeat = setInterval(() => {
      if (productionSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (productionSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) productionSocket = connect(SOCKET_PRODUCTION);
    }, 5000);

    return () => {
      productionSocket.close();
      clearInterval(heartbeat);
    };
  }, [online]);

  const panels = [
    {
      key: 1,
      headerStyle: classes.cardHeaderTodo,
      headerTitle: 'SOLICITADO',
      bodyStyle: classes.cardBodyTodo,
      orders: todo,
      showCounter: true,
      showBackBtn: false
    },
    {
      key: 2,
      headerStyle: classes.cardHeaderDoing,
      headerTitle: 'FAZENDO',
      bodyStyle: classes.cardBodyDoing,
      orders: doing
    },
    {
      key: 3,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'FEITO',
      bodyStyle: classes.cardBodyDone,
      orders: done,
      showOKBtn: true,
      showBackBtn: true,
      showPrintBtn: true
    }
  ];

  return (
    <>
      <Loading show={fetch} />
      {!fetch && todo.length === 0 && doing.length === 0 && done.length === 0 && (
        <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
          No orders yet
        </Typography>
      )}
      <Grid container spacing={3}>
        {panels.map((panel) => (
          <Panel
            key={panel.key}
            headerStyle={panel.headerStyle}
            headerTitle={panel.headerTitle}
            bodyStyle={panel.bodyStyle}
            orders={panel.orders}
            showOKBtn={panel.showOKBtn}
            showBackBtn={panel.showBackBtn}
            showPrintBtn={panel.showPrintBtn}
          />
        ))}
      </Grid>
    </>
  );
}

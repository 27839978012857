import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import EditProductsTable from './EditProductsTable'
import { Menu } from 'interfaces/menu';
import { Product } from 'interfaces/stock';
import { useDispatch } from 'react-redux';
import { editMenuProducts } from 'redux/ducks/menu'

import useStyles from './styles';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    menu: Menu;
    products: Product[];
}

export default function ModalEditMenu(props: SimpleDialogProps) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { onClose, open, menu, products } = props;

    const [selectedProducts, setSelectedProducts] = useState([])

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        setSelectedProducts(menu?.products)
    }, [menu]);

    const submitEdit = async () => {
        dispatch(editMenuProducts(menu?.menu_id, {
            products: selectedProducts,
        }))
            .then((result: boolean) => {
                if (result) handleClose()
            })
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="simple-dialog-title">
                Editar Produtos do Menu {menu?.name}
            </DialogTitle>
            {menu ?
                <EditProductsTable products={products} menu_products={menu?.products} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
                : null}
            <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
                <Button 
                    onClick={submitEdit} 
                    color="primary"
                    variant="outlined"
                    className={classes.activeButton}
                    >
                        Salvar
                </Button>
                <Button 
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    > 
                        Fechar
                </Button>
            </div>
        </Dialog>
    );
}
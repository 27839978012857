import { combineReducers } from 'redux';

import Alert from 'redux/ducks/alert';
import Authentication from 'redux/ducks/authentication';
import Balcony from 'redux/ducks/balcony';
import History from 'redux/ducks/history';
import Kitchen from 'redux/ducks/kitchen';
import Production from 'redux/ducks/production';
import Home from 'redux/ducks/home';
import Table from 'redux/ducks/table';
import Package from 'redux/ducks/package';
import Manager from 'redux/ducks/manager';
import Stock from 'redux/ducks/stock';
import CustomersAndSellers from 'redux/ducks/customersAndSellers';
import Notification from 'redux/ducks/notification';
import Menu from './menu';
import Store from './store';

export default combineReducers({
  alert: Alert,
  authentication: Authentication,
  balcony: Balcony,
  history: History,
  kitchen: Kitchen,
  production: Production,
  home: Home,
  table: Table,
  package: Package,
  manager: Manager,
  stock: Stock,
  customersAndSellers: CustomersAndSellers,
  notification: Notification,
  menu: Menu,
  store: Store
});

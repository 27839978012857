import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export enum EFilters {
  PRODUCTS = 'products',
  INGREDIENTS = 'ingredients'
}

export enum ETypesStock {
  FETCH = 'STOCK/FETCH',
  SUCCESS = 'STOCK/SUCCESS',
  FAILURE = 'STOCK/FAILURE',
  RESET = 'STOCK/RESET'
}

export interface IStockState {
  fetch?: boolean;
  ingredients: Ingredients[];
  products: Product[];
  providers: Provider[];
}

export interface Ingredients {
  name: string;
  id: number;
  ingredient_id: number;
  measure: string;
  quantity: number;
  cost: number;
}

export interface Product {
  active: boolean;
  bad_stock: number;
  category: string;
  category_2: string;
  ncm: string;
  cost: number;
  define_category: string;
  description: string;
  extras: any[];
  image: string | null;
  ingredients: Ingredients[];
  name: string;
  price: number;
  product_id: number;
  sold: number;
  allergy_notes: string;
  reserved: number;
  stock: number;
  time: number;
  next_days: [{
    date: string,
    weekday: string,
    value: number
  }]
  avarias: number;
  day_history: [{
    quantity: number;
    created_at: string;
    description: string;
    item_id: number;
    type: string;
    before: number;
    after: number;
  }]
}

export interface Provider {
  id: number;
  name: string;
  cpf_cnpj: string;
}

export interface IFetchAction extends IBaseAction<ETypesStock, null> {
  type: ETypesStock.FETCH;
}

export interface ISuccessAction extends IBaseAction<ETypesStock, IStockState> {
  type: ETypesStock.SUCCESS;
  payload: IStockState;
}

export interface IFailureAction extends IBaseAction<ETypesStock, string> {
  type: ETypesStock.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesStock, null> {
  type: ETypesStock.RESET;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;

import React from 'react';
import { Typography } from '@material-ui/core';
import { CardWithValue, CardBody, CardLabel } from './styles';
import NumberFormat from 'react-number-format';

interface Props {
  value: number;
  label: string;
  header?: number;
}

const NumberLessCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { value, label } = props;
  return (
    <CardWithValue>
      <CardBody>
        <CardLabel paragraph align="center">
          {label.toUpperCase()}
        </CardLabel>

        <Typography
          variant="h4"
          style={{ color: value >= 0 ? '#3FA336' : '#DE1C1C' }}
        >
          <NumberFormat
            value={value.toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'R$'}
          />
        </Typography>
      </CardBody>
    </CardWithValue>
  );
};

export default NumberLessCard;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  cardHeaderTodo: {
    marginBottom: 10,
    padding: 5,
    border: '1px solid rgb(158, 2, 6)',
    color: '#000'
  },
  cardHeaderDoing: {
    marginBottom: 10,
    padding: 5,
    border: '1px solid rgb(253, 120, 23)',
    color: '#000'
  },
  cardHeaderDone: {
    marginBottom: 10,
    padding: 5,
    border: '1px solid rgb(1, 165, 4)',
    color: '#000'
  },
  cardBodyTodo: {
    alignItems: 'stretch',
    width: '100%',
    minHeight: 200,
    marginBottom: 10,
    border: '1px solid rgb(158, 2, 6)',
    color: '#000'
  },
  cardBodyDoing: {
    alignItems: 'stretch',
    width: '100%',
    minHeight: 200,
    marginBottom: 10,
    border: '1px solid rgb(255, 145, 36)',
    color: '#000'
  },
  cardBodyDone: {
    alignItems: 'stretch',
    width: '100%',
    minHeight: 200,
    marginBottom: 10,
    border: '1px solid rgb(15, 197, 19)',
    color: '#000'
  },
  pos: {
    marginBottom: 12
  },
  productInfo: {
    marginTop: 5
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  orderIdText: {
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  itemContainer: {
    display: 'flex',
    paddingBottom: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  itemText: {
    color: 'black',
    fontFamily: 'Roboto'
  },
  commentContainer: {
    padding: '2.5%'
  },
  messageContainer: {
    padding: '2.5% 2.5% 5% 2.5%',
    backgroundColor: '#d7d7d77f',
    borderRadius: 5
  },
  commentTitle: {
    fontWeight: 'bold',
    marginBottom: 10
  },
  orderInfoContainer: {
    padding: '5%'
  },
  button: {
    width: 100,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 0.5 black',
    borderRadius: 10,
    fontWeight: 'bold'
  },
  orderContainer: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  receiptHeader: {
    width: 350,
    margin: '0 auto',
    marginTop: 10
  },
  receiptBody: {
    marginTop: 15,
    marginBottom: 10
  }
}));

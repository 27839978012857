import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setProductionStatus,
  setProductionRevertStatus
} from 'redux/ducks/production';

import { IProduction, IProductionState } from 'interfaces/production';
import IStore from 'interfaces/store';

import formatDate from 'date-fns/format';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import LeftIcon from '@material-ui/icons/ArrowBackIosOutlined';
import RightIcon from '@material-ui/icons/ArrowForwardIosOutlined';

import Animate from 'components/Common/Animate';
import Receipt from './Receipt';

interface IProps {
  headerTitle: string;
  headerStyle: string;
  bodyStyle: string;
  orders: IProduction[];
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showPrintBtn?: boolean;
}

export default function Panel(props: IProps): JSX.Element {
  const {
    headerTitle,
    headerStyle,
    bodyStyle,
    orders,
    showOKBtn,
    showBackBtn,
    showPrintBtn
  } = props;
  const dispatch = useDispatch();

  const { currentOrderID } = useSelector<IStore, IProductionState>(
    (state) => state.production
  );

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Animate container={false}>
        <Card className={headerStyle}>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ fontWeight: 300, fontSize: 16 }}
          >
            {headerTitle}
          </Typography>
        </Card>
      </Animate>
      {orders.length > 0 &&
        orders.map((order) => (
          <Animate key={order.id}>
            <Card className={bodyStyle}>
              <CardContent>
                <Grid container justify="space-between" alignItems="center">
                  <Typography
                    variant="overline"
                    style={{ fontWeight: 'bold', fontSize: 16 }}
                  >
                    {order.order_code}
                  </Typography>
                  <Typography variant="overline">
                    {formatDate(
                      new Date(order.date_ordered),
                      'dd/MM/yyyy - HH:mm'
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{
                    backgroundColor: '#f5f5f5',
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: 10,
                    width: '100%'
                  }}
                >
                  <Grid style={{ marginTop: 10 }}>
                    <Typography
                      variant="overline"
                      style={{ fontWeight: 'bold' }}
                    >
                      {order?.order_type}
                    </Typography>
                    <Typography paragraph variant="overline">
                      {`${order.quantity}${order.measure || 'x'} ${order.item}`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container justify="space-between">
                  {showBackBtn ? (
                    <Button
                      size="small"
                      disabled={currentOrderID === order.id}
                      onClick={() => dispatch(setProductionRevertStatus(order))}
                    >
                      <LeftIcon />
                    </Button>
                  ) : (
                    <Button disabled>{''}</Button>
                  )}
                  {showPrintBtn && (
                    <Receipt
                      order={order}
                      disabled={currentOrderID === order.id}
                    />
                  )}
                  {showOKBtn ? (
                    <Button
                      size="small"
                      disabled={currentOrderID === order.id}
                      onClick={() => dispatch(setProductionStatus(order))}
                    >
                      <RightIcon />
                    </Button>
                  ) : (
                    <Button disabled>{''}</Button>
                  )}
                </Grid>
              </CardActions>
            </Card>
          </Animate>
        ))}
    </Grid>
  );
}

Panel.defaultProps = {
  showOKBtn: true,
  showBackBtn: true,
  showPrintBtn: false
};

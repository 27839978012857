import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  fetchBestBuyers,
  fetchBestBuyersWithDateRange,
  fetchLastOrder
} from 'redux/ducks/customersAndSellers';
import { EFilters } from '../../enums/customers';
import formatDate from 'date-fns/format';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import CustomersTable from './CustomersTable';
import DateFilter from './DateFilter';
import LastOrderModal from './LastOrderModal';

const filters = [
  {
    label: 'Hoje',
    value: EFilters.TODAY
  },
  {
    label: 'Ontem',
    value: EFilters.YESTERDAY
  },
  {
    label: 'Ultimos 7 dias',
    value: EFilters.LAST_WEEK
  },
  {
    label: 'Ultimos 30 dias',
    value: EFilters.LAST_MONTH
  },
  {
    label: 'Todos',
    value: EFilters.ALL
  }
];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const { customersAndSellers } = useSelector<IStore, IStore>((state) => state);
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.TODAY);
  const [initialDate, setInitialDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchBestBuyers(activeFilter));
  }, [dispatch, activeFilter]);

  const handleDateRange = () => {
    dispatch(
      fetchBestBuyersWithDateRange(
        formatDate(initialDate, 'yyyy-MM-dd'),
        formatDate(endDate, 'yyyy-MM-dd')
      )
    );
  };

  const handleLastOrder = async (orderId: number) => {
    setLoading(true);
    setOpenModal(true);
    await dispatch(fetchLastOrder(orderId));
    setLoading(false);
  };

  // console.log(customersAndSellers.last_order);

  return (
    <>
      <Loading show={customersAndSellers.fetch} />
      <LastOrderModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        orderToShow={customersAndSellers.last_order}
        loading={loading}
      />
      <DateFilter
        activeFilter={activeFilter}
        setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
        filters={filters}
        dateRange={{
          initialDate,
          endDate,
          setInitialDate,
          setEndDate
        }}
        handleDateRange={handleDateRange}
      />
      <Grid container spacing={3}>
        <CustomersTable
          customers={customersAndSellers.customers}
          handleLastOrder={handleLastOrder}
        />
      </Grid>
    </>
  );
}

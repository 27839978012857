import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export interface IChartState {
    fetch: boolean
    orders: {
        opened: number,
        closed: number,
        canceled: number,
        afazer: number,
        fazendo: number,
        feito: number,
        depot: number,
        all: number
    };
    ingredients: {
        opened: number,
        closed: number,
        afazer: number,
        fazendo: number,
        feito: number,
        all: number
    };
    production: {
        opened: number,
        closed: number,
        afazer: number,
        fazendo: number,
        feito: number,
        all: number
    };
}

export enum ETypesChart {
    FETCH = 'HOME/FETCH',
    SUCCESS = 'HOME/SUCCESS',
}

export interface IFetchAction extends IBaseAction<ETypesChart, null> {
    type: ETypesChart.FETCH;
}

export interface ISuccessAction
    extends IBaseAction<ETypesChart, IChartState> {
    type: ETypesChart.SUCCESS;
    payload: IChartState;
}

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type GetState = () => any;

export type IHomeAction =
    | IFetchAction
    | ISuccessAction;

export type PromiseAction = Promise<IHomeAction>;

export type Dispatch = (
    action:
        | IHomeAction
        | ThunkAction
        | PromiseAction
        | IAlertAction
) => any;


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchMenusGeneral } from 'redux/ducks/menu';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import MenusTable from './MenusTable';


export default function Menus(): JSX.Element {
  const dispatch = useDispatch();
  const { menu } = useSelector<IStore, IStore>((state) => state);
  const { stock } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    dispatch(fetchMenusGeneral());
    dispatch(fetchProductsGeneral());
  }, [dispatch]);



  return (
    <>
      <Loading show={menu.fetch} />
      <Grid container spacing={3}>
        <MenusTable menus={menu.menus} products={stock.products} />
      </Grid>
    </>
  );
}

import React from 'react';

import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';

interface IProps {
  children: React.ReactElement;
  container?: boolean;
}

export default function Animate(props: IProps): JSX.Element {
  const { children, container } = props;
  const classes = useStyles();

  return (
    <Grid container={container} className={classes.animate}>
      {children}
    </Grid>
  );
}
Animate.defaultProps = {
  container: true
};

import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IStoreState,
  ETypesStore,
  IStoreAction,
  IStoreBase
} from 'interfaces/store_test';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: IStoreState = {
  fetch: false,
  next: '',
  previous: '',
  results: [],
  error: ''
};

/* Authentication Reducer. */
export default (
  state: IStoreState = initialState,
  action: IStoreAction
): IStoreState => {
  switch (action.type) {
    case ETypesStore.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesStore.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesStore.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    case ETypesStore.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStore = (): IFetchAction => ({
  type: ETypesStore.FETCH
});

export const successStore = (payload: IStoreState): ISuccessAction => ({
  type: ETypesStore.SUCCESS,
  payload
});

export const failureStore = (payload: string): IFailureAction => ({
  type: ETypesStore.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesStore.RESET
});

/* Authentication Side Effects Functions. */
export const getStores = (params: {} = undefined): ThunkAction => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch(fetchStore());

    let url = `/products/api/stores/`;
    let response;
    let results: IStoreBase[] = [];
    do {
      response = await fetch({
        method: EMethod.GET,
        url: url
      });
      url = response.next;
      results.push(...response.results);
    } while (response.next);
    response.results = results;
    dispatch(successStore(response));
  } catch (error) {
    dispatch(failureStore(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const getStore = (store_id: number = undefined): ThunkAction => async (
  dispatch: Dispatch
): Promise<IStoreBase> => {
  try {
    dispatch(fetchStore());

    let url = `/products/api/stores/${store_id}/`;
    let response;
    let results: IStoreBase;
    do {
      response = await fetch({
        method: EMethod.GET,
        url: url
      });
      url = response.next;
      results = response;
    } while (response.next);
    return results;
  } catch (error) {
    dispatch(failureStore(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return null;
  }
};

export const editStoreMenus = (store_id: number = undefined, data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.PATCH,
      url: `products/api/stores/${store_id}/`,
      data: data
    })
    dispatch(sendAlert('Store menus edited successfully', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const handleIsOwnStore = (store_id: number = undefined): ThunkAction => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: `products/api/stores/${store_id}/handle_is_own_store/`
    });
    dispatch(getStores())
    dispatch(sendAlert('Store is_own_store edited successfully', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};
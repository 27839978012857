import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import StoreTable from './storeTable';
import { fetch } from './../../utils/request';
import { getStores } from 'redux/ducks/store';
import { Menu } from 'interfaces/menu';


export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const { stock } = useSelector<IStore, IStore>((state) => state);

  const { store } = useSelector<IStore, IStore>((state) => state);
  const [fetchedMenus, setFetchedMenus] = useState<Menu[]>([]);

  const _getMenus = async () => {
    try {
      let response;
      let results = [];
      let url = '/products/api/menus/?store=ALL'
      do {
        response = await fetch({
          method: 'GET',
          url: url
        });
        url = response.next;
        results.push(...response.results);
        console.log('response ==> ', response.results);
      } while (response.next);
      response.results = results;
      setFetchedMenus(response.results);
    } catch (error) {
      console.log('error fetching ingredients');
    }
  };
  const control = 0;
  useEffect(() => {
    _getMenus();
    dispatch(getStores());
  }, [control, dispatch]);
  console.log(store.results)
  return (
    <>
      <Loading show={stock.fetch} />
      <Grid container spacing={3}>
        <StoreTable fetchedMenus={fetchedMenus} stores={store.results} />
      </Grid>
    </>
  );
}

import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IStockState,
  ETypesStock,
  IManagerAction,
  GetState
} from 'interfaces/stock';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { mountQueryURL } from '../../utils/query';

/* Authentication State. */
const initialState: IStockState = {
  fetch: false,
  ingredients: [],
  products: [],
  providers: []
};

/* Authentication Reducer. */
export default (
  state: IStockState = initialState,
  action: IManagerAction
): IStockState => {
  switch (action.type) {
    case ETypesStock.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesStock.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesStock.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesStock.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStock = (): IFetchAction => ({
  type: ETypesStock.FETCH
});

export const successFetchStock = (payload: IStockState): ISuccessAction => ({
  type: ETypesStock.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesStock.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesStock.RESET
});

/* Authentication Side Effects Functions. */
export const fetchProductsGeneral = (params: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { stock } = getState();
    dispatch(fetchStock());
    let query_url;
    if (params) query_url = mountQueryURL(params);
    else query_url = '';
    let url = `products/api/products/calculate_stock_products/${query_url}`;
    let response;
    let results = [];
    do {
      response = await fetch({
        method: EMethod.GET,
        url: url
      });
      url = response.next;
      results.push(...response);
    } while (response.next);
    response.results = results;
    dispatch(successFetchStock({ ...stock, products: results }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const fetchProducts = (params: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { stock } = getState();
    dispatch(fetchStock());
    let query_url;
    if (params) query_url = mountQueryURL(params);
    else query_url = '';
    const response = await fetch({
      method: EMethod.GET,
      url: `products/api/products/calculate_stock_products/${query_url}`
    });
    dispatch(successFetchStock({ ...stock, products: response }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const createProduct = (data: {} = undefined, params: {} = undefined): ThunkAction => async (
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const body = new FormData();
    //  var tags: any;
    class Ingredient {
      id: number;
      ingredient_id: number;
      ingredient_stock: {
        measure: string;
        quantity: number;
      };
    }

    class Extras {
      id: number;
      ingredient: string;
      limit: number;
      price: string;
      is_sauce: number;
      quantity: number;
    }
    var ingredientsAarray: Ingredient[] = [];
    var extras: Extras[] = [];
    Object.keys(data).forEach((key) => {
      if (key === 'image') {
        if (data[key]) {
          body.append(key, data[key]);
        } else {
          return null;
        }
      } else {
        if (key === 'extras') {
          extras = data[key];
        } else {
          if (key !== 'ingredients') {
            //   if (key === 'tags') {
            //   tags = data[key];
            // } else {
            body.append(key, data[key]);
            //}
          } else {
            ingredientsAarray = data[key];
          }
        }
      }
    });
    console.log(body);
    await fetch({
      method: EMethod.POST,
      headers: { 'content-type': 'multipart/form-data' },
      url: 'products/api/products/',
      data: body
    }).then(async (response) => {
      for (let index = 0; index < ingredientsAarray.length; index++) {
        const element = ingredientsAarray[index];
        console.log('element ==> ', element);
        var ingredientBody = {};
        Object.keys(element).forEach((key) => {
          if (key !== 'id') {
            ingredientBody[key] = element[key];
          }
        });
        //  console.log('ingredients ==> ', ingredientBody);

        await fetch({
          method: EMethod.POST,
          headers: {
            'content-type': 'application/json'
          },
          url: `products/api/products/${response.product_id}/add_ingredient_to_product/`,
          data: ingredientBody
        });
      }
      console.log('extras ==> ', extras);
      for (let index = 0; index < extras.length; index++) {
        const element = extras[index];
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'application/json' },
          url: `products/api/products/${response.product_id}/add_extra_to_product/`,
          data: element
        });
      }

      /**
      *  console.log('tags ==> ', tags);
      await fetch({
        method: EMethod.PATCH,
        headers: { 'content-type': 'application/json' },
        url: `products/api/products/${response.product_id}/`,
        data: tags
      }); */
    });
    dispatch(fetchProducts(params));
    dispatch(sendAlert('Produto criado com sucesso', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const editProduct = (
  product_id: number = undefined,
  data: {} = undefined,
): ThunkAction => async (dispatch: Dispatch): Promise<boolean> => {
  try {
    const body = new FormData();
    var oldExtras: any[] = [];
    var oldIngredients: any[] = [];
    var newExtras: any[] = [];
    var store;
    var newIngredients: any[] = [];
    Object.keys(data).forEach((key) => {
      if (key === 'image') {
        if (data[key]) {
          body.append(key, data[key]);
        } else {
          return null;
        }
      } else {
        switch (key) {
          case 'oldExtras':
            oldExtras = data[key];
            break;
          case 'oldIngredients':
            oldIngredients = data[key];
            break;
          case 'newExtras':
            newExtras = data[key];
            break;
          case 'newIngredients':
            newIngredients = data[key];
            break;
          case 'store':
            store = data[key];
            break;
          default:
            body.append(key, data[key]);
            break;
        }
      }
    });
    await fetch({
      method: EMethod.PATCH,
      headers: { 'content-type': 'multipart/form-data' },
      url: `products/api/products/${product_id}/?store=${store}`,
      data: body
    });
    for (let index = 0; index < oldIngredients.length; index++) {
      const element = oldIngredients[index];
      console.log('4 -', element);
      await fetch({
        method: EMethod.PATCH,
        url: `/products/api/stocks/${element.stock}/`,
        data: {
          quantity: element.quantity,
          measure: element.measure,
          ingredient_id: element.ingredient
        }
      });
    }
    for (let index = 0; index < newIngredients.length; index++) {
      const element = newIngredients[index];
      console.log('1 -', element);
      await fetch({
        method: EMethod.POST,
        url: `products/api/products/${product_id}/add_ingredient_to_product/`,
        data: element
      });
    }
    for (let index = 0; index < oldExtras.length; index++) {
      const element = oldExtras[index];
      console.log('3-', element);
      await fetch({
        method: EMethod.PATCH,
        headers: {
          'content-type': 'application/json'
        },
        url: `/products/api/extras/${element.id}/`,
        data: element
      });
    }
    for (let index = 0; index < newExtras.length; index++) {
      const element = newExtras[index];
      console.log('2 -', element);

      await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'application/json' },
        url: `products/api/products/${product_id}/add_extra_to_product/`,
        data: element
      });
    }
    dispatch(fetchProductsGeneral({ store: store }));
    dispatch(sendAlert('Produto editado com sucesso', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const fetchIngredients = (params: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { stock } = getState();
    dispatch(fetchStock());
    let query_url;
    if (params) query_url = mountQueryURL(params);
    else query_url = '';
    const response = await fetch({
      method: EMethod.GET,
      url: `products/api/ingredients/${query_url}`
    });
    dispatch(successFetchStock({ ...stock, ingredients: response.results }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};


export const submitProductionOrder = (data: {}): ThunkAction => async (
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: 'products/api/products/production_order/',
      data: data
    });
    dispatch(sendAlert('Ordem de Produção criada com sucesso', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const submitBuyOrder = (data: {}): ThunkAction => async (
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: 'products/api/products/buy_order/',
      data: data
    });
    dispatch(sendAlert('Ordem de Produção criada com sucesso', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const submitAvariaOrder = (data: {}): ThunkAction => async (
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: 'products/api/products/add_avaria_stock/',
      data: data
    });
    dispatch(sendAlert('Ordem de Avaria criada com sucesso', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const createIngredient = (data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: 'products/api/ingredients/',
      data: data
    });
    dispatch(sendAlert('Ingrediente criado com sucesso', EAlertVariant.SUCCESS));
    dispatch(fetchIngredients());
    return true;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const submitIngredientProductionOrder = (data: {}): ThunkAction => async (
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.POST,
      url: 'products/api/ingredients/production_order/',
      data: data
    });
    dispatch(sendAlert('Ordem de Produção criada com sucesso', EAlertVariant.SUCCESS));
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const fetchProviders = (
  params: {} = undefined
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
    try {
      const { stock } = getState();
      dispatch(fetchStock());
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = '';
      let url = `products/api/providers/${query_url}`;
      let response;
      let results = [];
      do {
        response = await fetch({
          method: EMethod.GET,
          url: url
        });
        url = response.next;
        console.log('response ==> ', response.results);
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      console.log('providers => ', results);
      dispatch(successFetchStock({ ...stock, providers: results }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

  export const createProvider = (data: {} = undefined): ThunkAction => async (
    dispatch: Dispatch
  ): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.POST,
        url: 'products/api/providers/',
        data: data
      });
      dispatch(sendAlert('Fornecedor criado com sucesso', EAlertVariant.SUCCESS));
      dispatch(fetchProviders());
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const fetchCustomer = (params: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { stock } = getState();
    dispatch(fetchStock());
    let query_url;
    if (params) query_url = mountQueryURL(params);
    else query_url = '';
    const response = await fetch({
      method: EMethod.GET,
      url: `/waiter/api/client/${query_url}`
    });
    dispatch(successFetchStock({ ...stock, client: response.results }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};


export const createCustomer = (data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch
): Promise<boolean> => {

  try {
    let address: object

    Object.keys(data).forEach((key) => {
      if (key === 'address') {
          address = data[key];
          delete data[key];
      } else {
          return data[key];
        }
    });
    console.log(data)
    await fetch({
      method: EMethod.POST,
      url: '/waiter/api/client/',
      data: data
    }).then(async (response) => {
      await fetch({
        method: EMethod.PATCH,
        headers: {
          'content-type': 'application/json'
        },
        url: `/waiter/api/client/${response.id}/update_address/?type=realtime`,
        data: {'address': address}
      });
    });
    dispatch(sendAlert('Customer created successfully', EAlertVariant.SUCCESS));
    dispatch(fetchCustomer());
    return true;

  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { Menu } from 'interfaces/menu';
import { useDispatch } from 'react-redux';
import { deleteMenu } from 'redux/ducks/menu'

import useStyles from './styles';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    menu: Menu;
}

export default function ModalEditMenu(props: SimpleDialogProps) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { onClose, open, menu } = props;

    const handleClose = () => {
        onClose();
    };

    const submitDelete = async () => {
        dispatch(deleteMenu(menu?.menu_id))
            .then((result: boolean) => {
                if (result) handleClose()
            })
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Deletar Menu {menu?.name}?
            </DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
                <Button onClick={submitDelete} 
                    color="primary"
                    variant="outlined"
                    className={classes.activeButton}
                > 
                    Deletar 
                </Button>
                <Button 
                    onClick={handleClose}
                    variant="outlined"
                    className={classes.button}
                > 
                    Fechar 
                </Button>
            </div>
        </Dialog>
    );
}
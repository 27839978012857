import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import ModalCreateProvider from './ModalCreateProvider';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Provider } from 'interfaces/stock';

import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid'; //

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  providers: Provider[];
}

export default function ProvidersTable(props: Props) {
  const classes = useStyles();
  const { providers } = props;
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', type: 'number', width: 90, headerAlign: 'left', align: 'left' },
    { field: 'name', headerName: 'Nome', type: 'string', width: 500, headerAlign: 'left', align: 'left' },
    {
      field: 'cpf_cnpj',
      headerName: 'CPF/CNPJ',
      type: 'string',
      width: 300,
      headerAlign: 'left',
      align: 'left',
    },
  ];

  let rows: Array<any> 
  if(providers){
    rows = providers?.map((provider) => (
      (
        {
          id: provider.id,
          name: provider.name,
          cpf_cnpj: provider.cpf_cnpj,
        }
      )
    ))
  }else {
    rows = [({
      id: '',
      name: '',
      cpf_cnpj: '',
    })]  
  }
  

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button
        style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
        color="primary"
        onClick={handleClickOpen}
        className={classes.activeButton}
      >
        Adicionar Fornecedor
      </Button>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>
      <ModalCreateProvider
        open={open}
        onClose={handleClose}
      />
    </TableContainer>
  );
}
import React from 'react';
import {
    Button,
    InputLabel,
    Input,
    Container,
    Grid,
    FormControl,
    IconButton
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createProvider } from 'redux/ducks/stock';
import './styles.css';

import useStyles from './styles';

// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function ModalCreateMenu(props: SimpleDialogProps) {
    const classes = useStyles();

    const { onClose, open } = props;
    const [name, setName] = React.useState('');
    const [cpfCnpj, setCpfCnpj] = React.useState('');

    const dispatch = useDispatch();

    const handleClose = () => {
        setName('');
        onClose();
    };

    const handleName = (event: any) => {
        setName(event.target.value);
    };
    const handleCpfCnpj = (event: any) => {
        setCpfCnpj(event.target.value);
    };
    const submitCreation = async () => {
        dispatch(
            createProvider({
                name: name,
                cpf_cnpj: cpfCnpj
            })
        ).then((result: boolean) => {
            if (result) handleClose();
        });
    };
    return (
        <Modal
            className="create-menu-dialog"
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-create-menu-content">
                <IconButton
                    style={{ width: '50px' }}
                    color="secondary"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>

                <h2>Adicionar Fornecedor</h2>
                <Container maxWidth="md">
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="name-input">Nome</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={name}
                                onChange={handleName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="name-input">CPF/CNPJ</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={cpfCnpj}
                                onChange={handleCpfCnpj}
                            />
                        </FormControl>
                    </Grid>
                    {/* End of left column */}
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ marginBottom: '30px' }}
                                variant="outlined"
                                onClick={submitCreation}
                                color="primary"
                                className={classes.activeButton}
                            >
                                Criar{' '}
                            </Button>
                        </div>
                    </Grid>
                </Container>
            </div>
        </Modal>
    );
}
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, CardContent } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  button: {
    margin: '0 5px',
    color: '#211f5c',
    border: 'none',
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  datePicker: {
    width: '70%',
    minWidth: '150px',
    marginRight: '10px'
  },
  datePickerButton: {
    color: '#211f5c'
  },
  active: {
    backgroundColor: '#211f5c !important'
  },
  disable: {
    color: '#211f5c',
    backgroundColor: '#fdfdfd !important'
  }
}));

export const CardWithValue = styled(Card)`
  background-color: #fdfdfd;
  min-width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CardWithGraphic = styled(Card)`
  background-color: #fdfdfd;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CardLabel = styled(Typography)`
  margin-bottom: 0;
`;

export const GraphicCardLabel = styled(Typography)`
  width: 100%;
  padding: 10px 20px;
  color: #4c4c4c;
  border-bottom: 1px solid #d1d1d1;
`;

export const CardBody = styled(CardContent)`
  padding-bottom: 16px !important;
`;

export const ColorLabel = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: ${(props) => (props.color ? props.color : '#211f5c')};
`;

export const PlusInfo = styled.span`
  color: #3fa336;
`;

export default useStyles;

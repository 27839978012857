import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import ManagerDashboard from './ManagerDashboard';
import CheckPassword from './CheckPassword';

export default function Manager(): JSX.Element {
  const classes = useStyles();
  const [showManager, setShowManager] = useState(false);

  useEffect(() => {
    setShowManager(false);
  }, []);

  return (
    <Grid container className={classes.fullView} justify="center">
      {showManager ? (
        <ManagerDashboard />
      ) : (
        <CheckPassword handleSuccess={() => setShowManager(true)} />
      )}
    </Grid>
  );
}

import React, { useEffect, useState } from 'react';

import { IKitchen } from 'interfaces/kitchen';
import logo from './../../assets/img/farina_logo.jpg';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import Print from 'components/Common/Print';
import { parseString } from 'xml2js';
import QrCode from 'qrcode.react';
import Moment from 'react-moment';
import nfceIcon from './../../assets/img/NFCe-icon.png';
import './styles.css';
interface IProps {
  order: IKitchen;
  disabled?: boolean;
}

interface NFCeData {
  nfeProc: any;
  protNFe: any;
}

export default function Nfce(props: IProps): JSX.Element {
  const { order, disabled } = props;

  const renderInfo = (str: any) => {
    str = str.replace('<br />', '');
    return str;
  };
  const [data, setData] = useState<NFCeData | undefined>(undefined);
  const [success, setSucess] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (order.NFCe_xml) {
      console.log('triggered');
      const store: string = localStorage.getItem('state');
      const state = JSON.parse(store);
      const { token } = state.authentication;
      var myHeaders = new Headers();
      myHeaders.append('origin', 'sameorigin');
      myHeaders.append('Authorization', `Bearer ${token}`)
      var url = `https://farina.stopplay.io/orders/api/orders/${order.order_id}/get_NFCe_xml/`;
      fetch(url, {
        headers: myHeaders
      })
        .then((response) => response.json())
        .then(async (responseText) => {
          console.log("here 1")
          await parseString(responseText['response'], function (err, result) {
            if (result !== undefined) {
              console.log('data => ', result);
              setData(result);
              setSucess(true);
              return result;
            }
          });
        })
        .catch((error) => {
          console.log('Error fetching the feed: ', error, Object.keys(error));
        });
    } else {
      console.log('unpaid ? =>', order);
    }
  };
  return (
    <>
      {success === true ? (
        <>
          <Print
            trigger={
              <Button size="medium" disabled={disabled}>
                <img height="35px" width="50px" src={nfceIcon} />
              </Button>
            }
          >
            <Card>
              <div className="NFCe-wrapper">
                <div className="image-and-info-wrapper">
                  <div className="logo-image-wrapper">
                    <img height="100%" width="100%" src={logo} alt="" />
                  </div>
                  <div className="image-and-info-wraper-2">
                    <p>
                      Número do Pedido{' '}
                      {success === true
                        ? order.order_code
                        : ''}
                    </p>
                    <p>
                      {success === true
                        ? data.nfeProc.NFe[0].infNFe[0].emit[0].xNome
                        : ''}
                    </p>
                    <p>
                      CNPJ:{' '}
                      {success === true
                        ? data.nfeProc.NFe[0].infNFe[0].emit[0].CNPJ
                        : ''}
                    </p>
                    <p>
                      IE:{' '}
                      {success === true
                        ? data.nfeProc.NFe[0].infNFe[0].emit[0].IE
                        : ''}
                    </p>
                    <p>
                      {success === true ? (
                        <>
                          {
                            data.nfeProc.NFe[0].infNFe[0].emit[0].enderEmit[0]
                              .xLgr[0]
                          }{' '}
                          ,{' '}
                          {
                            data.nfeProc.NFe[0].infNFe[0].emit[0].enderEmit[0]
                              .nro[0]
                          }
                          ,{' '}
                          {
                            data.nfeProc.NFe[0].infNFe[0].emit[0].enderEmit[0]
                              .xCpl[0]
                          }
                          ,{' '}
                          {
                            data.nfeProc.NFe[0].infNFe[0].emit[0].enderEmit[0]
                              .xBairro[0]
                          }{' '}
                          {
                            data.nfeProc.NFe[0].infNFe[0].emit[0].enderEmit[0]
                              .xMun[0]
                          }{' '}
                          -{' '}
                          {
                            data.nfeProc.NFe[0].infNFe[0].emit[0].enderEmit[0]
                              .xPais[0]
                          }
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashed-divider" />
                <p style={{ alignSelf: 'center' }}>
                  DOCUMENTO AUXILIAR DA NOTA FISCAL DE CONSUMIDOR ELETRÔNICA
                </p>
                <div className="dashed-divider" />
                <div className="second-cell-divider">
                  <div className="second-cell-element">
                    <p>ITEM</p>
                    <p>VL. ITEM</p>
                  </div>
                  {success === true
                    ? data.nfeProc.NFe[0].infNFe[0].det.map(
                        (product: any, index: any) => {
                          return (
                            <div key={index} className="second-cell-element">
                              <p>
                                {product.prod[0].cProd} {product.prod[0].xProd}{' '}
                                {Math.ceil(product.prod[0].qCom)} X{' '}
                                {product.prod[0].vUnTrib}
                              </p>
                              <p>{product.prod[0].vProd}</p>
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
                <div className="dashed-divider" />
                <div className="second-cell-divider">
                  <div className="second-cell-element">
                    <p>QTD. TOTAL DE ITENS</p>
                    <p>
                      {' '}
                      {success === true
                        ? data.nfeProc.NFe[0].infNFe[0].det.length
                        : ''}
                    </p>
                  </div>
                  {order.delivery_fee ?
                  <div className="second-cell-element">
                    <p>ENTREGA R$</p>
                    <p>
                      {' '}
                      {success === true
                        ? order.delivery_fee.toFixed(2)
                        : ''}
                    </p>
                  </div>
                  : ''}
                  <div className="second-cell-element">
                    <p>VALOR TOTAL R$</p>
                    <p>
                      {' '}
                      {success === true
                        ? data.nfeProc.NFe[0].infNFe[0].total[0].ICMSTot[0]
                            .vNF[0]
                        : ''}
                    </p>
                  </div>
                  <div className="second-cell-element">
                    <p>FORMA DE PAGAMENTO</p>
                    <p>Valor pago</p>
                  </div>
                  <div className="second-cell-element">
                    {order.payment === 'cash' ? <p>01 - Dinheiro</p> : order.payment === 'card' ? <p>02 - Cartão</p> :  order.payment === 'transfer' ? <p>03 - Transferência</p> : order.payment === 'machine' ? <p>04 - Maquineta</p> : order.payment === 'picpay' ? <p>05 - PicPay</p>: ''}
                    <p>
                      {success === true
                        ? data.nfeProc.NFe[0].infNFe[0].pag[0].detPag[0].vPag[0]
                        : ''}
                    </p>
                  </div>
                </div>
                <div className="dashed-divider" />
                <div className="third-cell-wrapper">
                  <p className="third-cell-text">
                    Consulte pela Chave de Acesso em
                    http://www.sefaz.pb.gov.br/nfcd
                  </p>
                  <p className="third-cell-text">
                    {success === true && data.nfeProc.protNfe
                      ? data.nfeProc.protNFe[0].infProt[0].chNFe[0]
                      : ''}
                  </p>
                </div>
                <div style={{ height: '25px' }} />
                <div className="dashed-divider" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <p style={{ alignSelf: 'center' }}>
                    {order.owner
                    ? 'CONSUMIDOR - ' + order.owner.name:
                    'CONSUMIDOR - ' + order.client.name }
                  </p>
                </div>
                <div className="dashed-divider" />
                <div className="third-cell-wrapper">
                  <p className="third-cell-text">
                    NFC-e nº{' '}
                    {success === true
                      ? data.nfeProc.NFe[0].infNFe[0].ide[0].nNF[0]
                      : ''}{' '}
                    Série{' '}
                    {success === true
                      ? "00" + data.nfeProc.NFe[0].infNFe[0].ide[0].serie[0]
                      : ''}{' '}
                    {success === true ? (
                      <>
                        <Moment format="DD/MM/yyyy">
                          {data.nfeProc.NFe[0].infNFe[0].ide[0].dhEmi[0]}
                        </Moment>{' '}
                        <Moment format="hh:mm:ss">
                          {data.nfeProc.NFe[0].infNFe[0].ide[0].dhEmi[0]}
                        </Moment>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  <p className="third-cell-text">
                    Protocolo de Autorização:{' '}
                    {success === true && data.nfeProc.protNFe
                      ? data.nfeProc.protNFe[0].infProt[0].nProt[0]
                      : ''}
                  </p>
                  <p className="third-cell-text">
                    Data de autorização:{' '}
                    {success === true && data.nfeProc.protNFe ? (
                      <>
                        <Moment format="DD/MM/yyyy">
                          {data.nfeProc.protNFe[0].infProt[0].dhRecbto[0]}
                        </Moment>{' '}
                        <Moment format="hh:mm:ss">
                          {data.nfeProc.protNFe[0].infProt[0].dhRecbto[0]}
                        </Moment>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  <br />
                </div>
                <div className="dashed-divider" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {success === true && data.nfeProc.protNFe ? (
                    <>
                      <div className="br" />
                      <QrCode
                        style={{ alignSelf: 'center' }}
                        value={
                          'https://www4.sefaz.pb.gov.br/atf/seg/SEGf_AcessarFuncao.jsp?cdFuncao=FIS_1410&p=' +
                          data.nfeProc.protNFe[0].infProt[0].chNFe[0]
                        }
                      />
                      <div className="br" />
                    </>
                  ) : null}
                </div>
                <div className="dashed-divider" />
                <p style={{ alignSelf: 'center', textAlign: 'center' }}>
                  INFORMAÇÕES ADICIONAIS DE INTERESSE DO CONTRIBUINTE{' '}
                  {success === true
                    ? renderInfo(
                        data.nfeProc.NFe[0].infNFe[0].infAdic[0].infCpl[0]
                      )
                    : ''}
                </p>
              </div>
            </Card>
          </Print>
        </>
      ) : null}
    </>
  );
}

Nfce.defaultProps = {
  disabled: false
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { StatusNotification } from 'interfaces/notification';
import ModalCreateNotificationStatus from './ModalCreateNotificationStatus'
import ModalEditNotificationStatus from './ModalEditNotificationStatus'
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { DataGrid, GridColDef, GridCellParams, GridToolbar } from '@material-ui/data-grid'; //

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  icons: {
    fill: '#3f51b5'
  },
});

interface IProps {
  notifications: StatusNotification[];
}


export default function StatusNotificationsTable(props: IProps) {
  const classes = useStyles();
  const { notifications } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedNotification, setSelectedNotification] = React.useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenEdit = (notification: StatusNotification) => {
    setOpenEdit(true);
    setSelectedNotification(notification)
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setSelectedNotification(undefined)
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', type: 'number', width: 90, headerAlign: 'left', align: 'left' },
    {
      field: 'message',
      headerName: 'Mensagem',
      type: 'string',
      width: 770,
      headerAlign: 'left',
      align: 'left',
    },
    { field: 'status', headerName: 'Status', width: 180, headerAlign: 'left',  align: 'left'  },
    {
      field: 'actions',
      headerName: 'Ações',
      type: 'string',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '3px', fill: 'blue' }}>{(params.value)}</span>
          <EditIcon className={classes.icons} onClick={() => { }} /* handleClickOpenHistory(params.product) */ />
        </div>
      ),
    },
    
  ];

  let rows: Array<any> 
  
  if(notifications){
    rows = notifications?.map((notification) => (
      (
        {
          id: notification.id,
          message: notification.message,
          status: notification.status,
        }
      )
    ))
  }else {
    rows = [({
      id: '',
      message: '',
      status: '',
    })]  
  }
  

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }} color="primary" onClick={handleClickOpen} className={classes.activeButton}>Adicionar notificação de status</Button>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          onCellClick={(params: GridCellParams) => (

            params.field == "actions" ? notifications?.map((notification) => {
                if (params.row.id == notification.id) {
                    console.log(params.row.id)
                    handleClickOpenEdit(notification)
                }
              })
                :
                null
              ) 
            }
        />
      </div>
      <ModalCreateNotificationStatus
        open={open}
        onClose={handleClose}
      />
      <ModalEditNotificationStatus
        open={openEdit}
        notification={selectedNotification}
        onClose={handleClose}
      />
    </TableContainer>
  );
}

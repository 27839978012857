import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { GeneralNotification } from 'interfaces/notification';
import ModalCreateNotificationGeneral from './ModalCreateNotificationGeneral'
import { Button } from '@material-ui/core';

import formatDate from 'date-fns/format';

import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid'; //

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  notifications: GeneralNotification[];
}


export default function GeneralNotificationsTable(props: IProps) {
  const classes = useStyles();
  const { notifications } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', type: 'number', width: 90, headerAlign: 'left', align: 'left' },
    { field: 'title', headerName: 'Título', type: 'string', width: 200, headerAlign: 'left', align: 'left' },
    {
      field: 'message',
      headerName: 'Mensagem',
      type: 'string',
      width: 1250,
      headerAlign: 'left',
      align: 'left',
    },
    { field: 'created_date', headerName: 'Criado às', width: 280, headerAlign: 'right', align: 'right' },
  ];

  let rows: Array<any> 
  
  if(notifications){
    rows = notifications?.map((notification) => (
      (
        {
          id: notification.id,
          title: notification.title,
          message: notification.message,
          created_date: formatDate(new Date(notification.created_date), 'dd/MM/yyyy hh:mm'),
        }
      )
    ))
  }else {
    rows = [({
      id: '',
      title: '',
      message: '',
      created_date: '',
    })]
  }
  

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }} color="primary" onClick={handleClickOpen} className={classes.activeButton}>Adicionar notificação geral</Button>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>
      <ModalCreateNotificationGeneral
        open={open}
        onClose={handleClose}
      />
    </TableContainer>
  );
}

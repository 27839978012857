import React, { useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, InputLabel, Input, Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editMenu } from 'redux/ducks/menu'
import { Menu } from 'interfaces/menu';

import useStyles from './styles';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    menu: Menu;
}

export default function ModalEditMenu(props: SimpleDialogProps) {
    const classes = useStyles();

    const { onClose, open, menu } = props;
    const [name, setName] = React.useState('');
    const [typeOfMenu, setTypeOfMenu] = React.useState('Take Away');
    const [maxOfItems, setMaxOfItems] = React.useState(0);
    const [maxOfSameItem, setMaxOfSameItem] = React.useState(0);
    const [active, setActive] = React.useState(true);

    const dispatch = useDispatch();

    const handleClose = () => {
        setName('');
        setActive(true);
        setMaxOfItems(0);
        setMaxOfSameItem(0);
        onClose();
    };

    useEffect(() => {
        if (menu) {
            setName(menu.name);
            setTypeOfMenu(menu.type_of_menu);
            setMaxOfItems(menu.max_quantity_of_items);
            setMaxOfSameItem(menu.max_quantity_of_same_item);
        }
    }, [menu]);

    const handleName = (event: any) => {
        setName(event.target.value)
    }

    const handleTypeOfMenu = (event: any) => {
        setTypeOfMenu(event.target.value)
    }

    const handleMaxOfItems = (event: any) => {
        setMaxOfItems(event.target.value)
    }

    const handleMaxOfSameItem = (event: any) => {
        setMaxOfSameItem(event.target.value)
    }

    const submitEdit = async () => {
        dispatch(editMenu(menu.menu_id, {
            name: name,
            type_of_menu: typeOfMenu,
            max_quantity_of_items: maxOfItems,
            max_quantity_of_same_item: maxOfSameItem,
            active: active,
        }))
            .then((result: boolean) => {
                if (result) handleClose()
            })
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle id="simple-dialog-title">
                Editar Menu {menu?.name}
            </DialogTitle>
            <FormControl style={{ margin: '0px 10px' }}>
                <InputLabel htmlFor="name-input">Name</InputLabel>
                <Input id="name-input" type="string" value={name} onChange={handleName} />
            </FormControl>
            <FormControl style={{ margin: '0px 10px' }}>
                <InputLabel htmlFor="price-input">Type of Menu</InputLabel>
                <Select labelId="label" id="select" value={typeOfMenu} onChange={handleTypeOfMenu}>
                    <MenuItem value="Take Away">Take Away</MenuItem>
                    <MenuItem value="Table">Table</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{ margin: '0px 10px' }}>
                <InputLabel htmlFor="name-input">Max Menu Items</InputLabel>
                <Input id="name-input" type="number" value={maxOfItems} onChange={handleMaxOfItems} />
            </FormControl>
            <FormControl style={{ margin: '0px 10px' }}>
                <InputLabel htmlFor="name-input">Max for same items</InputLabel>
                <Input id="name-input" type="number" value={maxOfSameItem} onChange={handleMaxOfSameItem} />
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
                <Button onClick={submitEdit} 
                    color="primary"
                    variant="outlined"
                    className={classes.activeButton}> 
                        Salvar 
                </Button>
                <Button onClick={handleClose} 
                    color="primary"
                    variant="outlined"
                    className={classes.button}> 
                        Fechar 
                </Button>
            </div>
        </Dialog>
    );
}
export const ENV = process.env.REACT_APP_ENV || 'production';

export const APP_NAME = process.env.REACT_APP_NAME || 'Realtime';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;

export const SOCKET_PRODUCTION = process.env.REACT_APP_SOCKET_PRODUCTION;
export const SOCKET_NOTIFICATION = process.env.REACT_APP_SOCKET_NOTIFICATION;
export const MANAGER_PASSWORD = process.env.REACT_APP_MANAGER_PASSWORD;

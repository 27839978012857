import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { IHistory } from 'interfaces/history';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import moment from 'moment';

import formatDate from 'date-fns/format';

import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  orders: IHistory[];
  initialDate: Date;
  finalDate: Date;
  isFiltering: Boolean;
  filter: any;
  setInitialDate: any;
  setFinalDate: any;
  message: string;
}

export default function HistoryTable(props: IProps) {
  const classes = useStyles();
  const { orders,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    isFiltering,
    filter,
    message } = props;

  const handleInitalDateChange = (date: Date) => {
    setInitialDate(date);
  };

  const handleFinalDateChange = (date: Date) => {
    setFinalDate(date);
  };

  const filterMessage = () => {
    const style = {
      color: '#233472',
      padding: '15px 0 0 0',
      fontWeight: 600
    };

    if (isFiltering) {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    }
  };

  const columns: GridColDef[] = [
    { field: 'order_code', headerName: 'Número do Pedido', type: 'string', width: 200, headerAlign: 'center', align: 'center' },
    {
      field: 'payment',
      headerName: 'Pagamento',
      type: 'string',
      width: 180,
      headerAlign: 'center',
      align: 'center',
    },
    { field: 'delivery', headerName: '', width: 280, headerAlign: 'center' },
    {
      field: 'pagarme',
      headerName: 'PagarMe',
      width: 140,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'is_paid',
      headerName: 'Pago?',
      width: 120,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date_ordered', headerName: 'Pedido', type: 'number', width: 280, headerAlign: 'center', align: 'center'
    },
    {
      field: 'doing_at', headerName: 'Fazendo', type: 'number', width: 280, headerAlign: 'center', align: 'center'
    },
    {
      field: 'done_at',
      headerName: 'Feito',
      type: 'number',
      width: 280,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'depot_at', headerName: 'Depósito', type: 'number', width: 280, headerAlign: 'center', align: 'center'
    },
    {
      field: 'transit_at', headerName: 'A Caminho', type: 'number', width: 280, headerAlign: 'center', align: 'center'
    },
    {
      field: 'delivered_at', headerName: 'Entregue', type: 'number', width: 280, headerAlign: 'center', align: 'center'
    },
    {
      field: 'finished_at', headerName: 'Completo', type: 'number', width: 280, headerAlign: 'center', align: 'center'
    },
    {
      field: 'NFCe_number', headerName: 'Número NFC-e', type: 'number', width: 280, headerAlign: 'center', align: 'center'
    },
  ];
  let rows: Array<any>

  if (orders) {

    rows = orders?.map((order) =>
    (
      {
        id: order.order_id,
        order_code: order.order_code,
        payment: order.payment,
        delivery: order.date_delivery ? formatDate(new Date(order.date_delivery), 'dd/MM/yyyy hh:mm') : '',
        pagarme: order.pagarme_id,
        is_paid: order.is_paid ? 'Sim' : 'Não',
        date_ordered: order.date_ordered ? formatDate(new Date(order.date_ordered), 'dd/MM/yyyy hh:mm') : '',
        doing_at: order.doing_at ? formatDate(new Date(order.doing_at), 'dd/MM/yyyy hh:mm') : '',
        done_at: order.done_at ? formatDate(new Date(order.done_at), 'dd/MM/yyyy hh:mm') : '',
        depot_at: order?.depot_at ? formatDate(new Date(order.depot_at), 'dd/MM/yyyy hh:mm') : '',
        transit_at: order?.transit_at ? formatDate(new Date(order?.transit_at), 'dd/MM/yyyy hh:mm') : '',
        delivered_at: order.delivered_at ? formatDate(new Date(order.delivered_at), 'dd/MM/yyyy hh:mm') : '',
        finished_at: order.finished_at ? formatDate(new Date(order.finished_at), 'dd/MM/yyyy hh:mm') : '',
        NFCe_number: order.NFCe_number,
      }
    )
    )
  } else {
    rows = [({
      id: '',
        order_code: '',
        payment: '',
        delivery: '',
        pagarme: '',
        is_paid: '',
        date_ordered: '',
        doing_at: '',
        done_at: '',
        depot_at: '',
        transit_at: '',
        delivered_at: '',
        finished_at: '',
        NFCe_number: '',
    })]
  }

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 15,
            padding: 15,
            backgroundColor: '#f5f5f5',
            borderRadius: 5
          }}
        >
          <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
            <Grid
              style={{
                alignItems: 'center',
                display: 'inline-flex'
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Data Inicial</span>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-initial"
                    value={initialDate}
                    placeholder="                ---------------"
                    onChange={handleInitalDateChange}
                    autoOk={true}
                    style={{ width: 189, marginRight: 5 }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Data Final</span>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-final"
                    margin="none"
                    value={finalDate}
                    onChange={handleFinalDateChange}
                    autoOk={true}
                    style={{ width: 189 }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Grid>

            <div style={{ display: 'inline-flex', marginLeft: 5 }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: 5, fontSize: '0.8em', padding: '5px 16px' }}
                onClick={filter}
              >
                Filtrar
              </Button>
            </div>
          </div>
          <PrintIcon onClick={() => {
            const _initialDate = initialDate
              ? moment(initialDate).format('YYYY-MM-DD')
              : null;
            const _finalDate = finalDate
              ? moment(finalDate).format('YYYY-MM-DD')
              : null;
            window.open(`https://farina.stopplay.io/waiter/generate_csv_of_orders_for_between_dates/?starts=${_initialDate}&ends=${_finalDate}&store=BYSTATION`)
          }} />
          {filterMessage()}
        </div>
        <div style={{ height: 550, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} components={{ Toolbar: GridToolbar }} />
        </div>
      </TableContainer>
    </>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import ModalCreateIngredient from './ModalCreateIngredient';
import ModalAddIngredient from './ModalAddIngredient';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { Ingredients } from 'interfaces/stock';

import { DataGrid, GridColDef, GridCellParams, GridToolbar } from '@material-ui/data-grid'; //

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#211f5c',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  icons: {
    fill: '#3f51b5'
  },
});

interface Props {
  ingredients: Ingredients[];
}

export default function IngredientsTable(props: Props) {
  const classes = useStyles();
  const { ingredients } = props;
  const [open, setOpen] = React.useState(false);
  const [openAddIngredient, setOpenAddIngredient] = React.useState(false);
  const [selectedIngredient, setSelectedIngredient] = React.useState(undefined);

  const handleClose = () => {
    setOpen(false);
    setOpenAddIngredient(false);
    setSelectedIngredient(undefined);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenAddIngredient = (ingredient: Ingredients) => {
    setOpenAddIngredient(true);
    setSelectedIngredient(ingredient);
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nome', type: 'string', width: 400, headerAlign: 'left', align: 'left' },
    {
      field: 'production',
      headerName: 'Produção',
      type: 'string',
      width: 140,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '3px', fill: 'blue' }}>{(params.value)}</span>
          <ControlPointIcon className={classes.icons} onClick={() => { }} /* handleClickOpenHistory(params.product) */ />
        </div>
      ),
    },
    { field: 'stock', headerName: 'Estoque', type: 'string', width: 180, headerAlign: 'right', align: 'right' },
    { field: 'measure', headerName: 'Medida', type: 'string', width: 180, headerAlign: 'right', align: 'right' },
    { field: 'cost', headerName: 'Custo (R$)', type: 'string', width: 240, headerAlign: 'right', align: 'right' },

   
  ];

  let rows: Array<any> 
  if(ingredients){
    rows = ingredients?.map((ingredient) => (
      (
        {
          id: ingredient.ingredient_id,
          name: ingredient.name,
          stock: ingredient.quantity,
          measure: ingredient.measure,
          cost: ingredient.cost
        }
      )
    ))
  }else {
    rows = [({
      id: '',
      name: '',
      stock: '',
      measure: '',
      cost: '',
    })]
  }
  


  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button
        style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
        color="primary"
        onClick={handleClickOpen}
        className={classes.activeButton}
      >
        Adicionar Ingrediente
      </Button>
      <div style={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          onCellClick={(params: GridCellParams) => (

            params.field === "production" ? ingredients?.map((ingredient) => {
                if (params.row.id === ingredient.ingredient_id) {
                    handleClickOpenAddIngredient(ingredient)
                }
              })
                :
                null
              ) 
            }
        />
      </div>
      <ModalCreateIngredient
        open={open}
        onClose={handleClose}
      />
      <ModalAddIngredient
        open={openAddIngredient}
        onClose={handleClose}
        ingredient={selectedIngredient}
      />
    </TableContainer>
  );
}
import React from 'react';
import { IPackage } from 'interfaces/package';
import { Grid, Card, Typography } from '@material-ui/core';

interface IProps {
  batch: IPackage;
  toPrint?: boolean;
}

export default function Panel(props: IProps): JSX.Element {
  const { batch, toPrint } = props;

  return (
    <Grid item xs={toPrint ? 4 : 12} sm={6} md={3} lg={3}>
      <Card style={{ padding: 10, height: '100%' }}>
        <Typography
          variant="subtitle2"
          align="center"
          style={{ fontWeight: 300, fontSize: 16 }}
        >
          {`PACOTE Nº ${batch.batch}`}
        </Typography>
        <hr />
        <Typography align="left" style={{ fontWeight: 600, fontSize: 14 }}>
          {`PRODUTOS`}
        </Typography>
        {batch.products.map((product, index) => (
          <Typography key={index} align="left" style={{ fontSize: 12 }}>
            {`${product.quantity} x ${product.product}`}
          </Typography>
        ))}
        <hr />
        <Typography align="left" style={{ fontWeight: 600, fontSize: 14 }}>
          {`INGREDIENTES`}
        </Typography>
        {batch.ingredients.map((ingredient, index) => (
          <Typography key={index} align="left" style={{ fontSize: 12 }}>
            {`${ingredient.ingredient} - ${ingredient.quantity}${ingredient.measure}`}
          </Typography>
        ))}
      </Card>
    </Grid>
  );
}

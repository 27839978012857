import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import store from './redux/';

import { APP_NAME } from 'settings';

import Routes from './components/Router';
import PrivateRoute from './components/Router/PrivateRoute';
import Authentication from './components/Authentication';
import Alert from './components/Global/Alert';

const App: React.FC = () => {
  useEffect(() => {
    document.title = APP_NAME;
  }, []);

  return (
    <Provider store={store}>
      <Alert />
      <Router>
        <Switch>
          <Route path="/" exact component={Authentication} />
          <PrivateRoute path="/dashboard" component={Routes} />
          <Route
            render={() => (
              <>
                <h3 className="text-center">Page Not Found</h3>
              </>
            )}
          />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
